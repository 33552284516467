export const GRID_COLUMN = {
  GRID_DIAMOND_LIST: 'GRID_DIAMOND_LIST', // client side
  GRID_DIAMOND_LIST_ADMIN: 'GRID_DIAMOND_LIST_ADMIN', // Search, Search Match Parir, New Arrival
  GRID_TRANSACTION_ENQUIRY_CART: 'GRID_TRANSACTION_ENQUIRY_CART', //Cart, Notes
  GRID_TRANSACTION_ENQUIRY_ENQUIRY_LIST: 'GRID_TRANSACTION_ENQUIRY_ENQUIRY_LIST', //Open Enquiry, Close Enquiry
  GRID_TRANSACTION_ENQUIRY_WATCH_LIST: 'GRID_TRANSACTION_ENQUIRY_WATCH_LIST', // watchlist
  GRID_TRANSACTION_HOLD_LIST: 'GRID_TRANSACTION_HOLD_LIST', //Hold
  GRID_TRANSACTION_ON_MEMO_LIST: 'GRID_TRANSACTION_ON_MEMO_LIST', //Memo
  GRID_TRANSACTION_ORDER: 'GRID_TRANSACTION_ORDER', //Pending, Approve, Rejected, Cancelled
  GRID_DIAMOND_LIST_CORUSCATE: 'GRID_DIAMOND_LIST_CORUSCATE', //Diamond List column Setting
  GRID_DIAMOND_LIST_ADMIN_PRICE: 'GRID_DIAMOND_LIST_ADMIN_PRICE', //used
  GRID_TRANSACTION_LOCATION: 'GRID_TRANSACTION_LOCATION', //LocationList
  // GRID_TRANSACTION_ENQUIRY_OFFICE_VIEW: 'GRID_TRANSACTION_ENQUIRY_OFFICE_VIEW', // not Used
  // GRID_TRANSACTION_ENQUIRY_REMAINDER: 'GRID_TRANSACTION_ENQUIRY_REMAINDER', // not in used
  // GRID_TRANSACTION_ENQUIRY_NOTES: 'GRID_TRANSACTION_ENQUIRY_NOTES', //not in used
  // GRID_TRANSACTION_OFFER: 'GRID_TRANSACTION_OFFER', //not used
  // GRID_DIAMOND_ADMIN_UPCOMING_LIST: 'GRID_DIAMOND_ADMIN_UPCOMING_LIST', //not used
  // GRID_DIAMOND_LIST_CHECK_IMAGE: 'GRID_DIAMOND_LIST_CHECK_IMAGE', //not used
  // GRID_DIAMOND_LIST_PROFORMA_REPORT: 'GRID_DIAMOND_LIST_PROFORMA_REPORT', //not used
  // GRID_DIAMOND_LIST_SE_REPORT: 'GRID_DIAMOND_LIST_SE_REPORT', //not used
  // GRID_DIAMOND_LIST_SHOW_STONE_REPORT: 'GRID_DIAMOND_LIST_SHOW_STONE_REPORT', //not used
  // GRID_CHECK_PACKET: 'GRID_CHECK_PACKET', // not used
  // GRID_ADMIN_TRANSACTION_BID: 'GRID_ADMIN_TRANSACTION_BID', //not used
  // GRID_ORDER_REQUEST: 'GRID_ORDER_REQUEST', //not used
};
export const DIAMOND_WEB_STATUS = {
  A: 'A',
  M: 'M',
  NA: 'NA',
  UPCOMING: 'U',
  BID: 'B',
  SHOW: 'S',
};

export const DIAMOND_WEB_STATUS_WITH_LABEL = {
  AVAILABLE: 'A',
  HOLD: 'H',
  BUSINESS_PROCESS: 'M',
};

export const DIAMOND_SEARCH_TYPE = {
  RECENT: 1,
  SAVE: 2,
  DEMAND: 3,
  API_SEARCH: 4,
  PAIR_SEARCH: 5,
  VOICE_SEARCH: 6,
  LUCKY_SEARCH: 7,
  ARTICLE: 8,
  COLLECTION: 9,
  INVENTORY: 10,
};

export const DIAMOND_BID = {
  TYPE: {
    BLIND: 1,
    OPEN: 2,
  },
  STATUS: {
    PENDING: 1,
    WIN: 2,
    LOSS: 3,
  },
};

export const SE_REPORT_STAGE_TYPE = { C: 'C', S: 'S', E: 'E' };

export const DIAMOND_WEB_STATUS_LIST = [
  'A',
  // 'B',
  // 'C',
  // 'C1',
  // 'C2',
  // 'C3',
  // 'C4',
  // 'C5',
  // 'CB',
  // 'CC',
  // 'CH',
  // 'CJ',
  // 'CX',
  // 'E',
  // 'EB',
  // 'EC',
  // 'EH',
  // 'EJ',
  // 'EX',
  // 'G',
  'H',
  // 'I',
  // 'J',
  // 'L',
  'M',
  // 'N',
  // 'O',
  // 'R',
  // 'S',
  // 'SB',
  // 'SH',
  // 'SJ',
  // 'SM',
  // 'SX',
  // 'T',
  // 'V',
  // 'Y',
  // 'Z',
];
