import UtilService from 'services/util';
import { isEmpty } from 'util/utils';

export const API_ROUTES = {
  create: { method: 'post', url: '/admin/v1/account/department/create' },
  view: { method: 'get', url: '/admin/v1/account/department/' },
  list: { method: 'get', url: '/admin/v1/account/department/paginate/' },
  update: { method: 'post', url: '/admin/v1/account/department/' },
  upsert: { method: 'post', url: '/admin/v1/account/department/upsert/' },
  destroy: { method: 'delete', url: '/admin/v1/account/department/destroy/' },
};

export const listAccountDepartment = (id, request = {}, config) =>
  UtilService.callApiAsync({
    ...UtilService.withUrlParams(API_ROUTES.list, [id]),
    request,
    ...(!isEmpty(config) && { config }),
  });

export const getAccountDepartment = (id, request = {}, config) =>
  UtilService.callApiAsync({
    ...UtilService.withUrlParams(API_ROUTES.view, [id]),
    request,
    ...(!isEmpty(config) && { config }),
  });

export const addAccountDepartment = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.create, request, ...(!isEmpty(config) && { config }) });

export const updateAccountDepartment = (id, request = {}, config) =>
  UtilService.callApiAsync({
    ...UtilService.withUrlParams(API_ROUTES.update, [id]),
    request,
    ...(!isEmpty(config) && { config }),
  });

export const upsertAccountDepartment = (id, request = {}, config) =>
  UtilService.callApiAsync({
    ...UtilService.withUrlParams(API_ROUTES.upsert, [id]),
    request,
    ...(!isEmpty(config) && { config }),
  });

export const deleteAccountDepartment = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.destroy, request, ...(!isEmpty(config) && { config }) });
