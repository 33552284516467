import AccountAddress from './AccountAddress';
import AccountDepartment from './AccountDepartment';
import AccountFilter from './AccountFilter';
import Auth from './Auth';
import clientReducer from './ClientReducer';
import Common from './Common';
import FilterReducer from './FilterReducer';
import LoaderReducer from './LoaderReducer';
import TabReducer from './TabReducer';
import TermsDetail from './TermsDetail';
import User from './User';
import UserFilter from './UserFilter';
import diamond from './diamond';
import tableValues from './table-values';

const reducers = {
  auth: Auth,
  commonData: Common,
  tab: TabReducer,
  tableValues,

  diamondData: diamond,
  loader: LoaderReducer,
  client: clientReducer,

  filter: FilterReducer,
  termsDetail: TermsDetail,

  accountFilter: AccountFilter,
  accountDepartment: AccountDepartment,
  accountAddress: AccountAddress,

  user: User,
  userFilter: UserFilter,
};

export default reducers;
