import { Stone } from 'entities/Stone';
import { User } from 'entities/User';

import { clone } from 'util/utils';

export class Block {
  static identifier = 'Block';

  props = {};
  stone = {};
  user = {};

  constructor(details = {}) {
    this.props = clone(Block.isBlock(details) ? details.props : details);
    this.stone = new Stone(this.props.diamond);
    this.user = new User(this.props.user);
  }

  static isBlock(input) {
    return input?.constructor === Block;
  }

  get id() {
    return this.props.id;
  }

  get memoNo() {
    return this.props.memoNo;
  }
}
