export const PREFIX_URL = 'sl';
export const PREFIX_URL_WITH_SLASH = `/${PREFIX_URL}`;
export const MEDIA_URL = 'https://arjivexports.blob.core.windows.net/arjivfiles';

export const BASE_URL =
  process.env.REACT_APP_ENV === 'production' ? 'https://api.sldiamonds.be/' : 'https://betapi.sldiamonds.be/';
export const FRONT_URL =
  process.env.REACT_APP_ENV === 'production' ? 'https://my.sldiamonds.be/' : 'https://beta.sldiamonds.be/';
export const DNA_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://my.diamondviewer360.com/'
    : 'https://beta.diamondviewer360.com/';
export const FILE_URL = `${BASE_URL}data/`;

export const COMMON_URL = { BASE_URL, FRONT_URL, DNA_URL, FILE_URL };
