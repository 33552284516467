/* eslint-disable */
import { LUXON_FORMAT, FRACTION_LENGTH } from 'util/constants';
import { i18n } from 'util/i18n';
import { memoize } from 'util/lodash';

import { COMMON_URL } from './CommonUrl';
import ProjectDetails from './ProjectDetails.json';

export { LOCALE, CURRENCY, TIMEZONE_IANA, LUXON_FORMAT, FRACTION_LENGTH } from 'util/constants';

export const MOBILE_MAX_WIDTH = 767;
export const TABLET_MAX_WIDTH = 991;
export const LIST_LIMIT = 250;
export const TABLE_PAGE_LIMIT = 250;

export const ALLOW_ROW_SELECTION = false;
export const ALLOW_ROW_HIGHLIGHT_BY_LAB = true;
export const ALLOW_ROW_GROUP_TITLE_TOGGLE = false;
export const ALLOW_MULTIPLE_BID_CONFIG = false;

export const FORMAT_DATETIME = LUXON_FORMAT.DATE_TIME;
export const FORMAT_DATE = LUXON_FORMAT.DATE;
export const FORMAT_TIME = LUXON_FORMAT.TIME;
export const FORMAT_DURATION = LUXON_FORMAT.DURATION;
export const TABLE_LIMIT = TABLE_PAGE_LIMIT;
export const PRECISION = FRACTION_LENGTH;

export const DRAWER_PROPS_LIST = [
  'closable',
  'destroyOnClose',
  'getContainer',
  'maskClosable',
  'mask',
  'maskStyle',
  'style',
  'bodyStyle',
  'title',
  'visible',
  'width',
  'height',
  'wrapClassName',
  'zIndex',
  'prefixCls',
  'push',
  'placement',
  'onClose',
  'className',
  'handler',
];

export function getPath() {
  return window.location.pathname?.split?.('/')?.pop?.();
}

export const CALENDER_FIELD = [
  'SHOW',
  'INFO',
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
];

export const USER_TYPE = {
  ADMIN: { SUPER: 1, GENERAL: 11 },
  PHARMACY: { ADMIN: 2, PHARMACIST: 21, LICENSED_TECHNICIAN: 22, INTERN: 23, SHIPPER: 24, RECEIVER: 25 },
  ORGANIZATION: { ADMIN: 3 },
  HOME: {
    ADMIN: 4,
    HOME_AREA: { ADMIN: 5, DOCTOR: 51, NURSE: 52, STAFF: 53, PATIENT: 54 },
  },
};

export const FILE_TYPES = {
  pdf: ['application/pdf'],
  image: ['image/jpeg', 'image/png', 'image/jpg'],
  json: ['application/json'],
};

export const VERSION_PLATFORM = { ANDROID: 1, IPHONE: 2 };

export const DEVICE_TYPES = {
  WEB: 1,
  ANDROID: 2,
  IPHONE: 3,
  DEVICE: 4,
  ADMIN: 5,
};

export const EVENT = {
  TYPE: { EVENT: '1', NEWS: '2', SHOW: '3', OTHER: '4', CSR: '5', POPUP: '6' },
};

export const FEATURE_STONE = {
  STONE_OF_DAY: 'stone_of_day',
  BEST_OF_FINESTAR: 'best',
  EXCLUSIVE_STONE: 'exclusive',
  PAIR: 'pair',
  FEATURE_STONE: 'stone',
};

export const USERS_TYPE = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  SUB_USER: 3,
  PRIMARY: 4,
  PERMANENT_GUEST: 5,
  EMPLOYEE: 6,
  API_USER: 7,
  SELLER: 8,
  CUSTOMER: 9,
  SECONDARY: 10,
  INTERNAL_SELLER: 11,
};

export const Employe_Type = { commonPER: 2, AdminPER: 1 };

export const PERMISSION_TYPE = { user: 'user', role: 'role' };

export const PARCEL_PRICE_OPERATION_TYPE = { SAVE_AS_NEW: '1', UPDATE: '2' };

export const PARCEL_PRICE_SHAPE = {
  ROUND: 'Round',
  PRINCESS: 'Princess',
  PEAR: 'Pear',
  OVAL: 'Oval',
  MARQUISE: 'Marquise',
};

export const NEWS = {
  DISPLAYON: { WEB: '1', APP: '2' },
  TYPE: { NOTIFICATION: '1', 'HELLO BAR': '2', 'TRADE SHOW': '3', OTHER: '4' },
};

export const BANNER_TYPES = {
  HOMEPAGE_TOP: 1,
  HOMEPAGE_SECOND: 2,
  HOMEPAGE_THIRD_LEFT: 3,
  HOMEPAGE_THIRD_RIGHT: 4,
  HOMEPAGE_FOUR: 5,
  HOMEPAGE_BOTTOM: 6,
  LOGIN_REGISTER: 7,
  LANDING_PAGE_NEWS: 8,
  HOME_TOP_LEFT_1: 9,
  HOME_TOP_LEFT_2: 10,
  HOME_TOP_LEFT_3: 11,
  HOME_TOP_RIGHT_1: 12,
  HOME_TOP_RIGHT_2: 13,
  HOME_TOP_RIGHT_3: 14,
  HOME_TOP_CENTRE: 15,
  HOME_BOTTOM_LEFT_1: 16,
  HOME_BOTTOM_LEFT_2: 17,
  HOME_BOTTOM_LEFT_3: 18,
  HOME_BOTTOM_RIGHT_1: 19,
  HOME_BOTTOM_RIGHT_2: 20,
  HOME_BOTTOM_RIGHT_3: 21,
  HOME_BOTTOM_CENTRE: 22,
  HOME_CENTRE: 23,
};

export const BANNER_SEARCH_BAR_POSITION = {
  CENTER: 1,
  TOP: 2,
  DOWN: 3,
  LEFT: 4,
  RIGHT: 5,
};

export const PAGE_TRACKING_DEVICE = { WEB: 1, ANDROID: 2, IOS: 3 };

export const PAGE_TRACKING_ACTION = {
  OPEN: 'open',
  CLOSE: 'close',
  UPLOAD: 'upload',
  RESET: 'reset',
  SELECT: 'select',
  UNSELECT: 'unselect',
  CUSTOM: 'custom',
  EXISTING: 'existing',
  FANCY: 'fancy',
  WHITE: 'white',
  LIST: 'list',
  CLICK: 'click',
  PRINT: 'print',
  EXCEL: 'excel',
  GRID: 'grid',
  VIDEO: 'video',
  PICTURE: 'picture',
  CERTIFICATE: 'certificate',
  CHANGE: 'change',
  DOWNLOAD: 'download',
  FOCUS: 'focus',
  SEARCH: 'search',
  COMPLETE: 'complete',
};

export const PAGE_TRACKING_SECTION = {
  ADVANCE_SEARCH: 'AdvanceSearch',
  ADD_DEMAND: 'AddDemand',
  SAVE_SEARCH: 'SavedSearch',
  UPLOAD_EXCEL: 'UploadExcel',
  RESET_FILTER: 'ResetFilter',
  THREE_EX: 'ThreeEx',
  TWO_EX: 'TwoEx',
  THREE_VG: 'ThreeVg',

  CARAT_SIZE: 'CaratSize',
  COLOR: 'Color',
  DIAMONDS: 'Diamonds',
  MODIFY: 'Modify',
  STICKY_FILTER: 'StickyFilter',
  PLACE_ORDER: 'PlaceOrder',
  SHIPMENT: 'Shipment',
  ENQUIRY: 'Enquiry',
  UPDATE: 'Update',
  EXPORT: 'Export',
  EXPORT_SEND_EMAIL: 'Export-Send_Email',
  ADD_TO_CART: 'AddToCart',
  ADD_TO_WATCHLIST: 'AddToWatchList',
  COMMENT: 'Comment',
  REMINDER: 'Reminder',
  VIEW: 'View',
  FILTER: 'Filter',
  SETTING: 'Setting',
  TABLE_ADD_TO_CART: 'Table-AddToCart',
  TABLE_ADD_TO_WATCHLIST: 'Table-AddToWatchList',
  TABLE_DELETE: 'Table-Delete',
  TABLE_COMMENT: 'Table-Comment',
  TABLE_REMINDER: 'Table-Reminder',
  TABLE_DETAILS: 'Table-Details',
  EDIT: 'Edit',
  DELETE: 'Delete',
  SEARCH: 'Search',
  CHANGE_PASSWORD: 'ChangePassword',
  INVENTORY: 'Inventory',
  MY_CART: 'MyCart',
  CALCULATOR: 'Calculator',
  TRACK_SHIPMENT: 'TrackShipment',
  LOCALE: 'Locale',
  LOGIN: 'login',
  LOGOUT: 'logout',
};

export const PAGE_TRACKING_PAGE = {
  DIAMOND_SEARCH: 'DiamondSearch',
  FANCY_SEARCH: 'FancySearch',
  MY_DEMAND: 'MyDemand',
  MY_SAVE_SEARCH: 'MySavedSearch',
  SEARCH_RESULT: 'SearchResult',
  UPCOMING_DIAMOND: 'UpcomingDiamond',
  NEW_DIAMOND: 'NewDiamond',
  CART: 'MyCart',
  WATCHLIST: 'MyWatchlist',
  REMINDER: 'MyReminder',
  COMMENT: 'MyComment',
  ENQUIRY: 'MyEnquiry',
  DEMAND: 'MyDemand',
  ORDER: 'MyOrder',
  ACCOUNT: 'MyAccount',
  HEADER: 'Header',
  RECOMMENDED: 'Recommended',
  USER: 'user',
  HOME: 'Home',
  DRAWER: 'Drawer',
  VENDOR_DIAMOND: 'vendor-diamond',
  SHEET_UPLOAD_V1: 'sheet-upload-V1',
  PROFILE: 'profile',
};

export const BANNER_TYPES_IMAGE = {
  Image: 1,
  Text: 2,
};

export const TRACK_TYPES = {
  Cart: 1,
  Watchlist: 2,
  Request: 3,
  Offer: 4,
  Reminder: 5,
  Enquiry: 6,
  Shipment: 7,
  Comment: 99,
  BID_WISHLIST: 10,
  BID_PENDING: 11,
};
export const TRANSACTION = {
  HOLD: 1,
  MEMO: 2,
};
export const TRANSACTION_ENQUIRY = {
  CUSTOMER_WISE: 1,
  DATE_WISE: 2,
  DIAMONDLIST: 3,
  DATE_CUSTOMER: 4,
  COUNTRY_WISE: 5,
  SALES_PERSON_WISE: 6,
};

export const TRANSPORT_STATUS = { Pending: '1', Done: '2', Cancel: '3' };

export const HOSPITALITY_STATUS = { Pending: '0', approved: '1', Cancel: '3' };

export const UserPage_Type = { Pending: '1', Verified: '2', Cancel: '3', verifyLogin: '4' };

export const Sheet_Processing = {
  PENDING: '1',
  PROCESSING: '2',
  COMPLETED: '3',
  FAILED: '4',
  UPLOADING: '5',
  UPLOADING_COMPLETED: '6',
  UPLOADING_FAILED: '7',
  ALL: '8',
};

export const FTP_REQUEST = { PENDING: '1', ACCEPTABLE: '2', CANCEL: '3' };

export const Account_Detail_tabs = {
  CompanyDetail: '3',
  BasicInfo: '1',
  AddressDetail: '2',
  Users: '4',
  Ftp: '5',
};

export const LOGIN_PLATFORM = { Android: '2', IOS: '3', Web: '1' };

export const MasterAction = {
  color: 'color',
  clarity: 'clarity',
  Symmetry: 'Symmetry',
  shape: 'shape',
  fluorescence: 'fluorescence',
  Location: 'Location',
  SIZE: 'SIZE',
  Polish: 'Polish',
  cut: 'Cut',
};

export const diamondParameters = {
  shp: { key: 'shp', name: 'Shape', master: 'SHAPE' },
  carat: { key: 'carat', name: 'Carat Range', master: 'SIZE' },
  col: { key: 'col', name: 'Color', master: 'COLOR' },
  clr: { key: 'clr', name: 'Clarity', master: 'CLARITY' },
  flu: { key: 'flu', name: 'Fluorescence', master: 'FLUORESCENCE' },
  crt: { key: 'crt', name: 'Carat', master: 'COLOR' },
  shd: { key: 'shd', name: 'Shade', master: 'SHADE' },
  lb: { key: 'lb', name: 'Lab', master: 'LAB' },
  cut: { key: 'cut', name: 'Cut', master: 'CUT' },
  pol: { key: 'pol', name: 'Polish', master: 'POLISH' },
  sym: { key: 'sym', name: 'Symmetry', master: 'SYMMETRY' },
  loc: { key: 'loc', name: 'Location', master: 'LOCATION' },
  country: { key: 'country', name: 'Country', master: 'COUNTRY' },
  inten: { key: 'inten', name: 'Intensity', master: 'INTENSITY' },
  ovrtn: { key: 'ovrtn', name: 'Overtone', master: 'OVERTONE' },
  blkInc: { key: 'blkInc', name: 'Black Table', master: 'BLACK_INCLUSION' },
  eCln: { key: 'eCln', name: 'Eye Clean', master: 'EYECLEAN' },
  mlk: { key: 'mlk', name: 'Milky', master: 'MILKEY' },
  hA: { key: 'hA', name: 'Heart and Arrow', master: 'H_AND_A' },
  rap: { key: 'rap', name: 'Rap ($)' },
  ctPr: { key: 'ctPr', name: 'Price/Carat ($)' },
  amt: { key: 'amt', name: 'Amount ($)' },
  depPer: { key: 'depPer', name: 'Depth %' },
  tblPer: { key: 'tblPer', name: 'Table %' },
  grdl: { key: 'grdl', name: 'Girdle', master: 'GIRDLE' },
  pAng: { key: 'pAng', name: 'Pavilion Angle' },
  cAng: { key: 'cAng', name: 'Crown Angle' },
  cHgt: { key: 'cHgt', name: 'Crown Height' },
  pHgt: { key: 'pHgt', name: 'Pavilian Height' },
  dmtrMn: { key: 'dmtrMn', name: 'Diameter Min' },
  dmtrMxn: { key: 'dmtrMxn', name: 'Diameter Max' },
  or: { key: 'or', name: 'Carat' }, // or has more props than caret
  ratio: { key: 'ratio', name: 'Ratio' },
  showSrNo: { key: 'showSrNo', name: 'Show ID' },
  kToSStr: { key: 'kToSStr', name: 'Key To Symbol', master: 'KEY_TO_SYMBOLS' },
  fcCol: { key: 'fcCol', name: 'Fancy Color', master: 'FANCY_COLOR' },
  isFcCol: { key: 'isFcCol', name: 'Has Fancy Color' },
  noBGM: { key: 'noBGM', name: 'No Brown, Green or Milky' },
  grdlCond: { key: 'grdlCond', name: 'Girdle Condition' },
  cultCond: { key: 'cultCond', name: 'Culet Condition' },
  cult: { key: 'cult', name: 'Culet', master: 'CULET' },
  opTbl: { key: 'opTbl', name: 'Open Inclusion Table', master: 'OPEN_INCLUSION_TABLE' },
  opPav: { key: 'opPav', name: 'Open Inclusion Pavilion', master: 'OPEN_INCLUSION_PAVILION' },
  opCrwn: { key: 'opCrwn', name: 'Open Inclusion Crown', master: 'OPEN_INCLUSION_CROWN' },
  keyToSymbol: { key: 'keyToSymbol', name: 'Key To Symbol', master: 'KEY_TO_SYMBOLS' },
  wTbl: { key: 'wTbl', name: 'White Inclusion Table', master: 'WHITE_INCLUSION_TABLE' },
  blkTbl: { key: 'blkTbl', name: 'Black Inclusion Table', master: 'BLACK_INCLUSION_TABLE' },
  blkSd: { key: 'blkSd', name: 'Black Inclusion Side', master: 'BLACK_INCLUSION_SIDE' },
  wSd: { key: 'wSd', name: 'White Inclusion Side', master: 'WHITE_INCLUSION_SIDE' },
  brlncy: { key: 'brlncy', name: 'Brilliancy', master: 'BRILLIANCY' },
  stoneId: { key: 'stoneId', name: 'Stone ID/ Report Number' },
  vnd: { key: 'vnd', name: 'Seller' },
  oldvStnId: { key: 'oldvStnId', name: 'Original Stock No.' },
  vStnId: { key: 'vStnId', name: 'Stone No' },
  length: { key: 'length', name: 'Length' },
  width: { key: 'width', name: 'Width' },
  height: { key: 'height', name: 'Depth' },
  counId: { key: 'counId', name: 'Location' },
  // country: { key: 'country', name: 'Country' },
  shpNm: { key: 'shpNm', name: 'Shape' },
  lab: { key: 'lab', name: 'Lab' },
  colNm: { key: 'colNm', name: 'Color' },
  clrNm: { key: 'clrNm', name: 'Clarity' },
  lap: { key: 'lap', name: 'LAP%' },
  lapCt: { key: 'lapCt', name: 'LAP/CT' },
  lapAmt: { key: 'lapAmt', name: 'Lap Amt' },
  Sale: { key: 'Sale', name: 'Sale' },
  saleCt: { key: 'saleCt', name: 'Sale/CT' },
  saleAmt: { key: 'saleAmt', name: 'Sale Amt' },
  cutNm: { key: 'cutNm', name: 'Cut' },
  polNm: { key: 'polNm', name: 'Pol', desc: 'Polish' },
  symNm: { key: 'symNm', name: 'Sym', desc: 'Symmetry' },
  flo: { key: 'flo', name: 'Flo', desc: 'Fluorescence' },
  tb: { key: 'tb', name: 'TB%', desc: 'Table %' },
  dp: { key: 'dp', name: 'DP%', desc: 'Depth %' },
  msrmnt: { key: 'msrmnt', name: 'Measurement' },
  //
  rptNo: { name: 'Certificate No', key: 'rptNo' },
  lbNm: { name: 'Lab', key: 'lbNm' },
  shdNm: { name: 'Shade', key: 'shdNm' },
  fluNm: { name: 'Fluorescence', key: 'fluNm' },
  lbCmt: { name: 'Report Comments', key: 'lbCmt' },
  girdleStr: { name: 'Girdle', key: 'girdleStr' },
  cultNm: { name: 'Culet', key: 'cultNm' },
  lsrInc: { name: 'Laser Inc.', key: 'lsrInc', desc: 'Laser Inclusion' },
  blkTblNm: { name: 'Center Natts', key: 'blkTblNm' },
  blkSdNm: { name: 'Side Natts', key: 'blkSdNm' },
  wTblNm: { name: 'Center White', key: 'wTblNm' },
  wSdNm: { name: 'Side White', key: 'wSdNm' },
  opTblNm: { name: 'Table Open', key: 'opTblNm' },
  opCrwnNm: { name: 'Crown Open', key: 'opCrwnNm' },
  opPavNm: { name: 'Pavillion Open', key: 'opPavNm' },
  opGrd: { key: 'opGrd', name: 'Open Inclusion Girdle', master: 'OPEN_INCLUSION_GIRDLE' },
  eClnNm: { name: 'Eye Clean', key: 'eClnNm' },
  hANm: { name: 'Heart & Arrow', key: 'hANm' },
  brlncyNm: { name: 'Brilliancy', key: 'brlncyNm' },
  type2Nm: { name: 'Type2 Cert', key: 'type2Nm' },
  locNm: { name: 'Country of Origin', key: 'locNm' },
  mines: { name: 'Rough Mine', key: 'mines' },
  //
  wSts: { name: 'Stage', key: 'wSts' },
  sSts: { name: 'Stage Status', key: 'sSts' },
  prcStg: { name: 'Price Stage', key: 'prcStg' },
  // back: { name: 'Disc %', key: 'cDis' },
  back: { name: 'Disc %', key: 'back' },
  cDis: { name: 'Dis %', key: 'cDis' },
  grdlPer: { name: 'Girdle %', key: 'grdlPer' },
  lowerhalf: { name: 'Lower Half', key: 'lowerhalf' },
  starlength: { name: 'Star Length', key: 'starlength' },
  kToSArr: { name: 'Key To Symbol', key: 'kToSArr' },
  isCm: { name: 'Cert', key: 'isCm' },
  isFm: { name: 'FM', key: 'isFm' },
  isDor: { name: 'DOR', key: 'isDor' },
  inDt: { name: 'Arrival Date', key: 'inDt' },
  isXray: { name: 'xRay', key: 'isXray' },
  type2: { name: 'Type ||A', key: 'type2' },
  lwrHal: { name: 'Lower Half', key: 'lwrHal' },
  strLn: { name: 'Star Length', key: 'strLn' },
  grdlThn: { name: 'Girdle', key: 'grdlThn', master: 'GIRDLE' },
  isSeal: { name: 'Seal', key: 'isSeal' },
};

export const diamondparameters2 = [
  { key: 'stoneId', name: 'Stone Id' },
  { key: 'shapeId', name: 'Shape' },
];

export const SEARCH_HISTORY = { RECENT: 1, SAVE: 2, DEMAND: 3, VOICE_SEARCH: 6 };

export const OVERFLOW_COUNT = 1500000;
export const PAGES_PERMISSION = {
  // prettier-ignore
  '1': [1, 60, 61, 62, 63, 64, 65, 66, 67, 71, 72, 73, 74, 75, 76, 77, 78, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 120, 118, /* Appointment */ 119, /* Slot */ 107, 108, 109, 110, 111, 112, 113, 121, /* parcel-master */ 122, /* setting */ 123, /* notification-master */ 124, 125, 126, 127, 128, 129, 130, 131, 140, 141, 151, 162, 163, /* hit-counter */ 164, /* location */ 145, /* sync setting */ 146, /* upload history */ 147, /* stock-summary */ 148, /* ftp request */],
  3: [1],
  4: [1],
};

export const IMAGE_PATH = 'https://diamanti.s3.amazonaws.com/images/diamond/***.jpg';
export const VIDEO_PATH = 'https://diamanti.s3.amazonaws.com/video/Vision360.html?d=***';
export const CERTI_PATH = 'http://diamanti.s3.amazonaws.com/certificates/***.jpg';

export const BASE_URL = COMMON_URL.BASE_URL;
export const EXCELFILE_URL = COMMON_URL.FILE_URL;
export const BASE_DOWNLOAD_URL = COMMON_URL.FILE_URL;
export const MEDIA_URL = 'https://s3.ap-south-1.amazonaws.com/finestargroup';

export const DEFAULT_API_ERROR = 'Something went wrong. Please contact admin.';
export const ERR_MSG = 'Something went wrong.';

export const SHARE_OPTIONS = {
  Images: {
    label: 'Image',
    key: 'img',
    subLevel: [
      { label: 'Ideal Image', key: 'img' },
      { label: 'Natural Image', key: 'naturalImage' },
      { label: 'Heart & Arrow Image', key: 'hAFile' },
      { label: 'Fluorescence Image', key: 'flsImage' },
      { label: 'Plot Image', key: 'pltFile' },
      { label: 'Proportion Image', key: 'propImage' },
    ],
  },
  Videos: {
    label: 'Video',
    key: 'videoFile',
    subLevel: [
      { label: 'Natural Video', key: 'videoFile' },
      { label: 'HD Video', key: 'mp4Video' },
    ],
  },
  Certificates: { label: 'Certificate', key: 'certFile' },
  Excels: { label: 'Excel', key: 'isExcel' },
};

export const MIME_TYPES = {
  png: ['89504e47'],
  jpeg: ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffee', 'ffd8ffdb'],
  jpg: ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffee', 'ffd8ffdb'],
  pdf: ['25504446'],
  xlsx: ['504b0304', '504b34', '504b0506', '504b0708'],
  ods: ['504b0304', '504b34', '504b0506', '504b0708'],
  xls: ['d0cf11e0'],
  csv: ['rfc4180', '5372204e', '50412c53', '53746f6e'],
  mp4: ['00020'],
};

export const PROJECT_DETAILS = {
  NAME: ProjectDetails && ProjectDetails.projectName ? ProjectDetails.projectName : 'SL Diamonds',
  LOGO_1:
    ProjectDetails && ProjectDetails.logo1 ? BASE_URL + ProjectDetails.logo1 : require('assets/images/logonamehd.png'),
  LOGO_2: ProjectDetails && ProjectDetails.logo2 ? BASE_URL + ProjectDetails.logo2 : require('assets/images/logo1.png'),
  LOGO_3: ProjectDetails && ProjectDetails.logo3 ? BASE_URL + ProjectDetails.logo3 : require('assets/images/logo1.png'),
};

export const bussinessTypeArray = {
  // Polished_Dealer: 'Polished Dealer',
  // Jewellery_Manufacturer: 'Jewellery Manufacturer',
  // Diamond_Manufacturer: 'Diamond Manufacturer',
  // Retailer: 'Retailer',
  // Consumer: 'Consumer',
  'Independent Jeweler': 'Independent Jeweler',
  'Online Jewelry Store': 'Online Jewelry Store',
  'Diamond Dealer/ Broker': 'Diamond Dealer/ Broker',
  'Diamond Manufacturer / Cutter': 'Diamond Manufacturer / Cutter',
  'Jewelry Manufacturer': 'Jewelry Manufacturer',
  'Jewelry Retail Chain': 'Jewelry Retail Chain',
  'Pawn shop': 'Pawn shop',
  Appraiser: 'Appraiser',
  Designer: 'Designer',
  'Not in the Diamond / Jewelry Trade': 'Not in the Diamond / Jewelry Trade',
  'Gold Buyer': 'Gold Buyer',
  'Diamond Mining': 'Diamond Mining',
  Auctioneer: 'Auctioneer',
  'Trade Association': 'Trade Association',
  'Watch Dealer': 'Watch Dealer',
  'Finance / Banking': 'Finance / Banking',
  Investor: 'Investor',
  'Jewelry Appraiser': 'Jewelry Appraiser',
  'Jewelry Retailer': 'Jewelry Retailer',
  'Diamond and Pearl Dealer / Broker': 'Diamond and Pearl Dealer / Broker',
  'Colored Stone Dealer / Broker': 'Colored Stone Dealer / Broker',
  'Estate Jewelry Dealer / Broker': 'Estate Jewelry Dealer / Broker',
  Other: 'Other',
};

export const DIAMOND_SEARCH_TYPE = {
  RECENT: 1,
  SAVE: 2,
  DEMAND: 3,
  API_SEARCH: 4,
  PAIR_SEARCH: 5,
  VOICE_SEARCH: 6,
  LUCKY_SEARCH: 7,
  ARTICLE: 8,
  COLLECTION: 9,
  INVENTORY: 10,
  API_SAVED_SEARCH: 11,
  ORDER: 14,
};

export const INVENTORY_PLATFORMS = [
  { key: 1, value: 'RAPNET' },
  { key: 2, value: 'R2NET' },
  { key: 3, value: 'IDEX' },
  { key: 4, value: 'MARKET_DIAMONDS' },
  { key: 5, value: 'GET_DIAMONDS' },
];

export const SETTING_TYPE = {
  OTHER_SETTING: 1,
  NOTIFICATION_SETTING: 2,
  PLACE_ORDER_ACTION: 3,
  OFFLINE_STOCK: 4,
  ADMIN_LOGIN_METHOD: 7,
  // USER_TOKEN: 8,
  MATCH_PAIR: 8,
  ACCOUNT_TERM: 9,
  USER_VERIFICATION: 10,
  DASHBOARD_COUNT: 11,
  BIFURCATE_INVENTORY: 13,
  MATCH_PAIR_FILTER: 14,
  NEW_ARRIVAL_SETTING: 15,
  SUGGESTED_STOCK_PAIR_SETTING: 16,
  LOGIN_RESTRICTION: 17,
  BANK_RATE: 18,
};

export const MATCH_PAIR_SETTING = { MATCH_PAIR: 1 };

export const INVENTORY_UPLOAD_METHODS = [
  { key: 1, value: 'API' },
  { key: 2, value: 'FTP' },
];

export const INVENTORY_SYNC_TIME = [
  { key: 15, value: '15 mins' },
  { key: 30, value: '30 mins' },
  { key: 60, value: '60 mins' },
];

export const INVENTORY_FILE_TYPES = [
  { key: '.csv', value: 'csv' },
  { key: '.xlsx', value: 'xlsx' },
];

export const ACCOUNT_TERM_TYPE = {
  GLOBAL: 1,
  GROUP: 2,
  INDIVIDUAL: 3,
  SHOW: 4,
};

export const DIAMOND_FEATURED_GROUP_TYPE = {
  // PAIR: 'pair',
  // STONE: 'stone',
  // EXCLUSIVE: 'exclusive',
  // BEST: 'best',
  // STONE_OF_DAY: 'stone_of_day',
  FEATURE_STONE: 'exclusive',
};

export const BANNER_TYPE = {
  HOMEPAGE: 1,
};
export const DIAMOND_BLOCK = {
  TYPE: {
    HOLD: 1,
    MEMO: 2,
  },
  STATUS: {
    PENDING: 1,
    APPROVED: 2,
    REJECT: 3,
    RELEASE: 4,
  },
  OFFER: {
    APPLIED: 1,
    ACCEPTED: 2,
    REJECTED: 3,
  },
};
export const OFFICE_STATUS = {
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
  CANCELLED: 4,
};
export const ORDER_STATUS = {
  PENDING: 1,
  APPROVE: 2,
  REJECTED: 3,
  CANCELLED: 4,
  HISTORY: [2, 5],
  DIRECT_REJECT: 3,
};
export const ORDER_REQUEST = {
  PENDING: 1,
  APPROVED: 2,
  DELIVERED: 3,
  REJECTED: 4,
};
export const QUOTE_STATUS = {
  PENDING: 1,
  APPROVE: 2,
  REJECTED: 3,
};

export const ACCOUNT_IS_VERIFIED = {
  1: 'PENDING',
  2: 'VERIFIED',
  3: 'CANCELLED',
};

export const MEMO_STATUS = {
  PENDIND: 1,
  APPROVED: 2,
  REJECT: 3,
  RELEASE: 4,
};

export const SHIP_MODE = [
  { value: 'Ship To Direct', key: 0 },
  { value: 'Ship To NY', key: 1 },
  { value: 'Ship To HK', key: 2 },
  { value: 'Ship to Dubai', key: 3 },
  { value: 'Ship to Antwerp', key: 4 },
  { value: 'Ship to Japan', key: 5 },
];

export const INVOICE_DATE = [
  { value: 'Today', key: 0 },
  { value: 'Tomorrow', key: 1 },
  { value: 'Later', key: 2 },
];

export const PROJECT_SETTINGS = {
  TYPE: {
    CM_CHARGES: 1,
    BANK_RATES: 2,
  },
};

export const MEETING_TYPE = [
  { value: 'Phone Call', key: 1 },
  { value: 'Web Conference', key: 2 },
  { value: 'In Person', key: 3 },
];
export const LEDGER_TYPE = [
  { key: 'ceo', value: 'CEO' },
  { key: 'designer', value: 'Designer' },
  { key: 'director', value: 'Director' },
  { key: 'broker', value: 'Broker' },
  // { key: 'associate office', value: 'Associate office' },
  // { key: 'mfg unit', value: 'Mfg unit' },
  // { key: 'individual', value: 'Individual' },
  // { key: 'buyer', value: 'Buyer' },
];

export const OFFERSTATUS = {
  1: 'Pending',
  2: 'Accepted',
  3: 'Rejected',
};

export const TRANSACTION_HOLD_STATUS = ['WH'];
export const TRANSACTION_HOLD_BNR2_STATUS = ['B', 'CB', 'SB', 'EB', 'J', 'CJ', 'SJ', 'EJ'];
export const TRANSACTION_MEMO_CONSIGNMENT_STATUS = ['WM'];
export const TRANSACTION_MEMO_OFFICE_STATUS = ['O'];

const FILE_URL = 'https://arjivexports.blob.core.windows.net/arjivfiles/';
const FILE_PATH = {
  JPG: 'Imaged/IMAGE/***.jpg',
  JPG_ALT: 'HDView/imaged/***/***.jpg',
  JPG_NATURAL: 'Imaged/NIMAGE/***.jpg',
  JPG_LAYOUT: 'Imaged/Layout/***.jpg',
  HTML_360: 'HDView/Vision360.html?d=***',
  MP4_360: 'HDView/imaged/***/video.mp4',
  MP4_NATURAL: 'Imaged/NVIDEO/***.mp4',
  PDF_CERT: 'Imaged/PDF/***.pdf',
  JPG_ASSET: 'Imaged/HA/***/***-ASET%20white-01.jpg',
  JPG_IDEAL: 'Imaged/HA/***/***-IdealScope-01.jpg',
  JPG_HEARTS: 'Imaged/HA/***/***-Hearts-01.jpg',
  JPG_ARROWS: 'Imaged/HA/***/***-Arrows-01.jpg',
  JPG_FLU: 'HDView/imaged/***/fluorescence.jpg',
  JPG_PLT: 'Imaged/PLOTING/***.png',
  JPG_MSR: 'Imaged/PLOTING/MEASUREMENT/***.png',
};

export const getFileUrl = memoize(
  (key = 'JPG', replace = '###') => `${FILE_URL}${FILE_PATH[key] ?? ''}`.replace(/[*]{3}/g, replace),
  (...args) => JSON.stringify(args),
);

export const FILE_URLS = {
  // OLD
  IMAGE_LAYOUT: 'https://arjivexports.blob.core.windows.net/arjivfiles/Imaged/Layout/***.jpg', // layoutNo
  JPG_ALT: 'https://arjivexports.blob.core.windows.net/arjivfiles/HDView/imaged/***/***.jpg',

  img: 'https://arjivexports.blob.core.windows.net/arjivfiles/Imaged/THUMB/***.jpg',
  image: 'https://arjivexports.blob.core.windows.net/arjivfiles/Imaged/IMAGE/***.jpg',
  hAFile: 'https://arjivexports.blob.core.windows.net/arjivfiles/Imaged/HA/***/***-ASET white-01.jpg', //Packet No
  heartImage: 'https://arjivexports.blob.core.windows.net/arjivfiles/Imaged/HA/***/***-Hearts-01.jpg',
  arrBlack: 'https://arjivexports.blob.core.windows.net/arjivfiles/Imaged/HA/***/***-Arrows-01.jpg', // Arrow Black Image
  idealWhiteImage: 'https://arjivexports.blob.core.windows.net/arjivfiles/Imaged/HA/***/***-IdealScope-01.jpg', //Packet No
  pltFile: 'https://arjivexports.blob.core.windows.net/arjivfiles/Imaged/PLOTING/***.png', // Plot Image
  proportionImage: 'https://arjivexports.blob.core.windows.net/arjivfiles/Imaged/PLOTING/MEASUREMENT/***.png', // Proportion Image
  roughImage: 'https://arjivexports.blob.core.windows.net/arjivfiles/Imaged/ROUGH/***/still.jpg', // Rough Image
  planImg: 'https://arjivexports.blob.core.windows.net/arjivfiles/Imaged/3D/***.png', // 3D Image

  certFile: 'https://arjivexports.blob.core.windows.net/arjivfiles/Imaged/PDF/***.pdf', //packet
  videoFile: 'https://arjivexports.blob.core.windows.net/arjivfiles/HDView/Vision360.html?d=***', // HD Video in html
  hdVideoFileMp4: 'https://arjivexports.blob.core.windows.net/arjivfiles/HDView/imaged/***/video.mp4', //HD  Video in Mp4
  mp4Video: 'https://arjivexports.blob.core.windows.net/arjivfiles/Imaged/NVIDEO/***.mp4', //Natural Video in Mp4
  roughVideoFile: 'https://arjivexports.blob.core.windows.net/arjivfiles/Imaged/ROUGH/***/video.mp4', // Rough Video
  naturalImage: 'https://arjivexports.blob.core.windows.net/arjivfiles/Imaged/NIMAGE/***.jpg',
  fluorescenceImage: 'https://arjivexports.blob.core.windows.net/arjivfiles/HDView/imaged/***/fluorescence.jpg',
};

export const SHOW_SELECTION = {
  SHOW: 1,
  QUOTE_DAY: 2,
};
export const REFERENCE_BY = [
  { value: 'Client Reference', key: 1 },
  { value: 'Sales Person', key: 2 },
];

export const KYC_STATUS = [
  { key: 1, value: 'Pending' },
  { key: 2, value: 'Approved' },
  { key: 3, value: 'Rejected' },
];

export const approveStatusLabel = {
  1: 'Pending',
  2: 'Approved',
  3: 'Rejected',
};

export const approveStatus = [
  { key: 1, value: 'Pending' },
  { key: 2, value: 'Approve' },
  { key: 3, value: 'Reject' },
];

export const DateTitle = {
  offer: i18n.t('title.offer') + ' Expiry Date :',
  officeView: 'Office Visit Date :',
  hold: 'Hold Expiry Date :',
};

export const REGISTRATION_TYPES = {
  1: 'International',
  2: 'International Associate',
  3: 'Domestic',
  4: 'Domestic Associate',
};

export const packetType = [
  { name: 'All', id: 0 },
  { name: 'HK', id: 1 },
  { name: 'USA', id: 2 },
];
export const CommonPrintExcel = {
  inNewArrival: {
    prcStg: 'NEW',
    filter: {
      wSts: 'B',
      sSts: { nin: ['P', 'D'] },
    },
  },
  inUpComing: {
    isUpcoming: true,
    filter: { wSts: 'U', sSts: { nin: ['P', 'D'] } },
  },
};

export const HkUsaPacket = {
  stock: 0,
  sale: 1,
  hold: 2,
};

export const ReviseEmailType = {
  cart: '1',
  watchlist: '2',
};

export const DiamondUploadTypes = {
  add_update: '1',
  replace_all: '2',
};

export const DIAMOND_DISPLAY_TYPE = {
  BUSINESS: 1,
  NEW: 2,
  AVAILABLE: 3,
  INLAB: 4,
  EXCLUSIVE: 11,
  BEST: 12,
  STONE_OF_DAY: 13,
  LUCKY: 14,
};

export const EXCEL_PDF = {
  TRACK: 1, //cart, watchlist, offer, enquiry
  ORDER: 2, //memp/paginate
  BLOCK: 3, //HOLD-MEMO
  SCHEDULE: 4, //cabin-schedule
  COMMENT: 5, //Notes
};

export const BID_WEEK_DAYS = [
  { key: 'Sunday', value: 'Sunday' },
  { key: 'Monday', value: 'Monday' },
  { key: 'Tuesday', value: 'Tuesday' },
  { key: 'Wednesday', value: 'Wednesday' },
  { key: 'Thursday', value: 'Thursday' },
  { key: 'Friday', value: 'Friday' },
  { key: 'Saturday', value: 'Saturday' },
];
export const SHAPE_LIMIT = 31;

export const ENQUIRY_STATUS = { OPEN: 1, CLOSE: 2 };

export const MEMO_TYPES = { HOLD: 1, MEMO_IN: 2, MEMO_OUT: 3 };
export const MEMO_TYPES_NAME = { 1: 'Hold', 2: 'Memo In', 3: 'Memo Out' };

export const SELLER_PAGE_LIMIT = 250;

export const DYNAMIC_BID_TYPE = [
  { key: 1, value: 'NEW_ARRIVAL' },
  { key: 2, value: 'BID_TO_BUY' },
];

export const INVENTORY_BID = [
  { key: 'DAILY', value: 'DAILY' },
  { key: 'WEEKLY', value: 'WEEKLY' },
  // { key: 'MONTHLY', value: 'MONTHLY' },
];

export const INVENTORY_TIMEZONE = [
  { key: 'CET', value: 'CET' },
  { key: 'IST', value: 'IST' },
];
export const INVENTORY_CALCOLUMN = [
  { key: 'all', value: 'ALL' },
  { key: 'ctPr', value: 'PRICE/CT' },
  { key: 'back', value: 'DISC%' },
];
export const INVENTORY_RESULT_TYPE = [
  { key: 'AUTO', value: 'AUTO' },
  { key: 'MANUEL', value: 'MANUEL' },
];
export const INVENTORY_RESULT = [
  // { key: '1', value: 'Split' },
  { key: '2', value: 'Actual' },
];
export const INVENTORY_DIAMPRESTATUS = [
  { key: 'B', value: 'Bid To Buy' },
  { key: 'N', value: 'New Arrival' },
];
export const INVENTORY_DIAMPOSTSTATUS = [
  { key: 'B', value: 'Bid To Buy' },
  { key: 'N', value: 'New Arrival' },
];
export const INVENTORY_DIAMSTATUS = [
  { key: 'A', value: 'A.B.S' },
  //{ key: 'D', value: 'Best Buy' },
  // { key: 'N', value: 'New Arrival' },
];

export const QUARTERLY_FILTER = {
  1: 'Quarterly',
  2: '6 Month',
  3: 'Yearly',
};

export const RECENT_ACTIVITY_MODULES = {
  user: 'User',
  'diamond-search': 'Diamond Search',
  cart: 'Cart',
  watchlist: 'WatchList',
  enquiry: 'Enquiry',
  comment: 'Notes',
  'confirm-stone': 'Confirm Stone',
  hold: 'Hold',
  memo: 'Business Process',
  // offer: 'Offer',
  // appointment: 'Appointment',
  // 'article',
  // 'demand',
  // 'diamond',
  // 'purchase',
  // 'request',
  // 'reminder',
  // 'shipment',
  // 'track',
  // 'pair setting',
  // 'setting',
  // 'sheet-upload-V1',
  // 'sheet-upload-V2',
  // 'sheet-upload',
  // 'sheet-restore',
  // 'bid',
};

export const RECENT_KYC_TYPE = {
  1: 'Pending',
  2: 'Approved',
  3: 'Rejected',
  4: 'All',
};

export const DAYS_LIST = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const ENQUIRY_FUNNEL_FILTER = {
  day: 'Daily',
  week: 'Weekly',
  month: 'Monthly',
};

export const DASHBOARD_SEARCH_FILTER_TYPES = {
  hours: 'Last Hours',
  day: 'Today',
  week: 'This Week',
  month: 'This Month',
};

export const MAX_ALLOWED_LOGIN_ATTEMPTS = 5;
