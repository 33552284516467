// import { pruneEmpty } from 'util/utils';

const TABLE_VALUES_INITIAL_STATE = {
  // _prevState: [],
};

export const TABLE_VALUES_ACTION_TYPES = {
  SET_VALUES: '@update-table/set-values',
  RESET_VALUES: '@update-table/reset-values',
  RESET: '@update-table/reset',
};

export const TABLE_VALUES_ACTIONS = {
  setValues: (payload = {}) => ({ type: TABLE_VALUES_ACTION_TYPES.SET_VALUES, payload }),
  resetValues: (payload = {}) => ({ type: TABLE_VALUES_ACTION_TYPES.RESET_VALUES, payload }),
  reset: (payload = {}) => ({ type: TABLE_VALUES_ACTION_TYPES.RESET, payload }),
};

export default (state = TABLE_VALUES_INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TABLE_VALUES_ACTION_TYPES.SET_VALUES: {
      return { ...state, [payload.table]: { ...state?.[payload.table], ...payload.data } };
    }

    case TABLE_VALUES_ACTION_TYPES.RESET_VALUES: {
      return { ...state, [payload.table]: {} };
    }

    case TABLE_VALUES_ACTION_TYPES.RESET: {
      return { ...TABLE_VALUES_INITIAL_STATE, _prevState: state._prevState };
    }

    default:
      return state;
  }
};
