import React from 'react';

import { Dropdown, Tooltip } from 'antd';

import { classNames, isString } from 'util/utils';

import { diamondParameters } from 'constants/Common';

import SortingFilterDropdown from './OverlayDropdownBack';

import { FILTER_COLUMNS } from '../Inventory/table-config';

import shortinglistdownWhiteSvg from 'assets/svg/InventoryResult/shortinglistdown-white.svg';
import shortinglistdownSvg from 'assets/svg/InventoryResult/shortinglistdown.svg';
import shortinglistupWhiteSvg from 'assets/svg/InventoryResult/shortinglistup-white.svg';
import shortinglistupSvg from 'assets/svg/InventoryResult/shortinglistup.svg';
import closeSvg from 'assets/svg/close.svg';
import sortingSvg from 'assets/svg/sorting.svg';

const ignoreClick = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

function ListTableHeaderBack(props) {
  const { column, filters, sortBy = [], setSortBy, toggleSortBy } = props;
  const { FilterOption, nodots } = props;

  const tooltipTitle =
    column?.desc || diamondParameters?.[column?.id]?.desc || diamondParameters?.[column?.id]?.name || column?.Header;

  const isColumnFiltered = filters?.some?.((filter) => filter.id === column.id || filter.id === column.accessor);

  const shouldShowFilterOverlay = column?.isSorted || (FilterOption !== false && FILTER_COLUMNS.includes(column?.id));

  const overlay = React.useMemo(
    () => (
      <React.Fragment>
        {(column?.isSorted || (FilterOption !== false && FILTER_COLUMNS.includes(column?.id))) && (
          <SortingFilterDropdown
            setSortBy={setSortBy}
            sortBy={sortBy}
            toggleSortBy={toggleSortBy}
            FilterOption={FilterOption}
            column={column}
          />
        )}
      </React.Fragment>
    ),
    [FilterOption, column, setSortBy, sortBy, toggleSortBy],
  );

  const clearSortBy = React.useCallback(
    (e) => {
      ignoreClick(e);
      column.clearSortBy();
    },
    [column],
  );

  const header = (
    <span className={classNames(['taleHeadTitle', isColumnFiltered && 'thead-filtered'])}>
      {column.render('Header')}
    </span>
  );

  return (
    <Dropdown
      getPopupContainer={(trigger) => trigger}
      overlayClassName="tabelDropdownClass"
      key={`filterOverlayDropdown${column.id}`}
      overlay={overlay}
      trigger={['click']}
    >
      <div
        // title={title}
        className={`tableHeaderMain ${nodots && 'squeceChange'}
        ${column.sort && !column.isSorted && 'shortingBoxShow'} ${column?.isSorted && 'shortingApply'}`}
        onClick={ignoreClick}
      >
        {!nodots && (
          <span className="listIcon">
            <img src={sortingSvg} alt="icon" />
          </span>
        )}
        {tooltipTitle ? (
          <Tooltip
            key={`headerDesc${tooltipTitle}`}
            title={isString(tooltipTitle) ? tooltipTitle : undefined}
            placement="bottom"
          >
            {header}
          </Tooltip>
        ) : (
          header
        )}
        {column?.isSorted && (
          <div className="selectShortingMainBlock">
            <div className="selectShorting" {...column.getSortByToggleProps()}>
              <span className="selectShortingCount" style={!column.isSorted ? { margin: 0 } : {}}>
                {column?.sortedIndex + 1}
              </span>
              {column.isSorted && (
                <span className="selectShortingArrow">
                  <img src={!column?.isSortedDescasc ? shortinglistupWhiteSvg : shortinglistdownWhiteSvg} />
                </span>
              )}
            </div>
            <div className="shortingClose" onClick={clearSortBy}>
              <img src={closeSvg} alt="icon" />
            </div>
          </div>
        )}
        {column.canSort && !column.isSorted && (
          <div className="shortingRound" {...column.getSortByToggleProps()}>
            <div className="shortingRoundInner">
              <img src={shortinglistupSvg} alt="icon" />
              <img src={shortinglistdownSvg} alt="icon" />
            </div>
          </div>
        )}
      </div>
    </Dropdown>
  );
}

export default React.memo(ListTableHeaderBack);
