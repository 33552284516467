import React from 'react';

import CommonModal from 'containers/CommonModal';
import InputBlock from 'containers/InputBlock';
import TextArea from 'containers/TextArea';

import { sendEmailAttachment, sendXRayEmailAttachment } from 'services/DiamondApi';
import { showNotification } from 'services/NotificationService';

import './action.less';
import { isFunction } from 'lodash';

import CircularProgress from 'components/CircularProgress';

import { useDispatch } from 'react-redux';

import { RowSelectService } from 'services/RowSelectService';

import { isEmpty } from 'util/utils';
import { groupByUserAccount } from 'util/utils/groupByUserAccount';

import { EXCEL_PDF } from 'constants/Common';
import OpenNotification from 'constants/OpenNotifications';

function SendEmailPopup(props) {
  const dispatch = useDispatch();
  const [subject, setSubject] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [cc, setCc] = React.useState('');
  const [email, setEmail] = React.useState(props?.client?.user?.email ?? '');
  const [subjectInvalid, setSubjectInvalid] = React.useState(false);
  const [emailInvalid, setEmailInvalid] = React.useState(false);
  const [ccInvalid, setCcInvalid] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    setSubject(props.xRay ? 'X-Ray Detail' : 'Diamond Details');

    setMessage(
      `Dear Sir/Madam,\nGreetings of the day from SL Diamonds Team.\nPlease have a look at below ${
        props.xRay ? 'X-Ray' : 'stock'
      } detail.`,
    );
  }, [props?.xRay]);

  const state = React.useMemo(
    () => {
      // prettier-ignore
      return { subject, message, cc, email, subjectInvalid, emailInvalid, ccInvalid, };
    },
    // prettier-ignore
    [subject, message, cc, email, subjectInvalid, emailInvalid, ccInvalid],
  );

  const handleEmailChange = (e) => {
    // const regEx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    // const result = regEx.test(String(e.target.value).toLowerCase());
    setEmail(e.target.value);
    setEmailInvalid(isEmpty(e.target.value.trim()));
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
    setSubjectInvalid(e.target.value.trim() === '');
  };

  const handleCCChange = (e) => {
    const regEx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const result = regEx.test(String(e.target.value).toLowerCase());
    const ccInvalid = e.target.value.trim() === '';
    setCc(e.target.value);
    setCcInvalid(ccInvalid);
  };

  const handleOk = async () => {
    const excelReq =
      props?.excelPDFTab == EXCEL_PDF.BLOCK
        ? { id: props.checked?.map((o) => o.blockId) }
        : { vStnId: props.checked?.map((d) => d.vStnId), isFcCol: false };

    if (email.trim() === '') {
      setEmailInvalid(true);
    } else {
      setLoader(true);

      const userAccount = groupByUserAccount(props?.checked, props?.client, 'vStnId');

      if (props.ids?.length < 6) {
        if (emailInvalid === false && subjectInvalid === false) {
          const obj = {
            ...state,
          };

          for (const key in obj) {
            if (obj[key] === false) {
              delete obj[key];
            }
          }

          if (props.xRay) {
            const [err, res] = await sendXRayEmailAttachment({ ...state, userAccount }, props.path);
            !err && showNotification(res);

            if (res.code === 'OK') {
              props.onDetailClose();
            }
          } else {
            const request = {
              orderDiamond: props?.orderList ?? undefined,
              sendEmail: true,
              email: obj,
              id: props.ids,
              filter: excelReq,
              userAccount,
              certFile: props.certFile,
              hAFile: props.hAFile,
              excelType: props.excelType == EXCEL_PDF.BLOCK ? props.excelType : undefined,
              ...(props.hAFile && { arrBlack: props.hAFile, heartImage: props.hAFile }),
              img: props.img,
              pltFile: props.isPlt,
              isPairExcel: props.currentType === 'INVENTORY.SINGLE_STONE.MATCH_PAIR' ? true : undefined,
              // isStock: props.isStock,
              videoFile: props.videoFile,
              // assetFile: props.assetFile,
              isExcel: props.isExcel,
              withOriginalStoneId: props?.excelForBoth ? undefined : props?.originalStoneId,
              arrowFile: props.arrowFile,
              mp4Video: props.mp4Video,
              roughVideo: props.roughVideo,
              flsImage: props.isFls,
              propImage: props.isProp,
              naturalImage: props.naturalImage,
            };

            const [err, res] = await sendEmailAttachment(request);
            !err && showNotification(res);

            if (res.code === 'OK') {
              // dispatch({ type: 'RESET_SELECTED_ROWS' });
            }
            props.onCancel(); //only email modal close
            if (isFunction(props.onClose)) props.onClose(); //whole modal close email model and main modal
          }
        }
      } else {
        props.onCancel();
        if (isFunction(props.onClose)) props.onClose(); //whole modal close email model and main modal
        OpenNotification({ type: 'error', title: `Sorry, file size is greater than 25MB and can't send via email.` });
      }
      setLoader(false);
    }
  };

  const handleMessageChange = React.useCallback((e) => {
    setMessage(e.target.value);
  }, []);

  return (
    <>
      <CommonModal
        visible={props.visible ?? props.sendEmail}
        handleOk={handleOk}
        handleCancel={props.onCancel}
        title="Send Email"
        submitTitle="Send Email"
        cancelTitle="Cancel"
        footerShow
        destroyOnClose
      >
        <div className="searchPopupCommon">
          <div className="searchPopupTwoValue1">
            <InputBlock
              label="Subject *"
              placeholder="Subject"
              value={subject}
              onChange={handleSubjectChange}
              error={subjectInvalid ? ' Please enter email subject' : ''}
            />
          </div>
          <div>
            <InputBlock
              className={props?.client?.user?.email ? 'disableBlock' : ''}
              label="Email *"
              placeholder="Email"
              autoFocus
              value={email}
              onChange={handleEmailChange}
              error={emailInvalid ? 'Please enter valid email' : ''}
            />
          </div>
          <div className="searchPopupTwoValue1">
            <InputBlock
              label="Mail CC"
              placeholder="Mail CC"
              value={cc}
              onChange={handleCCChange}
              error={ccInvalid ? 'Please enter valid cc email' : ''}
            />
          </div>
          <div className="searchPopupTwoValue1">
            <TextArea label="Message" placeholder="Message" value={message} onChange={handleMessageChange} />
          </div>
          {loader && <CircularProgress className="fixLoader" />}
        </div>
      </CommonModal>
    </>
  );
}

export default SendEmailPopup;
