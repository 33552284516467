import React from 'react';

import PropTypes from 'prop-types';

import { MEMO_TYPES_NAME } from 'constants/Common';

export const STATUS_INDICATION = {
  A: { name: 'Available', color: '#6bc950', hover: 'Available' },
  NA: { name: 'Not Available', color: '#666666', hover: 'Not Available' },
  M: { name: 'Business Process', color: '#307bea', hover: 'Memo' },
  H: { name: 'Hold', color: '#db1c1c', hover: 'Hold' },
  B: { name: 'Bid', color: '#cddc39', hover: 'Bid' },
  U: { name: 'Upcoming', color: 'orange', hover: 'Upcoming' },
  CP: { name: 'Confirm Pending', color: 'yellow', hover: 'Confirm Pending' },
  C: { name: 'Confirm', color: '#1cdbc3', hover: 'Confirm' },
  Not_in_Sync: { name: 'Not in Sync', color: 'rgb(255 141 141)', hover: 'Not in Sync' },
};

function Status(props) {
  const soldFromClientSide = props?.isSold && props?.soldIndication;
  return (
    <div className="showSatusBlock">
      {props.status !== 'ALL' && (
        <div
          className="SelectcolorWiseList"
          style={{
            backgroundColor: STATUS_INDICATION[soldFromClientSide ? 'Not_in_Sync' : props.status]?.color,
          }}
        />
      )}
      {soldFromClientSide ? (
        <span>
          <strong>{STATUS_INDICATION['Not_in_Sync']?.name}</strong>
        </span>
      ) : (
        <span>
          {STATUS_INDICATION[props.status]?.name}
          <strong>{props?.memoType ? ` / ${MEMO_TYPES_NAME?.[props?.memoType]}` : ``}</strong>
        </span>
      )}
    </div>
  );
}

Status.propTypes = {
  status: PropTypes.string,
  showLive: PropTypes.bool,
};

export default React.memo(Status);
