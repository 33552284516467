import { Storage } from 'services/storage';
import { callApi, withURLParams } from 'services/util';

import { isEmpty } from 'util/utils';

import { API_ROUTES } from 'constants/CommonApi';

export function getAccountDetails(id, payload = {}, config) {
  const user = id ?? Storage.get('user')?.account?.id;
  if (isEmpty(user)) return [];
  return callApi({ ...withURLParams(API_ROUTES.Account.get, [user]), payload, ...config });
}

export function getAccountsList(payload, config) {
  return callApi({ ...API_ROUTES.Account.paginate, payload, ...config });
}

export function createAccount(payload = {}, config) {
  return callApi({ ...API_ROUTES.Account.create, payload, ...config });
}

export function updateAccount(id, payload = {}) {
  payload = { id, ...payload };
  return callApi({ ...API_ROUTES.Account.update, payload });
}

export function changeAccountStatus(payload = {}) {
  return callApi({ ...API_ROUTES.Account.changeStatus, payload });
}

export function getHighEndClient(payload = {}, config) {
  return callApi({ ...API_ROUTES.Account.getHighEndClient, payload, ...config });
}
