import { callApi, withURLParams } from 'util/call-api';

import { API_ROUTES } from 'constants/CommonApi';

export function createMany(payload = {}, config) {
  return callApi({ ...API_ROUTES.User.createMany, payload, ...config });
}

export function downloadUserExcel(payload = {}, config) {
  return callApi({ ...API_ROUTES.User.downloadExcel, payload, ...config });
}

export function getUserList(payload = {}, config) {
  return callApi({ ...API_ROUTES.User.paginate, payload, ...config });
}

export function getUserToken(payload = {}, config) {
  return callApi({ ...API_ROUTES.User.generateToken, payload, ...config });
}

export function getUserDetails(id = null, config) {
  return callApi({ ...withURLParams(API_ROUTES.User.getDetail, [id]), ...config });
}

export function resendVerificationEmail(payload = {}, config) {
  return callApi({ ...API_ROUTES.User.resenVerificationEmail, payload, ...config });
}

export function removeFailedAttempt(payload = {}, config) {
  return callApi({ ...API_ROUTES.User.removeAttempts, payload, ...config });
}

export function resetUserPassword(payload = {}, config) {
  return callApi({ ...API_ROUTES.User.ResetPassword, payload, ...config });
}

export function storeUserDetail(payload = {}, config) {
  return callApi({ ...API_ROUTES.User.Upsert, payload, ...config });
}

export function syncUser(payload = {}, config) {
  return callApi({ ...API_ROUTES.User.manualUserSync, payload, ...config });
}

export function updateUserDetail(id = null, payload = {}, config) {
  return callApi({ ...withURLParams(API_ROUTES.User.update, [id]), payload, ...config });
}
