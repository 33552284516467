import React from 'react';
import { useSelector } from 'react-redux';

import { RowSelectService } from 'services/RowSelectService';

import { isArray } from 'util/utils';

const useRowSelect = (currentType) => {
  const selectedRowIds = useSelector((state) => state?.diamondData?.selectedRowIds?.[currentType] ?? []);

  const toggleRowSelection = React.useCallback(
    (row, select = true) => {
      if (!row?.id) return;
      select ? RowSelectService.selectRows(currentType, [row]) : RowSelectService.unSelectRows(currentType, [row]);
    },
    [currentType],
  );

  const toggleAllRowSelection = React.useCallback(
    (rows = [], replace = false) => {
      const areAllChecked = rows.every((row) => selectedRowIds.includes(row?.selectionKey ?? row?.id));

      areAllChecked
        ? RowSelectService.resetSelectedRows(currentType, replace)
        : RowSelectService.selectRows(currentType, rows, replace);
    },
    [currentType, selectedRowIds],
  );

  const isHeaderChecked = React.useCallback(
    (rows) => {
      if (!isArray(rows)) return;
      const allRowIds = rows.map((row) => row?.selectionKey ?? row?.id);
      return Boolean(allRowIds.every((id) => selectedRowIds.includes(id)));
    },
    [selectedRowIds],
  );

  const isRowChecked = React.useCallback((rowId) => selectedRowIds.includes(rowId), [selectedRowIds]);

  const clearAllRows = React.useCallback(() => RowSelectService.resetSelectedRows(currentType), [currentType]);

  return {
    isRowChecked,
    isHeaderChecked,
    toggleRowSelection,
    toggleAllRowSelection,
    clearAllRows,
  };
};

export default useRowSelect;
