import React from 'react';

import debounce from 'lodash/debounce';

import { catchError } from 'util/utils';

export function useIsIntersecting(ref, options) {
  const [isIntersecting, setIntersecting] = React.useState(false);

  options = { ...options };

  const observer = React.useMemo(() => {
    return catchError(() => {
      const debouncedSetIntersecting = debounce(setIntersecting);
      return new IntersectionObserver(
        ([entry]) => {
          return entry.isIntersecting
            ? setIntersecting(entry.isIntersecting)
            : debouncedSetIntersecting(entry.isIntersecting);
        },
        {
          root: options.root ?? null,
          rootMargin: options.rootMargin ?? '0%',
          threshold: options.threshold ?? 0,
        },
      );
    });
  }, [options.root, options.rootMargin, options.threshold]);

  React.useLayoutEffect(() => {
    // stop observing when rendered
    if (isIntersecting && options.freezeOnceVisible) return;

    catchError(() => observer.observe(ref.current));
    return () => catchError(() => observer.disconnect());
  }, [isIntersecting, observer, options.freezeOnceVisible, ref]);

  return isIntersecting;
}
