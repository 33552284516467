import { createBrowserHistory } from 'history';
// import { runInDevelopment } from './utils';

const history = createBrowserHistory();

// runInDevelopment(() => {
//   // eslint-disable-next-line no-console
//   history.listen(console.info);
// });

export { history };
