import React from 'react';
import { useSelector } from 'react-redux';

import { Tooltip } from 'antd';
import { sum } from 'lodash';

import { StoneCollection } from 'entities/StoneCollection';

import { RowSelectService } from 'services/RowSelectService';

import { useCurrentType } from 'util/hooks';
import { formatCurrency, formatDecimal, isEmpty } from 'util/utils';

import CopyPacketIcon from 'assets/svg/copy-packet.svg';
import CopyPacketIcon1 from 'assets/svg/copy-packet1.svg';

export { formatNumber } from 'util/utils';

export function getExtraPer(terms) {
  const extraPer = terms?.addPer ? terms.addPer : 0;
  return extraPer;
}

export function calculate(dataList) {
  dataList = dataList.map((item) => ({ ...item }));

  const sum_total = {
    total_pieces: 0,
    total_carat: 0,
    total_avg_rap: 0,
    total_rap_avg: 0,
    final_discount: 0,
    final_cost_discount: 0,
    final_price: 0,
    final_value: 0,
    total_rapaport: 0,
    avg_discount: 0,
    final_net_value: 0,
    final_cost_value: 0,
    final_cost_price: 0,
    final_cost_rate: 0,
    final_rate: 0,
    final_term_discount: 0,
    avg_term_discount: 0,
    fancy_price_per_carat: 0,
  };
  const total = {
    fancy_total: { ...sum_total },
    non_fancy_total: { ...sum_total },
  };
  total.fancy_total.isFancy = 1;
  total.non_fancy_total.isFancy = 0;

  dataList.forEach((lst) => {
    const use_total_key = lst.isFancy ? 'fancy_total' : 'non_fancy_total';
    total[use_total_key].total_pieces += 1;
    total[use_total_key].total_carat += lst.crt && parseFloat(lst.crt) ? parseFloat(lst.crt) : 0;
    lst.rap_avg = lst.rap ? parseFloat(lst.rap) * parseFloat(lst.crt || 0) : 0;
    total[use_total_key].total_avg_rap += lst.rap_avg;
    total[use_total_key].total_rap_avg += lst.rap_avg;
    total[use_total_key].total_rapaport += lst.rap ? parseFloat(lst.rap) : 0;
    total[use_total_key].final_value += lst.amt || 0;
    total[use_total_key].final_net_value += lst.calcAmount || 0;
    total[use_total_key].fancy_price_per_carat += lst.newPricePerCarat || 0;
    total[use_total_key].final_cost_value += lst.totCst || 0;
  });

  total.fancy_total.final_price =
    total.fancy_total.final_value && total.fancy_total.total_carat
      ? total.fancy_total.final_value / total.fancy_total.total_carat
      : 0;

  total.non_fancy_total.final_price =
    total.non_fancy_total.final_value && total.non_fancy_total.total_carat
      ? total.non_fancy_total.final_value / total.non_fancy_total.total_carat
      : 0;

  total.fancy_total.final_rate =
    total.fancy_total.final_net_value && total.fancy_total.total_carat
      ? total.fancy_total.final_net_value / total.fancy_total.total_carat
      : 0;

  total.non_fancy_total.final_rate =
    total.non_fancy_total.final_net_value && total.non_fancy_total.total_carat
      ? total.non_fancy_total.final_net_value / total.non_fancy_total.total_carat
      : 0;

  total.fancy_total.total_avg_rap = total.fancy_total.total_avg_rap
    ? total.fancy_total.total_avg_rap / total.fancy_total.total_carat
    : 0;

  total.non_fancy_total.total_avg_rap = total.non_fancy_total.total_avg_rap
    ? total.non_fancy_total.total_avg_rap / total.non_fancy_total.total_carat
    : 0;

  total.fancy_total.final_discount =
    total.fancy_total.final_price && total.fancy_total.total_avg_rap
      ? (1 - total.fancy_total.final_price / total.fancy_total.total_avg_rap) * -100
      : 0;

  total.non_fancy_total.final_discount =
    total.non_fancy_total.final_price && total.non_fancy_total.total_avg_rap
      ? (1 - total.non_fancy_total.final_price / total.non_fancy_total.total_avg_rap) * -100
      : 0;

  //cost Releted calculate
  total.fancy_total.final_cost_price =
    total.fancy_total.final_cost_value && total.fancy_total.total_carat
      ? total.fancy_total.final_cost_value / total.fancy_total.total_carat
      : 0;

  total.non_fancy_total.final_cost_price =
    total.non_fancy_total.final_cost_value && total.non_fancy_total.total_carat
      ? total.non_fancy_total.final_cost_value / total.non_fancy_total.total_carat
      : 0;

  total.fancy_total.final_cost_rate =
    total.fancy_total.final_cost_value && total.fancy_total.total_carat
      ? total.fancy_total.final_cost_value / total.fancy_total.total_carat
      : 0;

  total.non_fancy_total.final_cost_rate =
    total.non_fancy_total.final_cost_value && total.non_fancy_total.total_carat
      ? total.non_fancy_total.final_cost_value / total.non_fancy_total.total_carat
      : 0;

  total.fancy_total.final_cost_discount =
    total.fancy_total.final_cost_price && total.fancy_total.total_avg_rap
      ? (1 - total.fancy_total.final_cost_price / total.fancy_total.total_avg_rap) * -100
      : 0;

  total.non_fancy_total.final_cost_discount =
    total.non_fancy_total.final_cost_price && total.non_fancy_total.total_avg_rap
      ? (1 - total.non_fancy_total.final_cost_price / total.non_fancy_total.total_avg_rap) * -100
      : 0;

  total.fancy_total.final_term_discount =
    total.fancy_total.final_rate && total.fancy_total.total_avg_rap
      ? (1 - total.fancy_total.final_rate / total.fancy_total.total_avg_rap) * -100
      : 0;

  total.non_fancy_total.final_term_discount =
    total.non_fancy_total.final_rate && total.non_fancy_total.total_avg_rap
      ? (1 - total.non_fancy_total.final_rate / total.non_fancy_total.total_avg_rap) * -100
      : 0;

  const allTotal = {
    total_pieces: total.fancy_total.total_pieces + total.non_fancy_total.total_pieces,
    total_carat: total.fancy_total.total_carat + total.non_fancy_total.total_carat,
    final_rapaport: total.fancy_total.total_avg_rap + total.non_fancy_total.total_avg_rap,
    final_rap_avg: total.fancy_total.total_rap_avg + total.non_fancy_total.total_rap_avg,
    final_discount: total.fancy_total.final_discount
      ? total.fancy_total.final_discount
      : total.non_fancy_total.final_discount,
    final_cost_discount: total.fancy_total.final_cost_discount
      ? total.fancy_total.final_cost_discount
      : total.non_fancy_total.final_cost_discount,
    final_value: total.fancy_total.final_value + total.non_fancy_total.final_value,
    final_cost_value: total.fancy_total.final_cost_value + total.non_fancy_total.final_cost_value,
    final_price:
      total.fancy_total.total_carat + total.non_fancy_total.total_carat
        ? (total.fancy_total.final_value + total.non_fancy_total.final_value) /
          (total.fancy_total.total_carat + total.non_fancy_total.total_carat)
        : 0,
    final_cost_price:
      total.fancy_total.total_carat + total.non_fancy_total.total_carat
        ? (total.fancy_total.final_cost_value + total.non_fancy_total.final_cost_value) /
          (total.fancy_total.total_carat + total.non_fancy_total.total_carat)
        : 0,
    total_rapaport: total.fancy_total.total_rapaport + total.non_fancy_total.total_rapaport,
    final_net_value: total.fancy_total.final_net_value + total.non_fancy_total.final_net_value,
    final_rate:
      total.fancy_total.total_carat + total.non_fancy_total.total_carat
        ? (total.fancy_total.final_net_value + total.non_fancy_total.final_net_value) /
          (total.fancy_total.total_carat + total.non_fancy_total.total_carat)
        : 0,
    final_cost_rate:
      total.fancy_total.total_carat + total.non_fancy_total.total_carat
        ? (total.fancy_total.final_cost_value + total.non_fancy_total.final_cost_value) /
          (total.fancy_total.total_carat + total.non_fancy_total.total_carat)
        : 0,
    fancy_price_per_carat: total.fancy_price_per_carat
      ? (total.fancy_total.fancy_price_per_carat + total.non_fancy_total.fancy_price_per_carat) /
        (total.fancy_total.total_carat + total.non_fancy_total.total_carat)
      : 0,
    final_term_discount: total.fancy_total.final_term_discount
      ? total.fancy_total.final_term_discount
      : total.non_fancy_total.final_term_discount,
  };
  allTotal.avg_discount = allTotal.final_rapaport ? allTotal.final_price / allTotal.final_rapaport : 0;
  allTotal.avg_term_discount = allTotal.final_rapaport ? allTotal.final_rate / allTotal.final_rapaport : 0;
  const newPricePerCaratData = dataList.map((d) => Number(d.newPricePerCarat));

  allTotal.fancy_price_per_carat =
    newPricePerCaratData.length && dataList.length ? sum(newPricePerCaratData) / dataList.length : 0;

  return allTotal;
}

export function newDiamondPrice(diamond, terms = {}, qt = false, qChange) {
  diamond = { ...diamond };
  const extraPer = terms && terms.extraPer ? terms.extraPer : 0;
  // if (!extraPer) {
  //   ['pricePerCarat', 'calcPricePerCarat', 'calcDiscount', 'calcAmount'].forEach((key) => delete diamond[key]);
  // }
  // const rapPer = 0 //terms && terms.rapPer ? terms.rapPer : 0
  const quotePer = qt && diamond.finalquote ? Number(parseFloat(diamond.finalquote * -1).toFixed(2)) : 0;

  // if (extraPer || quotePer) {
  let pricePerCarat = ['bid'].includes(qt)
    ? diamond.bidPricePerCarat
    : quotePer
    ? Number(parseFloat(diamond.rap - (diamond.rap * quotePer) / 100).toFixed(2))
    : diamond.ctPr;
  if (qChange === 'quote') pricePerCarat = (diamond.finalquote * diamond.rap) / 100 + diamond.rap;
  if (qChange === 'ctPr') pricePerCarat = diamond.newPricePerCarat;
  diamond.calcPricePerCarat = Number(parseFloat(pricePerCarat - (pricePerCarat * Math.abs(extraPer)) / 100).toFixed(2));
  diamond.calcDiscount = Number(parseFloat((1 - diamond.calcPricePerCarat / diamond.rap) * 100 * -1).toFixed(2));
  diamond.calcAmount = Number(parseFloat(diamond.calcPricePerCarat * diamond.crt).toFixed(2));
  // } else {
  //   let pricePerCarat = diamond.ctPr;
  //   diamond.calcPricePerCarat = Number(parseFloat(pricePerCarat).toFixed(2));
  //   diamond.calcDiscount = Number(parseFloat((1 - diamond.calcPricePerCarat / diamond.rap) * 100 * -1).toFixed(2));
  //   diamond.calcAmount = Number(parseFloat(diamond.calcPricePerCarat * diamond.crt).toFixed(2));
  // }
  return diamond;
}

export const quoteCalculation = (diamondList) => {
  const newDiamond = {
    oldDiscount: 0,
    newDiscount: 0,
    offerValue: 0,
  };

  diamondList.forEach((data) => {
    newDiamond.oldDiscount += data.back * data.crt;
    newDiamond.newDiscount += data.newDiscount * data.crt;
    newDiamond.offerValue += data.newAmount * data.crt;
  });

  const { total_carat } = calculate(diamondList);
  newDiamond.oldDiscount = newDiamond.oldDiscount / total_carat;
  newDiamond.newDiscount = newDiamond.newDiscount / total_carat;
  newDiamond.offer = newDiamond.newDiscount;
  newDiamond.offerValue = newDiamond.offerValue / total_carat;
  newDiamond.discountDifferent = newDiamond.newDiscount - newDiamond.oldDiscount;
  return newDiamond;
};

const SelectStone = (props) => {
  const { sum } = props;

  const [currentType] = useCurrentType(props.currentType);
  const selectedRows = useSelector((state) => state?.diamondData?.selectedRows?.[currentType] || []);

  const calcList = React.useMemo(() => {
    const total = calculate(selectedRows);

    return [
      {
        label: 'Pieces',
        check: total.total_pieces || 0,
        sum: sum?.pieces || sum?.count || 0,
      },
      {
        label: 'Cts',
        check: formatDecimal(total?.total_carat ?? 0),
        sum: formatDecimal(sum?.totalCarat ?? 0),
      },
      {
        label: 'Rap $Amount',
        check: formatCurrency(total?.final_rap_avg),
        sum: formatDecimal(sum?.rapPrice ?? 0),
      },
      {
        label: 'Rap Price',
        check: formatCurrency(total?.final_rapaport ?? 0),
        sum: formatDecimal(sum?.rapAvg ?? 0),
      },
      {
        label: 'Avg Disc',
        check: `${formatDecimal(total?.final_discount ?? 0)}%`,
        cost: ` / ${formatDecimal(total?.final_cost_discount ?? 0)}%`,
        sum: formatDecimal(sum?.avgDiscount ?? 0),
      },
      {
        label: 'Total Ct/Pr',
        check: `${formatCurrency(total?.final_price ?? 0)} `,
        cost: ` / ${formatCurrency(total?.final_cost_price ?? 0)}`,
        sum: formatDecimal(sum?.totalPricePerCarat ?? 0),
      },
      {
        label: 'Amount',
        check: `${formatCurrency(total?.final_value ?? 0)} `,
        cost: ` / ${formatCurrency(total?.final_cost_value ?? 0)}`,
        sum: formatDecimal(sum?.totalAmount ?? 0),
      },
    ];
  }, [selectedRows, sum]);

  const copyPacketNo = () => new StoneCollection({ type: currentType }).copyVStnIdListToClipboard();
  const copyPacketNo1 = () => new StoneCollection({ type: currentType }).copyVStnIdListToClipboard1();
  const clearSelectedRows = () => RowSelectService.resetSelectedRows(currentType);

  return (
    !isEmpty(selectedRows) && (
      <>
        <div className="selectStoneValueBlock">
          {calcList.map((item, index) => (
            <div className="selectStoneValueItem" key={index}>
              <span className="selectStoneLabel">{item.label} : </span>
              <span className="selectStopnValue">
                <span className="redColor">{item.check}</span>
                <span className="blueColor">{item.cost}</span>
              </span>
            </div>
          ))}
          {/* {calcListForCost.map((item, index) => (
            <div className="selectStoneValueItem" key={index}>
              <span className="selectStoneLabel">{item.label} : </span>
              <span className="selectStopnValue">
                <span className="redColor">{item.check}</span>
              </span>
            </div>
          ))} */}

          <span>
            <div className="copyButton">
              <Tooltip placement="bottom" title="Copy Stock No" onClick={copyPacketNo}>
                <img src={CopyPacketIcon} />
              </Tooltip>
            </div>
            <div className="copyButton1">
              <Tooltip placement="bottom" title="Copy Original Stock No" onClick={copyPacketNo1}>
                <img src={CopyPacketIcon1} />
              </Tooltip>
            </div>
          </span>
          <div className="searchStoneClose" onClick={clearSelectedRows}>
            Clear all
          </div>
        </div>
      </>
    )
  );
};

export default React.memo(SelectStone);
