import { isArray } from 'util/utils';

import { USERS_TYPE } from 'constants/Common';

const getInitialState = () => ({
  clientList: {
    count: 0,
    clientList: [],
    detailShow: true,
    listShow: false,
    visible: true,
    show: true,
    customerDetail: null,
    filter: { page: 1, limit: 15 },
    clientStates: [],
    clientCities: [],
    startWith: { keyword: null, keys: ['companyNormalizeName', 'companyName', 'name', 'username', 'email'] },
  },
  userList: {
    filter: {
      type: [USERS_TYPE.ADMIN, USERS_TYPE.SELLER, USERS_TYPE.EMPLOYEE, USERS_TYPE.INTERNAL_SELLER],
    },
    page: 1,
    limit: 15,
    data: [],
    count: 0,
    activeData: {},
    startWith: { keyword: null, keys: ['companyName', 'name', 'username', 'email'] },
  },
});

export const TabActionTypes = {
  SET_CLIENT_LIST: 'SET_CLIENT_LIST',
  ADD_CLIENT_TO_LIST: 'ADD_CLIENT_TO_LIST',
  SET_USER_LIST: 'SET_USER_LIST',
  RESET_TAB: 'RESET_TAB',
};

export const TabActions = {
  setClientList: (payload) => ({ type: TabActionTypes.SET_CLIENT_LIST, payload }),
  setUserList: (payload) => ({ type: TabActionTypes.SET_USER_LIST, payload }),
  addClientToList: (payload) => ({ type: TabActionTypes.ADD_CLIENT_TO_LIST, payload }),
  resetList: () => ({ type: TabActionTypes.RESET_TAB }),
};

const initialState = { ...getInitialState() };

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TabActionTypes.SET_CLIENT_LIST:
      return { ...state, clientList: { ...state.clientList, ...payload } };

    case TabActionTypes.SET_USER_LIST:
      return { ...state, userList: { ...state.userList, ...payload } };

    case TabActionTypes.ADD_CLIENT_TO_LIST: {
      const clientList = state?.clientList?.clientList;
      if (clientList?.[0]?.id === payload?.id) return state;
      const newClientList = [payload, ...(isArray(clientList) ? clientList : [])];
      return { ...state, clientList: { ...state.clientList, clientList: newClientList } };
    }

    case '@@RESET':
    case TabActionTypes.RESET_TAB:
      return { ...getInitialState() };

    default:
      return state;
  }
};
