import React from 'react';

import { setIn } from 'formik';
import memoize from 'lodash/memoize';

import { Notify } from 'util/notify';

export function useFormikHelpers(formik) {
  const { setValues: sV, setFieldTouched: sFT } = formik;

  const setFieldValue = React.useMemo(() => {
    return memoize(
      (path) => (value) => sV((values) => setIn(values, path, value)),
      (path) => `${path}`,
    );
  }, [sV]);

  const setFieldTouched = React.useMemo(() => {
    return memoize(
      (path) => () => sFT(path, true),
      (path) => `${path}`,
    );
  }, [sFT]);

  const preventAndStop = React.useCallback((e) => {
    void e?.preventDefault?.();
    void e?.stopPropagation?.();
    Notify.warn({ message: 'The form is already being submitting' });
  }, []);

  const handleSubmit = formik.isSubmitting ? preventAndStop : formik.handleSubmit;
  const handleReset = formik.isSubmitting ? preventAndStop : formik.handleReset;

  return { setFieldValue, setFieldTouched, handleSubmit, handleReset };
}
