import { createAction, createReducer } from '@reduxjs/toolkit';

const initialState = { loading: 0 };

export const LoaderActions = {
  start: createAction('@loader/start'),
  stop: createAction('@loader/stop'),
  adjust: createAction('@loader/adjust'),
  reset: createAction('@loader/reset'),
};

const loaderReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(LoaderActions.start, (state) => {
      state.loading = state.loading + 1;
    })
    .addCase(LoaderActions.stop, (state) => {
      state.loading = state.loading < 1 ? 0 : state.loading - 1;
    })
    .addCase(LoaderActions.adjust, (state, action) => {
      const count = action.payload.count ?? 0;
      const loading = state.loading + count;
      state.loading = loading < 1 ? 0 : loading;
    })
    .addCase(LoaderActions.reset, () => {
      return initialState;
    })
    .addCase('@@RESET', () => {
      return initialState;
    });
});

export default loaderReducer;
