import { groupBy } from 'lodash';

import { isEmpty } from './isEmpty';
import { isNotEmpty } from './isNotEmpty';

export function groupByUserAccount(checked, client, key = '') {
  if (
    checked?.find((stone) =>
      isEmpty(
        client?.user?.account?.id ??
          client?.user?.account ??
          stone?.userAccount ??
          stone?.user?.account?.id ??
          stone?.user?.account,
      ),
    )
  )
    return undefined;

  const userAccounts = checked?.map((stone) => ({
    userAccount:
      client?.user?.account?.id ??
      client?.user?.account ??
      stone?.userAccount ??
      stone?.user?.account?.id ??
      stone?.user?.account,
  }));

  return userAccounts?.[0]?.userAccount;
}
