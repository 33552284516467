import * as AccountDepartmentAPI from 'services/AccountDepartmentApi';

import { isArray } from 'util/utils';

const initialState = { loading: 0, accountDepartments: {} };

export const AccountDepartmentActionTypes = {
  SET_ACCOUNT_DEPARTMENT_LIST: 'SET_ACCOUNT_DEPARTMENT_LIST',
  GET_ACCOUNT_DEPARTMENR_LIST: 'GET_ACCOUNT_DEPARTMENR_LIST',
  START_ACCOUNT_DEPARTMENT_LOADING: 'START_ACCOUNT_DEPARTMENT_LOADING',
  STOP_ACCOUNT_DEPARTMENT_LOADING: 'STOP_ACCOUNT_DEPARTMENT_LOADING',
};

export const AccountDepartmentActions = {
  setAccountDepartmentList: (id, list) => ({
    type: AccountDepartmentActionTypes.SET_ACCOUNT_DEPARTMENT_LIST,
    payload: { id, list },
  }),
  getAccountDepartmentList: (id) => async (dispatch) => {
    dispatch({ type: AccountDepartmentActionTypes.START_ACCOUNT_DEPARTMENT_LOADING });
    const [, res] = await AccountDepartmentAPI.listAccountDepartment(id);

    const list = res?.data?.list;
    return dispatch({
      type: AccountDepartmentActionTypes.SET_ACCOUNT_DEPARTMENT_LIST,
      payload: { id, list: res?.code === 'OK' && isArray(list) ? list : [] },
    });
  },
};

export default (state = initialState, action) => {
  switch (action?.type) {
    case AccountDepartmentActionTypes.START_ACCOUNT_DEPARTMENT_LOADING:
      return { ...state, loading: state.loading + 1 };

    case AccountDepartmentActionTypes.STOP_ACCOUNT_DEPARTMENT_LOADING:
      return { ...state, loading: state.loading - 1 };

    case AccountDepartmentActionTypes.SET_ACCOUNT_DEPARTMENT_LIST:
      return {
        ...state,
        loading: state.loading - 1,
        list: { ...state.list, [action?.payload?.id]: action?.payload?.list },
      };

    case '@@RESET':
      return { ...initialState };

    default:
      return { ...state };
  }
};
