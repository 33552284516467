import { isString } from 'lodash';

import { Stone } from 'entities/Stone';

import { RowSelectService } from 'services/RowSelectService';

import { callApi } from 'util/call-api';
import { Notify } from 'util/notify';
import { catchError, isEmpty } from 'util/utils';

export class StoneCollection {
  static identifier = 'StoneCollection';

  stones = [];
  type = 'DEFAULT';

  constructor(stoneList = [], type) {
    const list = isString(stoneList.type) ? RowSelectService.getSelectedRows(stoneList.type) : stoneList;
    this.stones = list.map((detail) => new Stone(detail));
    this.type = type || this.type;
  }

  static TRACK_TYPES = {
    CART: 1,
    WATCH: 2,
    REQUEST: 3,
    OFFER: 4,
    REMINDER: 5,
    ENQUIRY: 6,
    SHIPMENT: 7,
    COMMENT: 99,
    BID_WISHLIST: 10,
    BID_PENDING: 11,
  };

  static BLOCK_TYPES = { HOLD: 1, MEMO: 2, ORDER: 3 };

  static BLOCK_STATUS = { PENDING: 1, APPROVED: 2, REJECT: 3, RELEASE: 4 };

  get vStnIdList() {
    return this.stones.map((stone) => stone.vStnId);
  }

  get stoneIdList() {
    return this.stones.map((stone) => stone.id);
  }

  copyVStnIdListToClipboard() {
    if (isEmpty(this.stones)) return Notify.error({ message: 'Please select stone(s) to copy stock no.' });

    catchError(
      async () => {
        const copyText = this.stones.map((stone) => stone?.vStnId);
        await navigator.clipboard.writeText(copyText.toString());
        Notify.destroy();
        Notify.success({ message: 'Stock No Copied!' });
      },
      () => {
        Notify.destroy();
        Notify.error({ message: 'Failed to copy Stock No!' });
      },
    );
  }

  copyVStnIdListToClipboard1() {
    if (isEmpty(this.stones)) return Notify.error({ message: 'Please select stone(s) to copy stock no.' });

    catchError(
      async () => {
        const copyText = this.stones.map((stone) => stone?.oldvStnId);
        await navigator.clipboard.writeText(copyText.toString());
        Notify.destroy();
        Notify.success({ message: 'Original Stock No Copied!' });
      },
      () => {
        Notify.destroy();
        Notify.error({ message: 'Failed to copy Original Stock No!' });
      },
    );
  }

  async addToTrack(trackType, payload) {
    if (isEmpty(this.stones)) return Notify.error({ message: 'Please, select stone(s) to add to track.' });

    payload = { ...payload, trackType };
    payload.user = payload.user ?? payload.client?.user?.id;
    payload.userAccount = payload.userAccount ?? payload.client?.id;

    payload.diamonds = this.stones.map((stone) => ({
      diamond: stone.id,
      trackPricePerCarat: stone.props.ctPr,
      trackAmount: stone.props.amt,
      newDiscount: payload.trackType === StoneCollection.TRACK_TYPES.WATCH ? stone.props.expBack : undefined,
    }));

    delete payload.client;

    return callApi({
      method: 'post',
      url: 'admin/v1/diamond-track/create',
      payload,
      showLoader: true,
      notifyResponse: true,
    });
  }

  async addToBlock(blockType, payload) {
    if (isEmpty(this.stones)) return Notify.error({ message: 'Please, select stone(s) to add to block.' });

    payload = { ...payload, packetType: blockType };
    payload.user = payload.user ?? payload.client?.user?.id;
    payload.userAccount = payload.userAccount ?? payload.client?.id;
    payload.remarks = payload?.remarks;

    payload.diamonds = this.stones.map((stone) => ({
      vStnId: stone.props.vStnId,
    }));

    delete payload.client;

    return callApi({
      method: 'post',
      url: 'admin/v1/transaction/hold-memo-transaction',
      payload,
      showLoader: true,
      notifyResponse: true,
    });
  }

  async addToCart(payload) {
    if (isEmpty(this.stones)) return Notify.error({ message: 'Please, select stone(s) to add to cart.' });

    return this.addToTrack(StoneCollection.TRACK_TYPES.CART, payload);
  }

  async addToWatch(payload) {
    if (isEmpty(this.stones)) return Notify.error({ message: 'Please, select stone(s) to add to watchlist.' });

    return this.addToTrack(StoneCollection.TRACK_TYPES.WATCH, payload);
  }

  async addToEnquiry(payload) {
    if (isEmpty(this.stones)) return Notify.error({ message: 'Please, select stone(s) to add to enquiry.' });

    return this.addToTrack(StoneCollection.TRACK_TYPES.ENQUIRY, payload);
  }

  async addToHold(payload) {
    if (isEmpty(this.stones)) return Notify.error({ message: 'Please, select stone(s) to hold.' });

    return this.addToBlock(StoneCollection.BLOCK_TYPES.HOLD, payload);
  }

  async addToMemo(payload) {
    if (isEmpty(this.stones)) return Notify.error({ message: 'Please, select stone(s) to memo.' });

    return this.addToBlock(StoneCollection.BLOCK_TYPES.MEMO, payload);
  }
}
