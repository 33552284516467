import React from 'react';
import { useSelector } from 'react-redux';

import { useAutoRef } from './useAutoRef';
import { useCurrentModule } from './useCurrentModule';

const useSelectedRowsRef = { defaultList: [] };

export function useSelectedRows(_moduleId, options) {
  options = { mapFields: [], ...options };
  const optionsRef = useAutoRef(options);

  const module = useCurrentModule();
  const moduleId = _moduleId ?? module.path;

  const rowList = useSelector((state) => {
    return state.diamondData.selectedRows?.[moduleId] ?? useSelectedRowsRef.defaultList;
  });

  const rowMap = React.useMemo(() => {
    const { mapFields } = optionsRef.current;
    return Object.fromEntries(mapFields.map((field) => [field, rowList.map((row) => row?.[field])]));
  }, [optionsRef, rowList]);

  React.useDebugValue({ rowList, rowMap });
  return [rowList, rowMap];
}
