export function appendBits(val, len, bb) {
  if (len < 0 || len > 31 || val >>> len != 0) throw new RangeError('Value out of range');
  for (let i = len - 1; i >= 0; i--) bb.push((val >>> i) & 1);
}

export function getBit(x, i) {
  return ((x >>> i) & 1) != 0;
}

export function assert(cond) {
  if (!cond) throw new Error('Assertion error');
}
