import { i18n } from 'util/i18n';

export const Messages = {
  CommentRequire: 'Please Enter Note.',
  FilterSelect: 'Please Select Filter.',

  //MemoPopupModal
  userAccountSelect: 'Please Enter Party.',
  userSelect: 'Please Enter buyer.',
  sellerSelect: 'Please Select Salesman.',
  shippingCountrySelect: 'Please Select Shipping Country.',
  shippingCitySelect: 'Please Select Shipping City.',
  stageSelect: 'Please Select Stage.',
  assistantSellerSelect: 'Please Select Assistant Salesman.',
  shipModeSelect: 'Please Select ShipMode.',
  brokerSelect: 'Please Enter Broker.',
  shippingChargeSelect: 'Please Enter Shipping Charge',

  // HoldPopUp
  clientNameSelect: 'Please Select Client Name.',
  typeSelect: 'Please Select Type.',
  timeSelect: 'Please Enter time between 0 to max.',
  hourTimeSelect: 'Please Enter Time Between MIN to MAX.',
  highEndClient: 'Please Select HighEnd Client.',

  //confirm stone
  billTypeSelect: 'Please Select Bill Type.',
  sourceOfSaleSelect: 'Please Select Source Of Sale.',
  courierNameSelect: 'Please Select Courier Name.',
  invoiceDateSelect: 'Please Select Invoice Date',

  //offer message
  sameClientSelect: 'Please Select Similar Customer Diamond.',
  //quoteDay
  quoteDaySelect: 'Please Select ' + i18n.t('title.offer') + ' Title.',
  //showStone
  referenceBySelect: 'Please Select Reference By.',
  showNameSelect: 'Please Select Show Name.',
  reconfirmRequire: 'Please Enter Reconfirm with.',
  resourceNotAvailable: 'The Download Resource Not Available.',
  virtualTypeSelect: 'Please Select Appointment Type. ',

  Todo: {
    Title: 'Please enter title.',
    successMessage: 'To do is added successfully.',
  },

  Error: {
    Required: {
      Company: 'Please Select Company Name.',
      Stones: 'Please Select Diamond.',
      MoreThanOneStone: 'Please select minimum two diamonds to compare.',
      MaxCompareStone: 'Maximum 15 stones can be compared.',
      MemoAndHoldStone: 'Sorry! Unable to perform action at this time. The selected diamond(s) is in hold/memo.',
    },
    NotFound: {
      Stones: 'No Stones Found',
    },
  },
};

export default Messages;
