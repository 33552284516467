import React from 'react';

import videojs from 'video.js';

import { typeOf, logWarn, classNames } from 'util/utils';

import 'video.js/dist/video-js.css';

/**
 * VideoPlayer
 * Common Wrapper for VideoJs player
 * @author Sagar Panchal <panchal.sagar@outlook.com>
 */
function VideoPlayer(props) {
  const { className, ...forwardProps } = props;
  const [videoNode, setVideoNode] = React.useState();

  const player = React.useMemo(() => {
    if (!typeOf(videoNode, 'HTMLVideoElement')) return;
    try {
      const sources = [{ src: props.src, type: props.type }];
      const options = {
        ...forwardProps,
        playbackRates: [0.5, 0.75, 1.0, 1.5, 1.75, 2.0],
        sources: props?.sources ?? sources,
      };
      delete options.src;
      delete options.type;
      return videojs(videoNode, options);
    } catch (error) {
      logWarn({ VideoPlayerError: error });
    }
    return undefined;
  }, [forwardProps, props?.sources, props.src, props.type, videoNode]);

  React.useEffect(() => {
    try {
      void player?.aspectRatio?.('16:9');
      return () => {
        void player?.dispose?.();
      };
    } catch (error) {
      logWarn({ VideoPlayerError: error });
    }
  }, [player]);

  React.useEffect(() => {
    try {
      if (!typeOf(videoNode, 'HTMLVideoElement')) return;
      videoNode.addEventListener('contextmenu', (e) => e.preventDefault());
      return () => {
        videoNode.removeEventListener('contextmenu', (e) => e.preventDefault());
      };
    } catch (error) {
      logWarn({ VideoPlayerError: error });
    }
  }, [videoNode]);

  return (
    <div className={classNames([className])}>
      <div data-vjs-player>
        <video ref={setVideoNode} className="video-js"></video>
      </div>
    </div>
  );
}

export default React.memo(VideoPlayer);
