import Axios from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';
import Cookies from 'js-cookie';

import { isEmpty } from './utils';

import { BASE_URL } from '../constants/Common';

export const axios = Axios.create({
  baseURL: BASE_URL,
  adapter: cacheAdapterEnhancer(Axios.defaults.adapter, { enabledByDefault: false, cacheFlag: 'useCache' }),
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
});

axios.interceptors.request.use(
  (config) => {
    const token = Cookies.get('token');
    if (!isEmpty(token)) config.headers['Authorization'] = `JWT ${token}`;
    if (isEmpty(config.headers['Authorization'])) delete config.headers['Authorization'];

    config.headers.devicetype = config.headers.devicetype ?? 1;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default axios;
