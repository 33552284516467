import React from 'react';

import { PermissionService } from 'services/PermissionService';

import { ModuleContext } from './ModuleContext';

export const ModuleProvider = React.memo(({ children }) => {
  const [tree, setTree] = React.useState(() => PermissionService.getTree());

  React.useEffect(() => {
    setTree(PermissionService.getTree());
    return PermissionService.events.updateTree.listen(() => setTree(PermissionService.getTree()));
  }, []);

  return <ModuleContext.Provider value={tree}>{children}</ModuleContext.Provider>;
});

ModuleProvider.displayName = 'ModuleProvider';
