import { resetCountAction } from 'routes/Transaction/Actions';

import { filterRecord, updateRowClassList } from 'components/Inventory/table-utils';

import { get, isArray, isEmpty, isNotEmpty, isObject, isString } from 'util/utils';

const initialState = {
  status: {},
  selectedRows: {},
  selectedRowIds: {},
};

export const DiamondActionTypes = {
  SELECT_ROWS: 'SELECT_ROWS',
  UNSELECT_ROWS: 'UNSELECT_ROWS',
  RESET_SELECTED_ROWS: 'RESET_SELECTED_ROWS',
};

export const DiamondActions = {
  selectRows: (payload = {}) => ({ type: DiamondActionTypes.SELECT_ROWS, ...payload }),
  unSelectRows: (payload = {}) => ({ type: DiamondActionTypes.UNSELECT_ROWS, ...payload }),
  resetSelectedRows: (payload = {}) => ({ type: DiamondActionTypes.RESET_SELECTED_ROWS, ...payload }),
};

export default (state = initialState, { type, ...data }) => {
  switch (type) {
    case DiamondActionTypes.SELECT_ROWS: {
      const payload = isObject(data?.payload) ? [data?.payload] : isArray(data?.payload) ? data?.payload : [];
      const currentType = isString(data?.currentType) ? data?.currentType : undefined;
      const currentRows = data?.replace ? [] : state?.selectedRows?.[currentType] ?? [];
      const currentRowIds = data?.replace ? [] : state?.selectedRowIds?.[currentType] ?? [];
      const currentStatus = data?.replace ? {} : state?.status?.[currentType] ?? {};
      const uniqueBy = data?.uniqueBy;

      if (isString(uniqueBy) && payload.length === 1) {
        const found = currentRows.find((row) => get(row, uniqueBy) === get(payload?.[0], uniqueBy));
        if (!isEmpty(found)) return state;
      }

      const newRows = payload
        .map((row) => {
          if (currentRowIds.includes(row?.selectionKey ?? row?.id)) return;
          return filterRecord(row, { original: false });
        })
        .filter(isNotEmpty);
      const newRowIds = newRows.map((row) => row?.selectionKey ?? row?.id);
      const newStatus = Object.fromEntries(newRowIds.map((id) => [id, true]));
      newRowIds.forEach((id) => updateRowClassList(currentType, id, true));

      return {
        ...state,
        status: { ...state?.status, [currentType]: { ...currentStatus, ...newStatus } },
        selectedRows: { ...state?.selectedRows, [currentType]: [...currentRows, ...newRows] },
        selectedRowIds: { ...state?.selectedRowIds, [currentType]: [...currentRowIds, ...newRowIds] },
      };
    }

    case DiamondActionTypes.UNSELECT_ROWS: {
      const payload = isObject(data?.payload) ? [data?.payload] : isArray(data?.payload) ? data?.payload : [];
      const currentType = isString(data?.currentType) ? data?.currentType : undefined;
      const currentRows = data?.replace ? [] : state?.selectedRows?.[currentType] ?? [];

      const rowIdsToRemove = payload.map((row) => row?.selectionKey ?? row?.id);
      rowIdsToRemove.forEach((id) => updateRowClassList(currentType, id, false));

      const newRows = currentRows.filter((row) => !rowIdsToRemove.includes(row?.selectionKey ?? row?.id));
      const newRowIds = newRows.map((row) => row?.selectionKey ?? row?.id);
      const newStatus = Object.fromEntries(newRowIds.map((id) => [id, true]));

      return {
        ...state,
        status: { ...state?.status, [currentType]: newStatus },
        selectedRows: { ...state?.selectedRows, [currentType]: newRows },
        selectedRowIds: { ...state?.selectedRowIds, [currentType]: newRowIds },
      };
    }

    case '@@RESET':
    case DiamondActionTypes.RESET_SELECTED_ROWS: {
      const currentType = isString(data?.currentType) ? data?.currentType : undefined;
      // updateRowClassList(currentType);

      const newState = isEmpty(currentType)
        ? { ...initialState }
        : {
            ...state,
            status: { ...state?.status },
            selectedRows: { ...state?.selectedRows },
            selectedRowIds: { ...state?.selectedRowIds },
          };

      delete newState?.t?.[currentType];
      delete newState?.status?.[currentType];
      delete newState?.selectedRows?.[currentType];
      delete newState?.selectedRowIds?.[currentType];

      resetCountAction.emit();
      return newState;
    }

    default:
      return state;
  }
};
