import { QrCode } from 'libraries/QrCode';

import { clone, formatDecimal, isNotEmpty } from 'util/utils';

import { DNA_URL } from 'constants/CommonUrl';

export class Stone {
  static identifier = 'Stone';

  props = {};

  constructor(details = {}) {
    this.props = clone(Stone.isStone(details) ? details.props : details);
  }

  static isStone(input) {
    return input?.constructor === Stone;
  }

  // prettier-ignore
  static INCLUSION_FIELDS = ['blkTblNm', 'opTblNm', 'blkSdNm', 'opCrwnNm', 'wTblNm', 'opPavNm', 'wSdNm', 'opGrd', 'kToSStr', 'lbCmt'];

  get id() {
    return this.props.diamondId ?? this.props.id ?? this.props.vStnId;
  }

  get vStnId() {
    return this.props.vStnId;
  }

  get oldvStnId() {
    return this.props.oldvStnId;
  }

  getDnaQrCodeSvg(...args) {
    const qrCode = QrCode.encodeText(`${DNA_URL}/${this.id}`, QrCode.Ecc.HIGH);
    return qrCode.toSVGString(...args);
  }

  getStonePropSummary() {
    return [
      formatDecimal(this.props.crt),
      this.props.isFcCol ? this.props.fcColNm : this.props.colNm,
      this.props.clrNm,
      this.props.cutNm,
      this.props.polNm,
      this.props.symNm,
      this.props.fluNm,
    ].filter(isNotEmpty);
  }
}
