import { createAction, createReducer } from '@reduxjs/toolkit';

const initialState = {
  selected: {},
};

export const clientActions = {
  set: createAction('@client/set'),
  reset: createAction('@client/reset'),
};

const clientReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clientActions.set, (state, { payload }) => {
      state.selected[payload.module] = payload.selected;
    })
    .addCase(clientActions.reset, () => {
      return initialState;
    })
    .addCase('@@RESET', () => {
      return initialState;
    });
});

export default clientReducer;
