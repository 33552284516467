import React from 'react';

import { Popover, Tooltip } from 'antd';
import { groupBy, memoize } from 'lodash';
import { DateTime } from 'luxon';

import ClientName from 'components/Client/ClientName';
import { FLOAT_COLUMNS, ROUND_COLUMNS, CURRENCY_COLUMNS, DISCOUNT_COLUMNS } from 'components/Inventory/table-config';

import { MasterService } from 'services/MasterService';
import { StoneService } from 'services/StoneService';

import { FRACTION_LENGTH } from 'util/constants';
import {
  catchError,
  classNames,
  clone,
  forEach,
  formatCurrency,
  formatDate,
  formatDateTime,
  formatDecimal,
  formatNumber,
  getUserName,
  isArray,
  isEmpty,
  isNotEmpty,
  isNumber,
  isObject,
  isString,
  logWarn,
  returnIfNotEmpty,
} from 'util/utils';

import { FRONT_URL } from 'constants/CommonUrl';

import moreBlackSvg from 'assets/svg/InventoryResult/more-black.svg';
import layoutSvg from 'assets/svg/layout.svg';

export const updateRecord = (record) => {
  if (isEmpty(record) || !isObject(record)) return;
  const newRecord = { ...record, _original: { ...(record._original ?? record) } };
  delete newRecord?._original?._original;

  return StoneService.formatStoneRecord(newRecord);
};

export const updateList = (list) => {
  if (isEmpty(list) || !isArray(list)) return;
  return list.map((record) => updateRecord(record));
};

export const filterRecord = (record, options = {}) => {
  if (isEmpty(record) || !isObject(record)) return;
  options = { remove: [], original: true, ...options };

  if (options.original && !isEmpty(record.original)) {
    const { _original, ..._selected } = record;
    return { ..._original, _selected };
  }

  return record;
};

export const filterList = (list, options = {}) => {
  if (isEmpty(list) || !isArray(list)) return;
  return list.map((record) => filterRecord({ ...record }, options));
};

export const isNewStone = (stone, options) => {
  if (options?.type === 'NewArrivalDiamondList') return false;
  if (!stone.fpDt || stone.crt > 2.999) return false;
  if (['B', 'U'].includes(stone.wSts)) return true;
  return DateTime.local() - DateTime.fromISO(stone?.fpDt) <= 604800000;
  // 7 * 24 * 60 * 60 * 1000 => 604800000
};

export const getStickyStyle = memoize((noStatus = false) => {
  const stickyHeader = { position: 'sticky', zIndex: 2000 };
  const stickyCell = { position: 'sticky', backgroundColor: '#fff', zIndex: 1000 };
  const firstLeft = -10;
  const firstWidth = noStatus ? 40 : 140;
  const secondLeft = firstWidth + firstLeft - 5;
  const secondWidth = 160;
  const thirdLeft = firstWidth + secondWidth + firstLeft - 10;

  return {
    head: {
      first: { left: firstLeft + 'px', ...stickyHeader, minWidth: firstWidth + 'px' },
      second: { left: secondLeft + 'px', ...stickyHeader, minWidth: secondWidth + 'px' },
      third: { left: thirdLeft + 'px', ...stickyHeader },
    },
    cell: {
      first: { left: firstLeft + 'px', ...stickyCell, minWidth: firstWidth + 'px' },
      second: { left: secondLeft + 'px', ...stickyCell, minWidth: secondWidth + 'px' },
      third: { left: thirdLeft + 'px', ...stickyCell },
    },
  };
});

export const formatValues = (value, column) => {
  return !value
    ? '-'
    : CURRENCY_COLUMNS.includes(column)
    ? formatCurrency(value)
    : FLOAT_COLUMNS.includes(column)
    ? formatDecimal(value)
    : ROUND_COLUMNS.includes(column)
    ? formatNumber(Math.round(value))
    : value;
};

export const getUserTitle = (user, replace = '-') => {
  const companyName = user?.account?.companyName ?? user?.companyName;
  const name = [companyName, getUserName(user), user?.mobile, user?.email].filter(isNotEmpty);
  return !isEmpty(name) ? name.join(' | ') : replace;
};

export const getGroupTitle = (records = [], columns = []) => {
  const record = records?.[0];
  if (!isArray(columns)) return;
  const options = {
    user: columns.includes('user.id') || columns.includes('userId'),
    date: columns.includes('userDate') || columns.includes('createdAt'),
    country: columns.includes('countryNm'),
    seller: columns.includes('seller.id') || columns.includes('sellerNm'),
    stage: columns.includes('sSts') || columns.includes('blockCode'),
    vStnId: columns.includes('vStnId'),
    memoNo: columns.includes('memoNo'),
    groupNo: columns.includes('groupNo'),
    jangadNo: columns.includes('jangadNo'),
    approveDate: columns.includes('approveDate'),
  };

  if (options.user || options.date) {
    const dateTitle = record?.dateTitle ?? '';
    const date = record?.approveDate ?? record?.userDate ?? record?.createdAt ?? '-';

    if (options.user && options.date) return `${dateTitle} ${date} | ${getUserTitle(record?.user)}`;
    if (options.date) return `${dateTitle} ${date}`;
    if (options.user) return `${getUserTitle(record?.user)}`;
  }

  if (options.approveDate) return formatDate(record?.updatedAt);

  if (options.country) return record?.countryNm ?? '-';
  if (options.memoNo) return record?.memoNo ?? '-';
  if (options.seller) return getUserTitle(record?.seller, '-');
  if (options.stage) return record?.blockCode ?? record?.sSts ?? '-';
  if (options.vStnId) return record?.vStnId ?? '-';
  if (options.jangadNo) return record?.jangadNo ?? '-';

  if (options.groupNo) {
    const paramsList = records.map((stone) => ({
      shp: stone.shpNm,
      crt: parseFloat(stone.crt).toFixed(2),
      col: stone.isFcCol ? stone.fcColNm : stone.colNm,
      clr: stone.clrNm,
      cut: stone.cutNm,
      pol: stone.polNm,
      sym: stone.symNm,
    }));
    const fields = ['shp', 'crt', 'col', 'clr', 'cut', 'pol', 'sym'];
    const title = fields
      .map((field) => {
        const subGroup = groupBy(paramsList, field);
        return Object.keys(subGroup)?.length === 1 ? paramsList?.[0]?.[field] : undefined;
      })
      .filter(isNotEmpty)
      .join(' ');

    return title;
  }

  return '-';
};

export const memoizedGetGroupTitle = memoize(getGroupTitle, (...args) => JSON.stringify(args));

export const filterGridColumns = (columns = [], options = {}) => {
  columns =
    options?.dateList && options?.customerList
      ? columns.filter((el) => el.id !== 'createdAt' && el.id !== 'user')
      : options?.dateList
      ? columns.filter((el) => el.id !== 'createdAt')
      : options?.customerList
      ? columns.filter((el) => el.id !== 'user')
      : columns;

  if (options?.inClient) columns = columns.filter((el) => el.id !== 'user');

  return columns;
};

export const updateRowClassList = (accessor, rowId, select = false) =>
  new Promise((resolve) => {
    catchError(
      () => {
        const selector = !isEmpty(accessor) ? `[table-row${rowId ? `="${accessor}${rowId}"` : ''}]` : undefined;

        const tableRows = document.querySelectorAll(selector);

        select
          ? forEach(tableRows, (row) => row.classList.add('selectTr'))
          : forEach(tableRows, (row) => row.classList.remove('selectTr'));

        resolve(true);
      },
      (error) => {
        logWarn({ updateRowClassListError: error });
        resolve(false);
      },
    );
  });

export const viewStoneCertFile = async (stone) => {
  const link = await StoneService.getCertVerifyLink(stone);
  return window.open(link, '_blank');
};

export const cellFactory = (column, options) => {
  options = { shortDate: false, ...options };

  const Bargains = React.memo((props) => {
    const { row, field } = props;

    return (
      <div className="d-flex">
        {row.original.bargains.length === 0 && '-'}
        {row.original.bargains.slice(0, 3).map((bargain, index) => (
          <React.Fragment key={index}>
            <span className={`offerPrice ${bargain.type}`}>
              <span>{bargain[field]}</span>
            </span>
            {row.original.bargains?.length > 1 && index < 2 && index !== row.original.bargains?.length - 1 ? ' | ' : ''}
          </React.Fragment>
        ))}
        {row.original.bargains.length > 3 && (
          <Popover
            content={
              <React.Fragment>
                {row.original.bargains.map((bargain, index) => (
                  <span key={index} className={`offerPrice ${bargain.type}`}>
                    <span>{bargain[field]}</span>
                  </span>
                ))}
              </React.Fragment>
            }
          >
            <span className="moreValue">
              <img src={moreBlackSvg} alt="" />
            </span>
          </Popover>
        )}
      </div>
    );
  });
  Bargains.displayName = 'Bargains';

  const getCell = () => {
    if (CURRENCY_COLUMNS.includes(column.field)) {
      const fractionLength = (() => {
        if (FLOAT_COLUMNS.includes(column.field)) return FRACTION_LENGTH;
        if (ROUND_COLUMNS.includes(column.field)) return 0;
      })();

      return ({ cell }) => <div className="numberValues">{formatCurrency(cell.value, { fractionLength }) ?? '-'}</div>;
    }

    if (DISCOUNT_COLUMNS.includes(column.field)) {
      return ({ cell }) => (isNumber(Number(cell.value)) ? `${formatDecimal(cell.value)}%` : '-');
    }

    if (FLOAT_COLUMNS.includes(column.field)) {
      return ({ cell }) => <div className="numberValues">{formatDecimal(cell.value) ?? '-'}</div>;
    }

    if (ROUND_COLUMNS.includes(column.field)) {
      return ({ cell }) => <div className="numberValues">{formatNumber(Math.round(cell.value)) ?? '-'}</div>;
    }

    switch (column?.field) {
      case 'locNm': {
        const LOCATION = { india: 'IND', beligium: 'BEL', newyork: 'NY', hongkong: 'HK', dubai: 'DXB' };
        return ({ cell }) => LOCATION?.[cell.value?.trim?.()?.toLowerCase?.()] ?? cell.value ?? '-';
      }

      case 'createdAt': {
        return ({ cell }) => (options.shortDate ? formatDate(cell.value) : formatDateTime(cell.value) ?? '-');
      }

      case 'user': {
        return ({ cell, row }) => <ClientName user={cell.value} data={row.original} />;
      }

      case 'back': {
        return ({ cell }) => (cell.value ? `${formatDecimal(cell.value)}%` : '-');
      }

      case 'baseBack': {
        return ({ cell }) => (cell.value ? `${formatDecimal(cell.value)}%` : '-');
      }

      case 'dna': {
        column.link = true;
        return ({ row }) => (
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={`${FRONT_URL}dna/${
              (row.original?.memoNo ? row?.original.diamondId ?? row.original.id : row.original.id) ??
              row.original.vStnId
            }`}
          >
            DNA
          </a>
        );
      }

      case 'param': {
        return ({ row }) => <div>{row.original.param ?? '-'}</div>;
      }

      case 'RlsDate': {
        return ({ row }) => formatDateTime(row.original.validTillDate) ?? '-';
      }

      default: {
        return ({ row, cell }) => {
          const value = React.useMemo(() => {
            const description = MasterService.getList(column?.key)?.find?.(
              (master) => `${master.code}` === `${cell.value}`,
            )?.description;

            if (description) {
              return (
                <Tooltip key={cell.row.id + cell.column.id} title={description}>
                  {cell.value}
                </Tooltip>
              );
            }

            if (row.original.bargains) {
              if (column.field === 'newPricePerCarat') return <Bargains cell={cell} row={row} field="ctPr" />;
              if (column.field === 'newDiscount' && row.original.isFcCol) return '0.00';
              if (column.field === 'newDiscount') return <Bargains cell={cell} row={row} field="offer" />;

              if (column.field === 'newAmount') {
                return (
                  <span className={`offerPrice current`}>
                    <span>{row.original?.bargains?.[0]?.amt}</span>
                  </span>
                );
              }

              if (column.field === 'bargains') {
                return (
                  <div className="d-flex">
                    {row.original.bargains.length === 0 && '-'}
                    {row.original.isFcCol
                      ? '0.00'
                      : row.original.bargains.slice(0, 3).map((x, index) => (
                          <React.Fragment key={index}>
                            <span className={`offerPrice ${x.type}`}>
                              <Tooltip title={x.time}>
                                <span>{x.offer}</span>
                              </Tooltip>
                            </span>
                            {row.original.bargains?.length > 1 &&
                            index < 2 &&
                            index !== row.original.bargains?.length - 1
                              ? ' | '
                              : ''}
                          </React.Fragment>
                        ))}
                    {row.original.bargains.length > 3 && (
                      <Popover
                        content={
                          <React.Fragment>
                            {row.original.bargains.map((x, index) => (
                              <span className={`offerPrice ${x.type}`} key={index}>
                                <Tooltip title={x.time}>
                                  <span>{x.offer}</span>
                                </Tooltip>
                              </span>
                            ))}
                          </React.Fragment>
                        }
                      >
                        <span className="moreValue">
                          <img src={moreBlackSvg} alt="" />
                        </span>
                      </Popover>
                    )}
                  </div>
                );
              }
            }

            if (row.original.isFcCol && column.field === 'colNm') {
              return <React.Fragment>{returnIfNotEmpty(row.original?.fcColDesc, '-')}</React.Fragment>;
            }

            if (column.isFlag) {
              if (cell.value) return isString(column.sLabel) ? column.sLabel : 'Y';
              return isString(column.nLabel) ? column.nLabel : '-';
            }

            if (cell.value === true) return 'Y';
            if (!cell.value) return '-';

            if (column.field === 'vStnId') {
              if (!isEmpty(row.original.layoutNo) && `${row.original.layoutNo}` !== '0') {
                return (
                  <div style={{ position: 'relative' }}>
                    {cell.value}{' '}
                    <Tooltip title="Layout">
                      <img style={{ width: '20px', height: '20px', marginLeft: '5px' }} src={layoutSvg} alt="" />
                    </Tooltip>
                  </div>
                );
              }
            }

            return cell.value ?? '-';
          }, [cell, row]);

          return (
            <div
              className={classNames([
                FLOAT_COLUMNS.includes(column.field) && 'numberValue',
                ROUND_COLUMNS.includes(column.field) && 'numberValue',
              ])}
            >
              {value}
            </div>
          );
        };
      }
    }
  };

  const Cell = React.memo(getCell());
  Cell.displayName = options.displayName ?? 'CellContent';

  return Cell;
};

export const memoizedCellFactory = memoize(cellFactory, (...args) => JSON.stringify(args));
