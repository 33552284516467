import { get, groupBy, lowerCase, orderBy } from 'lodash';

import { TOTAL_COLUMNS } from 'components/Inventory/table-config';
import { getGroupTitle } from 'components/Inventory/table-utils';

import { isEmpty, isFunction } from 'util/utils';

export const GROUP_KEY = '_groupKey_';

/**
 * Add group data for table
 * @param {Array}  array    Records
 * @param {Array}  columns  Array of columns to group ['userDate', 'userId']
 * @param {String} keyToSet group separator key
 */
export function groupArrayByColumns(array = [], options) {
  options = { columns: ['createdAt'], keyToSet: 'userGroupHold', ...options };
  const { columns, keyToSet, order } = options;

  if (isEmpty(columns)) return array;

  const groups = groupBy(
    array?.map((record) => ({
      ...record,
      [GROUP_KEY]: isFunction(columns) ? columns(record) : columns.map((column) => get(record, column)).join('_'),
    })),
    GROUP_KEY,
  );

  const output = Object.values(groups)
    .map((records) => {
      if (!isEmpty(order)) {
        const orderMap = order.reduce((p, c) => ({ ...p, ...c }), {});
        records = orderBy(records, Object.keys(orderMap), Object.values(orderMap).map(lowerCase));
      }

      const length = records.length;
      const lastIndex = length - 1;
      const firstRecord = records[0];
      const lastRecord = records[lastIndex];

      firstRecord.isHeader = true;
      lastRecord.isFooter = true;

      firstRecord[keyToSet] = true;
      lastRecord[keyToSet] = true;

      const groupTitle = getGroupTitle(records, columns) ?? firstRecord?.[GROUP_KEY];
      const totalStones = length;
      const data = {};

      TOTAL_COLUMNS.forEach((columnId) => {
        data[columnId] = data[columnId] ?? [];
        records.forEach((record) => void (record[columnId] && data[columnId].push(record[columnId])));
      });

      records.forEach((record) => {
        record._groupTitle_ = groupTitle;
        record.totalDiamonds = totalStones;
        record.groupData = data;
      });

      return records;
    })
    .flat();

  return output;
}

export function groupingArrayBoth(list, keyToSet) {
  return groupArrayByColumns(list, { columns: ['userDate', 'userId'], keyToSet });
}

export const groupingArray = (list, keyToSet, subArray, keyForGroup) => {
  return groupArrayByColumns(list, { columns: [keyForGroup], keyToSet });
};
