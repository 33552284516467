import { cloneDeep } from 'util/lodash';

import { catchError } from './catchError';
import { isArray } from './isArray';
import { isEmpty } from './isEmpty';
import { isObject } from './isObject';

/**
 * Prune refs with empty values
 * @author Sagar Panchal <panchal.sagar@outlook.com>
 * @param {*} input value
 * @param {*} options options
 * @returns {boolean}
 */
export function pruneEmpty(input, options) {
  const prune = (current) => {
    if (isArray(current)) {
      current = [...current];

      for (let index = current.length - 1; index > -1; index--) {
        current[index] = prune(current[index]);
        if (isEmpty(current[index], options)) current.splice(index, 1);
      }
    } else if (isObject(current)) {
      current = { ...current };

      for (const prop in current) {
        current[prop] = prune(current[prop]);
        if (isEmpty(current[prop], options)) delete current[prop];
      }
    } else {
      catchError(() => {
        current = cloneDeep(current);
      });
    }

    if (!isEmpty(current, options)) return current;
  };

  return prune(input);
}
