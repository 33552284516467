import { LAB_LINKS } from 'components/Inventory/table-config';

import { clone, formatDecimal, isEmpty, isString, isArray, isNotEmpty, parseDecimal, isObject } from 'util/utils';

import { getFileUrl, BASE_URL } from 'constants/Common';
import { API_ROUTES } from 'constants/CommonApi';

import { MediaService } from './MediaService';
import { callApiAsync } from './util';

export class StoneService {
  static calculateDiscount(stone, input, field) {
    stone = { ...stone };
    input = { ...input };

    stone.amt = parseDecimal(stone.amt) ?? 0;
    stone.back = parseDecimal(stone.back) ?? 0;
    stone.crt = parseDecimal(stone.crt, 3) ?? 0;
    stone.ctPr = parseDecimal(stone.ctPr) ?? 0;
    stone.rap = parseDecimal(stone.rap) ?? 0;

    // when rap is not available, use ctPr and back to get rap
    if (!stone.rap) stone.rap = parseDecimal((100 * stone.oldCtPr ?? stone.ctPr) / (100 + stone.back));

    const output = {
      ctPr: Math.abs(parseDecimal(input.ctPr ?? stone.ctPr)),
      back: parseDecimal(input.back ?? stone.back),
      amt: parseDecimal(input.amt ?? stone.amt),
    };

    switch (field) {
      case 'back': {
        output.ctPr = parseDecimal(stone.rap * (1 + output.back / 100));
        break;
      }

      case 'ctPr': {
        output.back = parseDecimal(100 * (output.ctPr / stone.rap - 1));
        break;
      }

      default:
        break;
    }

    output.amt = parseDecimal(output.ctPr * stone.crt);

    // const isMoreThanMax = isObject(stone.maxAllowedValues) ? output[field] > stone.maxAllowedValues?.[field] : false;
    // const isLessThanMin = isObject(stone.minAllowedValues) ? output[field] < stone.minAllowedValues?.[field] : false;

    // if (isMoreThanMax || isLessThanMin) {
    //   output.back = stone.back;
    //   output.ctPr = stone.ctPr;
    //   output.amt = stone.amt;
    // }

    // if (isMoreThanMax) Notify.error(`Cannot offer for more than ${Math.abs(stone.maxRapDiff)}% rap difference`)();
    // if (isLessThanMin) Notify.error(`Cannot offer for less than ${Math.abs(stone.minRapDiff)}% rap difference`)();

    return output;
  }

  static formatRecordForCalculation(record, options = {}) {
    if (!isObject(record)) return record;
    record = { _original: record?._original ?? clone(record), ...clone(record) };
    const { range } = isObject(options) ? { ...options } : {};

    record.minRapDiff = parseDecimal(record.wSts === 'D' ? range?.bestBuyMinBuynowDiscount : range?.minBuynowDiscount);
    record.maxRapDiff = parseDecimal(record.wSts === 'D' ? range?.bestBuyMaxBuynowDiscount : range?.maxBuynowDiscount);
    const minBack = parseDecimal(record.back) + record.minRapDiff;
    const maxBack = parseDecimal(record.back) + record.maxRapDiff;
    record.minAllowedValues = StoneService.calculateDiscount(record, { back: minBack }, 'back');
    record.maxAllowedValues = StoneService.calculateDiscount(record, { back: maxBack }, 'back');
    return record;
  }

  static formatListForCalculation(list, options = {}) {
    return list.map((record) => StoneService.formatRecordForCalculation(record, options));
  }

  static CHECK_FILE_HEADERS = false;
  // prettier-ignore
  static INCLUSION_FIELDS = ['blkTblNm', 'opTblNm', 'blkSdNm', 'opCrwnNm', 'wTblNm', 'opPavNm', 'wSdNm', 'opGrd', 'kToSStr', 'lbCmt'];

  static formatStoneRecord(record) {
    const output = { ...record };
    output._original = output._original ?? clone(record);

    if (!isEmpty(output.girdleStr) && !isEmpty(output.grdlPer))
      output.girdleStr = `${output.girdleStr} (${output.grdlPer}%)`;

    // if (output.shpNm === 'ROUND') output.ratio = undefined;

    if (!isEmpty(output.clrNm) && ['FL', 'IF'].includes(output.clrNm)) {
      const inclusionFieldsExclude = ['kToSStr'];

      StoneService.INCLUSION_FIELDS.forEach((field) => {
        if (!inclusionFieldsExclude.includes(field)) output[field] = 'NONE';
      });
    }

    return output;
  }

  static formatStoneList(list) {
    return list.map(StoneService.formatStoneRecord);
  }

  static getStonePropSummary(stone) {
    return [
      formatDecimal(stone.crt),
      stone.isFcCol ? stone.fcColNm : stone.colNm,
      stone.clrNm,
      stone.cutNm,
      stone.polNm,
      stone.symNm,
      stone.fluNm,
    ].filter(isNotEmpty);
  }

  static getStoneGroupTitle(list) {
    const stonePropList = list.map((stone) => ({
      shpNm: stone.shpNm,
      crt: parseDecimal(stone.crt, 2),
      colNm: stone.isFcCol ? stone.fcColNm : stone.colNm,
      clrNm: stone.clrNm,
      cutNm: stone.cutNm,
      polNm: stone.polNm,
      symNm: stone.symNm,
    }));

    const firstStoneProp = stonePropList[0];
    const display = Object.keys(firstStoneProp)
      .map((key) => {
        const isPropSame = stonePropList.every((stone_) => stone_[key] === firstStoneProp[key]);
        return isPropSame ? firstStoneProp[key] : undefined;
      })
      .filter(isNotEmpty);
    return !isEmpty(display) ? display.join(' ') : '-';
  }

  static async getCertFileLink(stone) {
    const [error] = await (!stone.certFile ? MediaService.getHeaders(getFileUrl('PDF_CERT', stone?.vStnId)) : [false]);
    return !error
      ? getFileUrl('PDF_CERT', stone?.vStnId)
      : LAB_LINKS[stone?.lbNm?.toUpperCase?.()]?.replace?.('***', stone?.rptNo);
  }

  static getCertVerifyLink(stone) {
    return LAB_LINKS[stone?.lbNm?.toUpperCase?.()]?.replace?.(/(?:[*]{3}){1}/gm, stone?.rptNo);
  }

  static async checkFileAvailability(stone) {
    const list = [
      ['DEFIMG', getFileUrl('JPG_ALT', stone?.vStnId), true, stone?.img],
      ['NATIMG', getFileUrl('JPG_NATURAL', stone?.vStnId), true, stone?.isNtrlImg],
      ['360VID', getFileUrl('MP4_360', stone?.vStnId), true, stone?.videoFile],
      ['NATVID', getFileUrl('MP4_NATURAL', stone?.vStnId), true, stone?.isNtrlVideo],
      ['LBCERT', getFileUrl('PDF_CERT', stone?.vStnId), true, stone?.certFile],
      ['ASTIMG', getFileUrl('JPG_ASSET', stone?.vStnId), true, stone?.hAFile],
      ['IDLIMG', getFileUrl('JPG_IDEAL', stone?.vStnId), true, stone?.hAFile],
      ['HRTIMG', getFileUrl('JPG_HEARTS', stone?.vStnId), stone?.shpNm === 'ROUND', stone?.hAFile],
      ['ABLIMG', getFileUrl('JPG_ARROWS', stone?.vStnId), stone?.shpNm === 'ROUND', stone?.hAFile],
      ['FLSIMG', getFileUrl('JPG_FLU', stone?.vStnId), true, stone?.flsImage],
      ['PLTIMG', getFileUrl('JPG_PLT', stone?.vStnId), true, stone?.isPltImg],
      ['MSRIMG', getFileUrl('JPG_MSR', stone?.vStnId), true, stone?.isMsr],
    ];

    const promiseList = list.map(async ([key, url, show, available]) => {
      if (show && StoneService.CHECK_FILE_HEADERS) {
        const [error] = await MediaService.getHeaders(url);
        if (!error) return key;
      } else {
        if (show && available) return key;
      }
    });

    const resolvedList = await Promise.all(promiseList);

    return resolvedList.filter(isNotEmpty);
  }

  static async getExcelLink(stoneList, payload = {}) {
    const [err, res] = await callApiAsync({
      ...API_ROUTES.Inventory.exportExcel,
      request: { isExcel: true, isClient: true, id: stoneList.map((stone) => stone?.id), ...payload },
    });
    if (err) return;

    const remotePath = isString(res?.data) ? res?.data : isArray(res?.data) ? res?.data?.[0] : res?.data?.data;
    if (!isString(remotePath)) return;

    const output = `${BASE_URL}data/${remotePath}`;
    return output;
  }
}

window.__StoneService = StoneService;
