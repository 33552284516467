import React from 'react';

import { PermissionService } from 'services/PermissionService';

import { isEmpty } from 'util/utils';

import { useCurrentModule } from './useCurrentModule';

/**
 * usePermissions react hook
 * @author Sagar Panchal <panchal.sagar@outlook.com>
 */

export function usePermissions(path) {
  const _module = useCurrentModule();
  const [module, _setModule] = React.useState(() => _module ?? PermissionService.defaultModule);

  const setModule = React.useCallback((value) => {
    _setModule(value ?? PermissionService.defaultModule);
  }, []);

  React.useEffect(() => {
    if (isEmpty(path)) {
      setModule(_module);
    } else {
      setModule(PermissionService.getPermission(path));
      return PermissionService.events.updateTree.listen(() => {
        setModule(PermissionService.getPermission(path));
      });
    }
  }, [_module, path, setModule]);

  React.useDebugValue(module);
  return [module, module.permissions];
}
