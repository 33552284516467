import React from 'react';
import { useDispatch } from 'react-redux';

import { Checkbox } from 'antd';

import Modal from 'containers/CommonModal';

import CircularProgress from 'components/CircularProgress';

import { downloadFile, downloadZip } from 'services/DiamondApi';
import { getLoginUser } from 'services/commonFunc';

import { usePermissions } from 'util/hooks';
import { isEmpty } from 'util/utils';
import { groupByUserAccount } from 'util/utils/groupByUserAccount';

import { FILE_URLS, USERS_TYPE } from 'constants/Common';
import OpenNotification from 'constants/OpenNotifications';

import './share-action.less';
import { ShareRadio } from './ShareAction';

function DownloadOption({ checked, id, onChange, title, uiid, ...props }) {
  const handleChange = React.useCallback(() => {
    onChange(!id);
  }, [onChange, id]);

  return (
    <li id={uiid} onClick={handleChange} {...props}>
      <div className="dropdownBottomItem">
        <div className="dropdownIconCheckBox">
          <Checkbox onChange={onChange} checked={checked}></Checkbox>
        </div>
        <span className="shareOptionLabel">{title}</span>
      </div>
    </li>
  );
}

function DownloadAction({ onClose, visible, downloadExcel, ...props }) {
  const loginUser = getLoginUser();
  const [module] = usePermissions();

  const dispatch = useDispatch();

  const [isExcel, setIsExcel] = React.useState(false);
  const [isCert, setIsCert] = React.useState(false);

  const [isImg, setIsImg] = React.useState(false);
  const [isPlt, setIsPlt] = React.useState(false);
  const [isNatural, setNaturalImage] = React.useState(false); //natrual
  const [isFls, setFlsImage] = React.useState(false); //image
  const [isProp, setIsProp] = React.useState(false);

  const [originalStoneId, setOriginalStoneId] = React.useState(false);
  const [excelForBoth, setExcelForBoth] = React.useState(false);
  const [withOutOriginalId, setWithOutOriginalId] = React.useState(false);

  const [isHa, setIsHa] = React.useState(false);
  const [Ideal, setIdeal] = React.useState(false);
  const [isVideo, setIsVideo] = React.useState(false);
  const [isVideo2, setIsVideo2] = React.useState(false);
  const [images] = React.useState(false);
  const [videos] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  const isAllChecked = React.useMemo(
    () => [isImg, isVideo, isCert, originalStoneId].findIndex((item) => item === false) === -1,
    [isImg, isVideo, isCert, originalStoneId],
  );

  const toggleIsAllChecked = React.useCallback((e) => {
    const checked = e.target.checked;
    setIsImg(checked);
    setIsVideo(checked);
    setIsCert(checked);
    setExcelForBoth(checked);
    setIsExcel(checked);
  }, []);

  const areAllChecked = [isHa, isCert, isExcel, isImg, isPlt, isProp, isNatural, isFls, isVideo, isVideo2].filter(
    Boolean,
  ).length;

  const anyOptionsChecked =
    isHa || isCert || isExcel || isImg || isPlt || isVideo || Ideal || isVideo2 || isNatural || isFls || isProp;

  const downloadStoneDetails = React.useCallback(async () => {
    if (anyOptionsChecked) {
      const wSts =
        props.currentType === 'NewArrivalDiamondList' ? 'B' : props.currentType === 'upcomingList' ? 'U' : undefined;
      const isUpcoming = props.currentType === 'upcomingList' || undefined;
      const userAccount = groupByUserAccount(props?.checked, props?.client, 'vStnId');
      const id = props?.checked?.map?.((stone) => stone?.id) || props?.id;
      const blockId = props?.checked?.map?.((stone) => stone?.blockId);
      const vStnId = props?.checked?.map?.((stone) => stone?.vStnId) ?? [];

      const filter =
        module?.parentPath === 'TRANSACTION.MEMO'
          ? { vStnId: vStnId, id: blockId }
          : module?.parentPath === 'TRANSACTION.CONFIRM_STONE'
          ? { id: id, wSts }
          : module?.original?.mainModule === 'TRANSACTION' || module?.parentName === 'TRANSACTION'
          ? { id: blockId, wSts, vStnId: vStnId }
          : { vStnId: vStnId, wSts };

      if (props.checked.length >= 1) {
        if (isExcel && areAllChecked === 1) {
          setLoader(true);
          downloadExcel(!excelForBoth ? { withOriginalStoneId: originalStoneId } : undefined);
          setLoader(false);
          onClose();
          dispatch({ type: 'RESET_SELECTED_ROWS' });
        } else if (areAllChecked >= 2 || props.checked.length > 1 || isHa) {
          const obj = {
            filter,
            ...(props.currentType === 'INVENTORY.SINGLE_STONE.MATCH_PAIR'
              ? { isPairExcel: true }
              : props.currentType === 'layoutList'
              ? { isLayoutExcel: true, isPairExcel: true }
              : {}),
            isUpcoming,
            userAccount,
            isExcel: isExcel,
            img: isImg,
            certFile: isCert,
            videoFile: isVideo,
            hAFile: isHa,
            ...(isHa && { arrBlack: isHa, heartImage: isHa }),
            ...(module?.parentPath === 'TRANSACTION.CONFIRM_STONE'
              ? { excelType: 2 }
              : isEmpty(props.excelType)
              ? {}
              : { excelType: props.excelType }),
            // module?.parentPath === 'TRANSACTION.MEMO' || module?.parentPath === 'TRANSACTION.HOLD'
            // ? { excelType: 3 }
            // : props.module?.parentPath === 'TRANSACTION.ENQUIRY' ||
            //   module?.path === 'TRANSACTION.CART' ||
            //   module?.path === 'TRANSACTION.WATCHLIST'
            // ? { excelType: 1 }
            // : module?.path === 'TRANSACTION.NOTES'
            // ? { excelType: 5 }
            // : module?.parentPath === 'TRANSACTION.CONFIRM_STONE'
            // ? { excelType: 2 }
            // : {}
            pltFile: isPlt,
            propImage: isProp,
            flsImage: isFls,
            naturalImage: isNatural,
            mp4Video: isVideo,
            zip: true,
          };

          setLoader(true);
          await downloadZip(obj);

          setLoader(false);
          onClose();
          dispatch({ type: 'RESET_SELECTED_ROWS' });
        } else if (isCert) {
          const obj = {
            path: props?.checked[0]?.files?.certFile,
            fileName: `certFile_${props.checked[0].vStnId}`,
            ext: `.${props?.checked[0]?.files?.certFile.split('.').pop()}`,
          };
          setLoader(true);
          await downloadFile(obj);
          setLoader(false);
          onClose();
          dispatch({ type: 'RESET_SELECTED_ROWS' });
        } else if (isVideo) {
          const obj = {
            path: props?.checked[0]?.files?.videoFile,
            fileName: `NaturalVideoFile_${props.checked[0].vStnId}`,
            ext: '.mp4',
          };
          setLoader(true);
          await downloadFile(obj);
          setLoader(false);
          onClose();
          dispatch({ type: 'RESET_SELECTED_ROWS' });
        } else if (isVideo2) {
          const obj = {
            path: FILE_URLS.hdVideoFileMp4?.replace('***', props.checked[0].vStnId),
            fileName: `360VideoFile_${props.checked[0].vStnId}`,
            ext: '.mp4',
          };
          setLoader(true);
          await downloadFile(obj);
          setLoader(false);
          onClose();
          dispatch({ type: 'RESET_SELECTED_ROWS' });
        } else if (isImg) {
          ////Image
          const obj = {
            path: props?.checked[0]?.files?.img,
            fileName: `Ideal_Image_${props.checked[0].vStnId}`,
            ext: '.jpg',
          };
          setLoader(true);
          await downloadFile(obj);
          setLoader(false);
          onClose();
          dispatch({ type: 'RESET_SELECTED_ROWS' });
        } else if (isPlt) {
          const obj = {
            path: FILE_URLS.pltFile?.replace('***', props.checked[0].vStnId),
            fileName: `PlottingImage_${props.checked[0].vStnId}`,
            ext: '.png',
          };
          setLoader(true);
          await downloadFile(obj);
          setLoader(false);
          onClose();
          dispatch({ type: 'RESET_SELECTED_ROWS' });
        } else if (isNatural) {
          const obj = {
            path: FILE_URLS.naturalImage?.replace('***', props.checked[0].vStnId),
            fileName: `Natural_${props.checked[0].vStnId}`,
            ext: '.jpg',
          };
          setLoader(true);
          await downloadFile(obj);
          setLoader(false);
          onClose();
          dispatch({ type: 'RESET_SELECTED_ROWS' });
        } else if (isProp) {
          const obj = {
            path: FILE_URLS.proportionImage?.replace('***', props.checked[0].vStnId),
            fileName: `ProportionImage_${props.checked[0].vStnId}`,
            ext: '.jpg',
          };
          setLoader(true);
          await downloadFile(obj);
          setLoader(false);
          onClose();
          dispatch({ type: 'RESET_SELECTED_ROWS' });
        } else if (isFls) {
          const obj = {
            path: FILE_URLS.fluorescenceImage?.replace('***', props.checked[0].vStnId),
            fileName: `FluorescenceImage_${props.checked[0].vStnId}`,
            ext: '.jpg',
          };
          setLoader(true);
          await downloadFile(obj);
          setLoader(false);
          onClose();
          dispatch({ type: 'RESET_SELECTED_ROWS' });
        }
      }
    } else {
      OpenNotification({
        type: 'error',
        title: 'Please Select atleast 1 Option to Download.',
      });
    }
  }, [
    anyOptionsChecked,
    props.currentType,
    props.checked,
    isExcel,
    areAllChecked,
    isHa,
    isCert,
    isVideo,
    isVideo2,
    isImg,
    isPlt,
    isNatural,
    isProp,
    isFls,
    downloadExcel,
    onClose,
    dispatch,
  ]);

  const getOriginalStoneId = (stoneId) => {
    setExcelForBoth(stoneId == 'both');
    setOriginalStoneId(stoneId == 'with');
    setWithOutOriginalId(stoneId == 'withOut');
    setIsExcel(true);
  };

  React.useEffect(() => {
    setIsImg(isImg);
    setIsHa(images);
    setNaturalImage(images);
    setFlsImage(images);
    setIsPlt(images);
    setIdeal(images);
    setIsProp(images);
    setOriginalStoneId(images);
    setWithOutOriginalId(images);
  }, [images]);

  React.useEffect(() => {
    setIsVideo(isVideo);
    setIsVideo2(videos);
  }, [videos]);

  // React.useEffect(() => {
  //   setIsImg(allChecked);
  //   setIsVideo(allChecked);
  //   setIsCert(allChecked);
  //   setIsExcel(allChecked);
  // }, [allChecked]);

  return (
    <Modal title="Download" handleCancel={onClose} visible={visible ?? true} className="md-size">
      <div>
        <Checkbox checked={isAllChecked} onChange={toggleIsAllChecked}>
          <span className="checkBox-span">Select All</span>
        </Checkbox>
        <div className="searchPopupCommon">
          <div className="shareOptionSet">
            <div className="shareOptionSetItem shareOptionImage">
              <ul>
                {/* <div className="dropdownMainTitle">
                  <div className="dropdownIconCheckBox">
                    <Checkbox checked={images} onChange={() => setImages(!images)}></Checkbox>
                  </div>
                  <span>Images : </span>
                </div> */}
                <div className="dropdownRightCheck">
                  <DownloadOption title="Image" checked={isImg} onChange={() => setIsImg(!isImg)} />
                  {/* <DownloadOption
                    title="Natural Image"
                    checked={isNatural}
                    onChange={() => setNaturalImage(!isNatural)}
                  />
                  <DownloadOption title="Heart & Arrow Image" checked={isHa} onChange={() => setIsHa(!isHa)} />
                  <DownloadOption title="Fluorescence Image" checked={isFls} onChange={() => setFlsImage(!isFls)} />
                  <DownloadOption title="Plot Image" checked={isPlt} onChange={() => setIsPlt(!isPlt)} />
                  <DownloadOption title="Proportion Image" checked={isProp} onChange={() => setIsProp(!isProp)} /> */}
                </div>
                <div className="dropdownRightCheck">
                  {/* <DownloadOption title="360 Video" checked={isVideo2} onChange={() => setIsVideo2(!isVideo2)} /> */}
                  <DownloadOption title="Natural Video" checked={isVideo} onChange={() => setIsVideo(!isVideo)} />
                </div>
                <div className="dropdownRightCheck">
                  <DownloadOption title="Certificate" checked={isCert} onChange={() => setIsCert(!isCert)} />
                </div>
                {![USERS_TYPE.ADMIN, USERS_TYPE.SUPER_ADMIN].includes(loginUser?.type) && (
                  <div>
                    <DownloadOption checked={isExcel} onChange={() => setIsExcel(!isExcel)} title="Excel" />
                  </div>
                )}
              </ul>

              {[USERS_TYPE.ADMIN, USERS_TYPE.SUPER_ADMIN].includes(loginUser?.type) && (
                <>
                  <ul>
                    <div className="dropdownDownloadMainTitle">
                      <span>Excel : </span>
                    </div>
                    <div className="dropdownRightCheck">
                      <ShareRadio checked={excelForBoth} onChange={() => getOriginalStoneId('both')} title="Both" />

                      <ShareRadio
                        title="Original Stock No."
                        checked={originalStoneId}
                        onClick={() => getOriginalStoneId('with')}
                      />
                      <ShareRadio
                        title="Modified Stock No."
                        checked={withOutOriginalId}
                        onClick={() => getOriginalStoneId('withOut')}
                      />
                    </div>
                  </ul>
                </>
              )}

              {/* <ul>
                <div className="dropdownMainTitle">
                  <div className="dropdownIconCheckBox">
                    <Checkbox checked={videos} onChange={() => setVideos(!videos)}></Checkbox>
                  </div>
                  <span>Video : </span>
                </div>
                <div className="dropdownRightCheck">
                  <DownloadOption title="360 Video" checked={isVideo2} onChange={() => setIsVideo2(!isVideo2)} />
                  <DownloadOption title="Natural Video" checked={isVideo} onChange={() => setIsVideo(!isVideo)} />
                </div>
              </ul> */}
              {/* <ul className="d-flex">
                <div className="width-50 d-flex align-items-center">
                  <div className="dropdownMainTitle">
                    <span>Certificate : </span>
                  </div>
                  <div className="dropdownRightCheck">
                    <DownloadOption title="Certificate" checked={isCert} onChange={() => setIsCert(!isCert)} />
                  </div>
                </div>
                <div className="width-50 d-flex align-items-center excelShare">
                  <div className="dropdownMainTitle">
                    <span>Excel : </span>
                  </div>
                  <div className="dropdownRightCheck">
                    <div>
                      <DownloadOption checked={isExcel} onChange={() => setIsExcel(!isExcel)} title="Excel" />
                    </div>
                  </div>
                </div>
              </ul> */}
            </div>
          </div>
          <div className="commonModalButton mt-25">
            <button className="fillButton" onClick={downloadStoneDetails}>
              Download
            </button>
            {loader && <CircularProgress className="fixLoader" />}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default DownloadAction;
