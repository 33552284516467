import React from 'react';
import { Modal as AntdModal } from 'antd';
import classNames from 'util/classNames';
import './modal.less';

function Modal({ className, children, noDefaults, ...restProps }) {
  const { handleOk, handleCancel, footerShow, submitTitle, cancelTitle, ...forwardProps } = restProps;
  const defaultProps = React.useMemo(
    () =>
      !noDefaults && {
        onOk: handleOk,
        onCancel: handleCancel,
        footer: footerShow ? (
          <div className="commonModalButton">
            {restProps?.memoIn && (
              <button className="fillButton" onClick={restProps?.memoIn}>
                Memo In
              </button>
            )}
            <button className="fillButton" onClick={handleOk}>
              {submitTitle}
            </button>
            <button className="outLineButton" onClick={handleCancel}>
              {cancelTitle}
            </button>
          </div>
        ) : null,
        centered: true,
      },
    [cancelTitle, footerShow, handleCancel, handleOk, noDefaults, submitTitle],
  );

  return (
    <AntdModal className={classNames(['commonModal', className])} {...defaultProps} {...forwardProps}>
      {children}
    </AntdModal>
  );
}

export default Modal;
