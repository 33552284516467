import { Block } from 'entities/Block';
import { BlockCollection } from 'entities/BlockCollection';
import { CellNumber } from 'entities/CellNumber';
import { Order } from 'entities/Order';
import { Stone } from 'entities/Stone';
import { StoneCollection } from 'entities/StoneCollection';
import { User } from 'entities/User';

import { LoaderService } from 'services/LoaderService';
import { MasterService } from 'services/MasterService';
import { PermissionService } from 'services/PermissionService';
import { UserService } from 'services/UserService';
import { Action } from 'services/action';
import { Storage } from 'services/storage';

import * as Utils from 'util/utils';

const entities = [BlockCollection, Block, CellNumber, Order, StoneCollection, Stone, User];
const services = [Action, Storage, LoaderService, UserService, MasterService, PermissionService];
window.__DN = {};
services.forEach((service) => service.initialize());

const initialize = () => {
  if (!(Utils.isDevEnv() || window.__DN?.DEBUG)) return;
  window.__DN.Utils = Utils;

  window.__DN.entities = {};
  entities.forEach((entity) => (window.__DN.entities[entity.identifier ?? entity.name] = entity));

  window.__DN.services = {};
  services.forEach((service) => (window.__DN.services[service.identifier ?? service.name] = service));
};

window.__DN._INIT = () => initialize();

window.__DN._INIT();
