import React from 'react';

import * as Hooks from 'util/hooks';

// import noImageSvg from 'assets/svg/noimage.svg';
import { isFunction } from 'util/utils';

import noImageList from 'assets/svg/noImageList.svg';
import noImageSvgWhite from 'assets/svg/noimage-white.svg';

import './image.less';

const Image = React.memo(({ children, bgDark = false, ...props }) => {
  const [hasError, setError] = Hooks.useBoolean(false);

  React.useEffect(() => {
    setError.false();
  }, [props.src, setError]);

  return !hasError ? (
    <img
      onError={() => {
        setError.true();
        if (isFunction(props.onError)) props.onError();
      }}
      {...props}
    />
  ) : children ? (
    children
  ) : (
    <img {...props} src={bgDark ? noImageSvgWhite : noImageList} />
  );
});

Image.displayName = 'Image';

export default Image;
