import { isNotEmpty } from './isNotEmpty';

/**
 * Combine url parts
 * @param  {array} args  array or strings (partial urls)
 * @returns
 */
export function combineURLs(...args) {
  return args
    .filter(isNotEmpty)
    .join('/')
    .replace(/([^:]\/)\/+/g, '$1');
}
