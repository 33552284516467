import Cookies from 'js-cookie';

import { Storage } from 'services/storage';

import {
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  USER_DEFAULT_PASSWORD_SET,
  USERNAME_PASSWORD,
  VERIFY_OTP,
  REMOVE_USERNAME_PASSWORD,
} from 'constants/ActionTypes';

const getInitialState = () => ({
  token: Cookies.get('token') ?? null,
  initURL: '',
  authUser: Storage.get('user') ?? null,
  defaultPassword: null,
  userData: {},
  isVerified: false,
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case INIT_URL:
      return { ...state, initURL: action.payload };

    case USER_DATA:
      return { ...state, authUser: action.payload };

    case USER_TOKEN_SET:
      return { ...state, token: action.payload };

    case USER_DEFAULT_PASSWORD_SET:
      return { ...state, defaultPassword: action.payload };

    case USERNAME_PASSWORD:
      return { ...state, userData: action.payload };

    case VERIFY_OTP:
      return { ...state, isVerified: action.payload };

    case REMOVE_USERNAME_PASSWORD:
      return { ...state, userData: {} };

    case '@@RESET':
    case SIGNOUT_USER_SUCCESS:
      return { ...getInitialState };

    default:
      return state;
  }
};
