import { callApiAsync } from 'services/util';
import { API_ROUTES } from 'constants/CommonApi';

export const getOrderList = (request = {}) => callApiAsync({ ...API_ROUTES.Order.paginate, request });

export const getConfirmList = (request = {}) => callApiAsync({ ...API_ROUTES.Confirm.paginate, request });

export const statusChange = (request = {}) => callApiAsync({ ...API_ROUTES.Order.statusChange, request });

export const printDiamondPdf = (request = {}) => callApiAsync({ ...API_ROUTES.Order.orderDiamondPdf, request });
