import { LoaderService } from 'services/LoaderService';
import { handleLogout } from 'services/util';

import { axios } from 'util/axios';
import { catchError, isFunction, isString, logWarn, objectToQueryString } from 'util/utils';

import { BASE_URL } from 'constants/Common';

import { Notify } from './notify';

export const callApi = async (config, cb) => {
  config.notifyErrors = config.notifyErrors ?? true;
  config.notifyResponse = config.notifyResponse ?? false;

  if (config.showLoader) LoaderService.startLoading();

  const request = {
    baseURL: BASE_URL,
    url: config.url,
    method: config.method,
    data: config.payload ?? config.data ?? config.request,
    headers: {
      'Content-Type': 'application/json',
      devicetype: config?.devicetype,
    },
  };

  const output = { error: undefined, response: undefined, data: undefined, message: undefined };

  try {
    const response = await axios.request(request);

    output.response = response;
    output.data = response?.data;
    output.error = response?.data?.code !== 'OK';
    output.message = output?.data?.message ?? output?.response?.message;

    if (!output.error && config.notifyResponse && isString(output.message)) {
      Notify.success({ message: output.message });
    }
  } catch (error) {
    logWarn(error);
    output.response = error?.response;
    output.data = error?.response?.data;
    output.error = error || true;
    output.message = output?.data?.message ?? output?.response?.message ?? 'Something bad happend';

    if (output.error && config.notifyErrors && isString(output.message)) {
      Notify.error({ message: output.message });
    }
  }

  if (output?.data?.code === 'E_UNAUTHORIZED') handleLogout();
  if (config.showLoader) LoaderService.stopLoading();
  if (isFunction(cb)) catchError(() => cb(output.error, output.data, output));

  return [output.error, output.data, output];
};

export const withURLParams = (input, params = []) => ({
  ...input,
  url: `${input.url}${!input.url.endsWith('/') ? '/' : ''}${params.join('/')}`,
});

export const withQueryParams = (input, params = {}) => ({
  ...input,
  url: `${input.url}${objectToQueryString(params)}`,
});
