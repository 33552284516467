import { MediaService } from 'services/MediaService';

import { callApi } from 'util/call-api';
import { clone } from 'util/utils';

import { BASE_DOWNLOAD_URL, SETTING_TYPE } from 'constants/Common';
import { API_ROUTES } from 'constants/CommonApi';

export class Order {
  static identifier = 'Order';

  props = {};

  constructor(details = {}) {
    this.props = clone(Order.isOrder(details) ? details.props : details);
  }

  static isOrder(input) {
    return input?.constructor === Order;
  }

  get id() {
    return this.props.id;
  }

  async getInvoiceLink(payload = {}) {
    payload = {
      memoNo: [this.props.memoNo],
      vStnIds: this.props.memoDetails.map((stone) => stone.vStnId),
      type: SETTING_TYPE.INVOICE_DETAILS,
      orderPdf: true,
      ...payload,
    };
    const [err, res] = await callApi({ ...API_ROUTES.ORDER.GET_INVOICE, payload, showLoader: true });
    const link = err ? undefined : `${BASE_DOWNLOAD_URL}${res?.data}`;
    return [err, link];
  }

  async downloadInvoice(payload = {}) {
    const [err, link] = await this.getInvoiceLink(payload);
    return err ? [err, undefined] : MediaService.downloadFile(link, { name: `ORDER_${this.props.memoNo}.pdf` });
  }

  async viewInvoice(payload = {}) {
    const [err, link] = await this.getInvoiceLink(payload);
    return err ? [err, undefined] : MediaService.viewFile(link, false);
  }
}
