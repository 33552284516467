import React from 'react';
import './textarea.less';

function TextArea({ label, required, error, row, ...props }) {
  return (
    <div className={'from-group textAreaWrapper ' + (error && 'error-message')}>
      <label className="commonLabel">
        {label} {required && <span className="required-ast">*</span>}
      </label>
      <textarea className="commonTextArea" row={row || 2} {...props} />
      {error && <div className="error">{error}</div>}
    </div>
  );
}

export default React.memo(TextArea);
