import find from 'lodash/find';
import invert from 'lodash/invert';

import { Storage } from 'services/storage';

import { isEmpty, isArray, isNotEmpty } from 'util/utils';

import { USERS_TYPE } from './Common';
import { MENU } from './Menu';
import _PERMISSION from './PermissionConstants';

const getDefaultPermissions = (spread = {}) => ({
  view: true,
  insert: true,
  update: true,
  delete: true,
  uploadExcel: true,
  downloadExcel: true,
  mailExcel: true,
  printPDF: true,
  all: true,
  ...spread,
});

export const PERMISSION = _PERMISSION;

export const USER_PERMISSION = [
  { module: 'DASHBOARD', title: 'Dashboard', permissions: getDefaultPermissions() },
  { module: 'SEARCH_DIAMOND', title: 'Search Diamond', permissions: getDefaultPermissions() },
  { module: 'SEARCH_LIST', title: 'Search List', permissions: getDefaultPermissions() },
  { module: 'SAVE_SEARCH', title: 'My Saved Search', permissions: getDefaultPermissions() },
  { module: 'DEMAND', title: 'Demand', permissions: getDefaultPermissions() },
  { module: 'CART', title: 'Cart', permissions: getDefaultPermissions() },
  { module: 'WATCHLIST', title: 'Watchlist', permissions: getDefaultPermissions() },
  { module: 'OFFICE', title: 'Office', permissions: getDefaultPermissions() },
  { module: 'NOTES', title: 'Notes', permissions: getDefaultPermissions() },
  { module: 'MEMO', title: 'Memo', permissions: getDefaultPermissions() },
  { module: 'CONFIRM_STONE', title: 'My Confirm List', permissions: getDefaultPermissions() },
  // { module: 'APPOINTMENT', title: 'Appointment', permissions: getDefaultPermissions() },
  { module: 'COMPARE', title: 'Compare', permissions: getDefaultPermissions() },
  { module: 'ENQUIRY', title: 'Enquiry', permissions: getDefaultPermissions() },
  { module: 'DOWNLOAD', title: 'Download', permissions: getDefaultPermissions() },
  { module: 'SHARE_VIA_WHATSAPP', title: 'Share via whatsapp', permissions: getDefaultPermissions() },
  { module: 'SHARE_VIA_MAIL', title: 'Share via mail', permissions: getDefaultPermissions() },
  { module: 'SHARE_VIA_SKYPE', title: 'Share via skype', permissions: getDefaultPermissions() },
  { module: 'PRINT', title: 'Print', permissions: getDefaultPermissions() },
  { module: 'MATCH_PAIR', title: 'Match Pair', permissions: getDefaultPermissions() },
  { module: 'SEARCH_MATCH_PAIR', title: 'Search Match Pair', permissions: getDefaultPermissions() },
  { module: 'NEW_ARRIVAL', title: 'New Arrival', permissions: getDefaultPermissions() },
  { module: 'FEATURE_STONE', title: 'Feature Stone', permissions: getDefaultPermissions() },
  { module: 'UPCOMING', title: 'Upcoming Diamonds', permissions: getDefaultPermissions() },
  { module: 'NOTIFICATIONS', title: 'Notifications', permissions: getDefaultPermissions() },
  // { module: 'LAYOUT', title: 'Layout', permissions: getDefaultPermissions() },
  // { module: 'BID', title: 'BID IT', permissions: getDefaultPermissions() },
  // { module: 'BUSINESS_SUMMARY', title: 'Business Summary', permissions: getDefaultPermissions() },
  // { module: 'EXCLUSIVE', title: 'Exclusive', permissions: getDefaultPermissions() },
  // { module: 'HIDE_GRID_COLUMNS', title: 'Hide Grid Columns', permissions: { org: false, ftc: false, mines: false } },
  // { module: 'MY_BID', title: 'My Bid', permissions: getDefaultPermissions() },
  // { module: 'QUOTE', title: 'Offer', permissions: getDefaultPermissions() },
  // { module: 'SEARCH_LAYOUT', title: 'Search Layout', permissions: getDefaultPermissions() },
  // { module: 'SUGGESTED_STOCK', title: 'Suggested stock', permissions: getDefaultPermissions() },
];

export const MODULES = {
  TRANSACTION: {
    ORDER_REQUEST: {
      NAME: 'ORDER_REQUEST',
      MODULE: { PENDING: 'PENDING', APPROVED: 'APPROVED', REJECTED: 'REJECTED', DELIVERED: 'DELIVERED' },
    },
    QUOTE: {
      NAME: 'QUOTE',
      MODULE: { PENDING: 'PENDING', APPROVED: 'APPROVED', REJECTED: 'REJECTED', COUNTER: 'COUNTER' },
    },
    CONFIRM_STONE: {
      NAME: 'CONFIRM_STONE',
      MODULE: { PENDING: 'PENDING', APPROVED: 'APPROVED', REJECTED: 'REJECTED', CANCELLED: 'CANCELLED' },
    },
    ENQUIRY: {
      NAME: 'ENQUIRY',
      MODULE: { SEEN_STONES: 'SEEN_STONES', ENQUIRY_OPEN: 'ENQUIRY_OPEN', ENQUIRY_CLOSE: 'ENQUIRY_CLOSE' },
    },
    CART: {
      NAME: 'CART',
      MODULE: { CART: 'CART' },
    },
    WATCHLIST: {
      NAME: 'WATCHLIST',
      MODULE: { WATCHLIST: 'WATCHLIST' },
    },
    NOTES: {
      NAME: 'NOTES',
      MODULE: { NOTES: 'NOTES' },
    },
    OFFICE_VIEW: {
      NAME: 'OFFICE_VIEW',
      MODULE: { PENDING: 'PENDING', APPROVED: 'APPROVED', REJECTED: 'REJECTED' },
    },
    BID: {
      NAME: 'BID',
      MODULE: {
        RUNNING: 'RUNNING',
        HISTORY: 'HISTORY',
        WATCHLIST: 'WATCHLIST',
        PENDING: 'PENDING',
        VIEWED: 'VIEWED',
        APPROVED: 'APPROVED',
        MYCLIENTBID: 'MYCLIENTBID',
      },
    },
    HOLD: {
      NAME: 'HOLD',
      MODULE: { BN_R2NET: 'BN_R2NET', HOLD: 'HOLD' },
    },
    MEMO: {
      NAME: 'MEMO',
      MODULE: { ISSUED: 'ISSUED', RECEIVED: 'RECEIVED', CANCELLED: 'CANCELLED' },
    },
    LOCATIONLIST: {
      NAME: 'LOCATIONLIST',
      MODULE: { LOCATIONLIST: 'LOCATIONLIST', RECEIVE: 'RECEIVE' },
    },
    SE_REPORT: {
      NAME: 'ECS_REPORT',
      MODULE: { E: 'E', C: 'C', S: 'S' },
    },
    PROFORMA_REPORT: {
      NAME: 'PROFORMA_REPORT',
      MODULE: { OK: 'OK', PENDING: 'PENDING' },
    },
    TRACK_SHIPMENT: {
      NAME: 'TRACK_SHIPMENT',
    },
  },
  PRICING: {
    REVISE: { NAME: 'REVISE' },
  },
  NOTIFICATIONS: {
    BULK_EMAIL: { NAME: 'BULK_EMAIL' },
    NEW: { NAME: 'NEW' },
    SEEN: { NAME: 'CLEARED' },
  },
  INVENTORY: {
    SINGLE_STONE: {
      NAME: 'SINGLE_STONE',
      MODULE: {
        OVERVIEW: 'OVERVIEW',
        SEARCH: 'SEARCH',
        QUICKSEARCH: 'QUICK_SEARCH',
        NEWARRIVAL: 'NEW_ARRIVAL',
        UPCOMING: 'UPCOMING',
        SAVEDSEARCH: 'SAVED_SEARCH',
        DEMAND: 'MY_DEMAND',
        MATCHPAIR: 'MATCH_PAIR',
        SEARCH_LAYOUT: 'SEARCH_LAYOUT',
        // SEARCH_MATCH_PAIR: 'SEARCH_MATCH_PAIR',
        SHOWSTONEREPORT: 'SHOW_STONE_REPORT',
        QUOTEDAY: 'QUOTE_DAY',
        CONFIRM_STONE: 'CONFIRM_STONE',
        EXPORT_EXCEL: 'EXPORT_EXCEL',
      },
    },
    STOCK_SUMMARY: {
      NAME: 'STOCK_SUMMARY',
      MODULE: {
        SUMMARY: 'SUMMARY',
        DIST_CRITERIA: 'DIST_CRITERIA',
        BLUENILE: 'BLUENILE',
        JAMESALLEN: 'JAMESALLEN',
        NO_DIST: 'NO_DIST',
      },
    },
    ANALYTICS: {
      NAME: 'ANALYTICS',
    },
    CONFIGURATION: {
      NAME: 'CONFIGURATION',
      MODULE: {
        SYNC_WITH_GLOBAL_INVENTORY: 'SYNC_WITH_GLOBAL_INVENTORY',
        CREATE_COLLECTIONS: 'CREATE_COLLECTIONS',
        TERMS_DETAILS: 'TERMS_DETAILS',
        VOLUME_DISCOUNT: 'VOLUME_DISCOUNT',
        FEATURE_STONE: 'FEATURE_STONE',
        BID_CONFIGURATION: 'BID_CONFIGURATION',
        CARAT_RANGE: 'CARAT_RANGE',
        CUT_GROUP: 'CUT_GROUP',
        RAPNET_POLICY: 'RAPNET_POLICY',
        CHECK_IMAGE: 'CHECK_IMAGE',
        CERTIFICATE_UPLOAD: 'CERTIFICATE_UPLOAD',
        DIAMOND_COLUMN_SETTING: 'DIAMOND_COLUMN_SETTING',
        DOWNLOAD_EXCEL: 'DOWNLOAD_EXCEL',
        QUOTE_DAY: 'QUOTE_DAY',
        CHECK_PACKET: 'CHECK_PACKET',
      },
    },
  },
  DASHBOARD: {
    DASHBOARD: { NAME: 'DASHBOARD' },
    ENQUIRY: { NAME: 'ENQUIRY' },
    INVENTORY: { NAME: 'INVENTORY' },
    SEARCH: { NAME: 'SEARCH' },
    ANALYSIS: {
      NAME: 'ANALYSIS',
      MODULE: { SALESREPORT: 'SALESREPORT', SALESANALYSIS: 'SALESANALYSIS' },
    },
  },
  USER: {
    OVERVIEW: { NAME: 'OVERVIEW' },
    ACTIONS: { NAME: 'ACTIONS' },
    PERFORMANCE: { NAME: 'PERFORMANCE' },
    CONFIGURATION: {
      NAME: 'CONFIGURATION',
      MODULE: { CHANGE_PASSWORD: 'CHANGE_PASSWORD', BASIC_DETAILS: 'BASIC_DETAILS', PERMISSIONS: 'PERMISSIONS' },
    },
    BEHAVIOUR: {
      NAME: 'BEHAVIOUR',
      MODULE: { LOGIN_LOG: 'LOGIN_LOG', ACTIVITY: 'ACTIVITY' },
    },
  },
  CLIENT: {
    KYC: {
      NAME: 'KYC',
      MODULE: {
        GENERAL: 'GENERAL',
        BUSINESS: 'BUSINESS_DETAILS',
        BRANCH: 'BRANCH',
        BANKS: 'BANKS',
        DEPARTMENT: 'DEPARTMENT',
        USER: 'USER',
        REFERENCES: 'REFERENCES',
        SETTING: 'SETTING',
        TERMDETAILS: 'TERMS_DETAIL',
      },
    },
    ENQUIRY: {
      NAME: 'ENQUIRY',
      MODULE: { HOLD: 'HOLD', MEMO: 'MEMO', DEMAND: 'DEMAND' },
    },
    TRANSACTION: {
      NAME: 'TRANSACTION',
      MODULE: {
        CART: 'CART',
        WATCHLIST: 'WATCHLIST',
        NOTE: 'NOTES',
        OFFICE_VIEW: 'OFFICE_VIEW',
        SEEN_STONES: 'SEEN_STONES',
        ENQUIRY_CLOSE: 'ENQUIRY_CLOSE',
        ENQUIRY_OPEN: 'ENQUIRY_OPEN',
        LOCATIONLIST: 'LOCATIONLIST',
        RECEIVE: 'RECEIVE',
      },
    },
    CONFIRM_STONE: {
      NAME: 'CONFIRM_STONE',
      MODULE: { PENDING: 'PENDING', APPROVED: 'APPROVED', REJECTED: 'REJECTED', CANCELLED: 'CANCELLED' },
    },
    OFFER: {
      NAME: 'QUOTE',
      MODULE: { PENDING: 'PENDING', APPROVED: 'APPROVED', REJECTED: 'REJECTED' },
    },
    SEARCH: {
      NAME: 'SEARCH',
      MODULE: { SAVEDSEARCH: 'SAVED_SEARCH', RECENT: 'RECENT_SEARCH' },
    },
    ANALYTICS: { NAME: 'ANALYTICS' },
    SUGGESTED_STOCK: { NAME: 'SUGGESTED_STOCK' },
  },
  TODO: {
    KYC: {
      NAME: 'KYC',
      MODULE: { CLOSED: 'CLOSED', IN_PROGRESS: 'IN_PROGRESS', PENDING: 'PENDING' },
    },
    SALES: { NAME: 'SALES' },
  },
  // CRM: { TARGET: { NAME: 'TARGET' } },
  // UTILITY: {
  //   SHIPPING_LABEL: {
  //     NAME: 'SHIPPING_LABEL',
  //     MODULE: { PENDING: 'PENDING', ACCEPTED: 'ACCEPTED', REJECTED: 'REJECTED' },
  //   },
  //   CHEQUE_DETAILS: {
  //     NAME: 'CHEQUE_DETAILS',
  //     MODULE: { PENDING: 'PENDING', ACCEPTED: 'ACCEPTED', REJECTED: 'REJECTED' },
  //   },
  //   RAPNET: {
  //     NAME: 'RAPNET',
  //     MODULE: { PENDING: 'PENDING', ACCEPTED: 'ACCEPTED', REJECTED: 'REJECTED' },
  //   },
  // },
  SHORTCUT: { USER: { NAME: 'USER' }, KYC: { NAME: 'CLIENT_KYC' } },
  SETTING: {
    ACCOUNT: { NAME: 'MY_ACCOUNT' },
    NOTIFICATION: { NAME: 'NOTIFICATION' },
    TERMS: { NAME: 'TERMS_AND_CONDITIONS' },
    MY_SETTING: {
      NAME: 'MY_SETTING',
      MODULE: {
        MASTER: 'MASTER',
        SUB_MASTER: 'SUB_MASTER',
        HOME_SETTING: 'HOME_SETTING',
        STOCK: 'STOCK',
        MATCH_PAIR_SETTING: 'MATCH_PAIR_SETTING',
        PROJECT_SETTING: 'PROJECT_SETTING',
        CONTACT_DIRECTORY: 'CONTACT_DIRECTORY',
        ROLE_PERMISSION: 'ROLE_PERMISSION',
      },
    },
  },
};

export const MAINMODULE = {
  TRANSACTION: 'TRANSACTION',
  PRICING: 'PRICING',
  NOTIFICATIONS: 'NOTIFICATION',
  INVENTORY: 'INVENTORY',
  DASHBOARD: 'DASHBOARD',
  USER: 'USER',
  CLIENT: 'CLIENT',
  TODO: 'TODO',
  SHORTCUT: 'SHORTCUTS',
  SETTING: 'SETTING',
  // CRM: 'CRM',
};

export function getLoginPermission() {
  const loginPermission = Storage.get('permission') || [];
  let permit = loginPermission?.filter(isNotEmpty);
  if (permit.filter((x) => x.mainModule).length === 0) permit = [];

  // if (res?.data?.user?.type === USERS_TYPE.SUPER_ADMIN ? [] :) permit = PERMISSION;
  if (Storage.get('user')?.type === USERS_TYPE.SUPER_ADMIN) {
    permit = PERMISSION;
  }

  if (!isEmpty(permit)) {
    permit.forEach((val) => {
      if (!val.subModule && val.mainModule) {
        const fst = val.mainModule.split('_')[0];
        const main = invert(MAINMODULE)[val.mainModule]
          ? val.mainModule
          : invert(MAINMODULE)[fst]
          ? fst
          : val.mainModule;
        const mod = val.module;
        const sub = val.subModule
          ? val.subModule
          : invert(MAINMODULE)[fst] && val.mainModule.includes('_')
          ? val.mainModule.split('_').slice(1).join('_')
          : val.module;

        val.mainModule = main;
        val.subModule = sub;
        const obj = PERMISSION.filter((el) => el.mainModule === main && el.subModule === sub && el.module === mod);

        if (obj && obj[0]) {
          val.url = obj[0].url;
        }
      }
    });
  }
  // return PERMISSION;
  return permit;
}

export function getPermittedMainModuleList() {
  const PermissionData = getLoginPermission();
  let MenuData = [];
  if (PermissionData) {
    MENU.forEach((val) => {
      const modules = PermissionData.filter((el) => el.mainModule === val.permit)
        .map((x) => x.permissions.view || x.permissions.all)
        .filter((el) => el && el);
      if (modules.length) MenuData.push(val);
    });
  } else MenuData = MENU;
  return MenuData;
}

export function getPermittedSubModuleList(mainModule) {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.mainModule === mainModule)
    .filter((x) => x.permissions.view || x.permissions.all)
    .map((x) => x.subModule || x.module);
  return MenuData;
}

export function getPermittedModuleList(mainModule, subModule) {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.mainModule === mainModule && el.subModule === subModule)
    .filter((x) => x.permissions?.view || x.permissions?.all)
    .map((x) => x.module);
  return MenuData;
}

export function getPermittedModuleListWithoutSubmodule(mainModule, module) {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.mainModule === mainModule && el.module === module)
    .filter((x) => x.permissions.view || x.permissions.all)
    .map((x) => x.module);
  return MenuData;
}

export function getPermittedInsertList(mainModule, subModule) {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.mainModule === mainModule && el.subModule === subModule)
    .filter((x) => x.permissions.insert || x.permissions.all)
    .map((x) => x.module);
  return MenuData;
}

export function getPermittedInsertListFirst(mainModule, subModule, module) {
  const PermissionData = getLoginPermission();
  let MenuData = subModule
    ? find(PermissionData, { mainModule, subModule })
    : find(PermissionData, { mainModule, module });
  if (MenuData) {
    MenuData = MenuData.permissions.insert || MenuData.permissions.all ? [MenuData.subModule] : [];
  } else MenuData = [];
  return MenuData;
}

export function getPermittedUpdateListFirst(mainModule, subModule, module) {
  const PermissionData = getLoginPermission();
  let MenuData = find(PermissionData, { mainModule, subModule, module });
  if (MenuData) {
    MenuData = MenuData.permissions.update || MenuData.permissions.all ? [MenuData.subModule] : [];
  } else MenuData = [];
  return MenuData;
}

export function getPermittedDeleteFirst(mainModule, subModule, module) {
  const PermissionData = getLoginPermission();
  let MenuData = subModule
    ? find(PermissionData, { mainModule, subModule })
    : find(PermissionData, { mainModule, module });
  if (MenuData) {
    MenuData = MenuData.permissions.delete || MenuData.permissions.all ? [MenuData.subModule] : [];
  } else MenuData = [];
  return MenuData;
}

export function getPermittedExportList(mainModule, subModule) {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.mainModule === mainModule && el.subModule === subModule)
    .filter((x) => x.permissions.downloadExcel || x.permissions.all)
    .map((x) => x.module);
  return MenuData;
}

export function permiableRoute(url) {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.url === url)
    .map((x) => x.permissions.view || x.permissions.all)
    .filter((el) => el && el);
  return MenuData.length ? true : false;
}

export const filterRoutesByModule = (routesList, module, subModule) => {
  if (!module || !isArray(routesList)) return routesList;

  const PermittedSubModuleList = subModule
    ? getPermittedModuleList(module, subModule)
    : getPermittedSubModuleList(module);

  if (isEmpty(PermittedSubModuleList) || !isArray(routesList)) return routesList;
  return routesList.filter((el) => PermittedSubModuleList.includes(el?.tab));
};

export function getModuleFromUrl() {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.url && window.location.pathname.includes(el.url));
  const permit = MenuData[0] ? MenuData[0].permissions : {};

  if (permit.all) {
    // permit = {
    //   view: true,
    //   insert: true,
    //   update: true,
    //   delete: true,
    //   uploadExcel: true,
    //   downloadExcel: true,
    //   mailExcel: true,
    //   printPDF: true,
    //   all: true,
    // };
    Object.keys(permit).map((key) => {
      permit[key] = true;
    });
  }
  return permit;
}

export function downloadPermit() {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.url && window.location.pathname.includes(el.url))
    .map((x) => x.permissions.downloadExcel || x.permissions.all)
    .filter((el) => el && el);
  return MenuData[0] ? true : false;
}

export function printPermit() {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.url && window.location.pathname.includes(el.url))
    .map((x) => x.permissions.printPDF || x.permissions.all)
    .filter((el) => el && el);
  return MenuData[0] ? true : false;
}
