import React from 'react';
import { useBoolean } from 'util/hooks';

import './embed.less';

function Embed(props) {
  const { tag = 'iframe', name, title, clickToOpen, ..._props } = props;
  const [error, setError] = useBoolean();

  const handleClick = React.useCallback(() => {
    if (clickToOpen) window.open(_props.src, '_blank');
  }, [clickToOpen, _props.src]);

  const component = React.useMemo(() => {
    return error
      ? _props?.children ?? <div className="no-msg">{name} Not available</div>
      : React.createElement(tag, { onError: setError.true, STYLE: 'border: none', title, ..._props });
  }, [error, name, _props, title, setError, tag]);

  return clickToOpen ? (
    <div className="embed-wrapper">
      <div className="embed-overlay" title={title} onClick={handleClick} />
      {component}
    </div>
  ) : (
    component
  );
}

export default React.memo(Embed);
