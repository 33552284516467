import React from 'react';
import { withRouter } from 'react-router-dom';

import { Checkbox, Radio } from 'antd';

import Modal from 'containers/CommonModal';

import { StoneService } from 'services/StoneService';
import { getLoginUser } from 'services/commonFunc';

import { Notify } from 'util/notify';
import {
  formatDecimal,
  isArray,
  isEmpty,
  isFunction,
  isNotEmpty,
  objectToQueryString,
  queryStringToObject,
} from 'util/utils';
import { groupByUserAccount } from 'util/utils/groupByUserAccount';

import { FILE_URLS, USERS_TYPE } from 'constants/Common';
import { COMMON_URL } from 'constants/CommonUrl';
import { Messages } from 'constants/Messages';

import SendEmailPopup from './SendEmailPopup';

import './share-action.less';

function ShareCheckbox(props) {
  const { checked, id, onChange, title, ...restProps } = props;

  return (
    <li onClick={onChange} {...restProps}>
      <div className="dropdownBottomItem">
        <div className="dropdownIconCheckBox">
          <Checkbox onChange={onChange} checked={checked}></Checkbox>
        </div>
        <span className="shareOptionLabel">{title}</span>
      </div>
    </li>
  );
}

export function ShareRadio(props) {
  const { checked, onChange, title, ...restProps } = props;

  return (
    <li onClick={onChange} {...restProps}>
      <div className="dropdownBottomItem">
        <div className="dropdownIconCheckBox">
          <Radio onChange={onChange} checked={checked}></Radio>
        </div>
        <span className="shareOptionLabel">{title}</span>
      </div>
    </li>
  );
}

function ShareAction(props) {
  const loginUser = getLoginUser();
  const { checked, client, downloadExcel, onClose, visible, orderList } = props;

  const [isHa, setIsHa] = React.useState(false);
  const [isPlt, setIsPlt] = React.useState(false);
  const [isCert, setIsCert] = React.useState(false);
  const [isProp, setIsProp] = React.useState(false);
  const [isExcel, setIsExcel] = React.useState(false);

  const [isImg, setIsImg] = React.useState(false);
  const [isFls, setFlsImage] = React.useState(false);
  const [isNatural, setNaturalImage] = React.useState(false);

  const [isVideo, setIsVideo] = React.useState(false);
  const [isVideo2, setIsVideo2] = React.useState(false);

  const [originalStoneId, setOriginalStoneId] = React.useState(false);
  const [withOutOriginalId, setWithOutOriginalId] = React.useState(false);
  const [excelForBoth, setExcelForBoth] = React.useState(false);

  const [withoutPrice, setWithoutPrice] = React.useState(true);
  const [withPrice, setWithPrice] = React.useState(false);
  const [diamondDetail, setDiamondDetail] = React.useState(false);

  const [link, setLink] = React.useState(false);
  const [images, setImages] = React.useState(false);
  const [videos, setVideos] = React.useState(false);
  const [sendEmail, setSendEmail] = React.useState(false);
  const [allChecked, setAllChecked] = React.useState(false);
  const clickRef = React.useRef(false);
  const toggleEmailModal = React.useCallback(() => setSendEmail((sendEmail) => !sendEmail), []);
  const userAccount = React.useMemo(() => groupByUserAccount(checked, client, 'vStnId'), [checked, client]);

  const checkedIds = React.useMemo(() => {
    return isArray(checked) && checked?.map?.((item) => item?.id ?? item?._id); // _id (for location list)
  }, [checked]);

  // const anyOptionsChecked =
  //   isHa || isCert || isExcel || isImg || isPlt || isVideo || isVideo2 || isNatural || isProp || isFls;

  const getShareLinks = React.useCallback(async () => {
    const links = ['Hello,'];
    // links.push(`Greetings from SL Diamonds,`);

    // if (!isArray(checked) || isEmpty(checked) || withPrice) return;
    // if (!anyOptionsChecked) return Notify.error({ message: 'Please Select atleast 1 Option to Share.' });

    void checked?.forEach?.((stone) => {
      const videoLink =
        stone?.videoFile && stone?.files?.videoFile.match('https://up.diacam360.com')
          ? stone?.files?.videoFile + '/video'
          : stone?.files?.videoFile;

      links.push(
        originalStoneId || excelForBoth ? `\nOriginal Stock No. :  ${stone?.oldvStnId}\n` : '',
        `Stone No :  ${stone?.vStnId}\n`,
      );
      // links.push(`\nStone ID :  ${stone?.vStnId}\n`);

      links.push(
        [
          stone?.shpNm,
          formatDecimal(stone?.crt),
          stone?.colNm,
          stone?.clrNm,
          stone?.polNm,
          stone?.symNm,
          stone?.fluNm,
          stone?.lbNm,
          stone?.rptNo,
        ]
          .filter(isNotEmpty)
          .join(' | '),
      );

      // if (link) {

      if (checked.length > 0) {
        links.push(
          `\nDiamond Details : ${COMMON_URL.DNA_URL}dna/${
            stone?.diamondId ? stone?.diamondId : stone?.id ?? stone?._id
          }${withPrice ? '?wp=true' : '  '}`,
        );
      }

      // }
      if (isImg && stone.img) {
        links.push(`\nImage : ${stone.files?.img}`);
      }

      // if (isNatural && stone.img) {
      //   links.push(`Natural Image:\n${FILE_URLS.naturalImage?.replace(/[*]{3}/g, stone.vStnId)}`);
      // }

      // if (isVideo2 && stone.videoFile) {
      //   links.push(`360 Video:\n${FILE_URLS.hdVideoFileMp4?.replace(/[*]{3}/g, stone.vStnId)}`);
      // }

      if (isVideo && stone.videoFile) {
        links.push(`\nVideo : ${videoLink}`);
      }

      if (isCert && stone.certFile) {
        links.push(`\nCertificate : ${stone.files?.certFile}`);
      }

      // if (isHa && stone.hAFile) {
      //   links.push(`HA Image:\n${FILE_URLS.idealWhiteImage?.replace(/[*]{3}/g, stone.vStnId)}`);
      //   if (!window.location?.pathname.includes('single-stone/predefine-match-pair') && stone.shpNm === 'ROUND') {
      //     links.push(FILE_URLS.arrBlack?.replace(/[*]{3}/g, stone.vStnId));
      //   }

      //   links.push(FILE_URLS.hAFile?.replace(/[*]{3}/g, stone.vStnId));
      //   if (stone.shpNm === 'ROUND') {
      //     links.push(FILE_URLS.heartImage?.replace(/[*]{3}/g, stone.vStnId));
      //   }
      // }

      // if (isFls && stone.img) {
      //   links.push(`Fluorescence Image:\n${FILE_URLS.fluorescenceImage?.replace(/[*]{3}/g, stone.vStnId)}`);
      // }

      // if (isPlt && stone.img) {
      //   links.push(`Plot Image:\n${FILE_URLS.pltFile?.replace(/[*]{3}/g, stone.vStnId)}`);
      // }

      // if (isProp && stone.img) {
      //   links.push(`Proportion Image:\n${FILE_URLS?.proportionImage?.replace(/[*]{3}/g, stone.vStnId)}`);
      // }
    });

    if (isExcel) {
      const getExcelLink = () =>
        isFunction(downloadExcel)
          ? downloadExcel(!excelForBoth ? { withOriginalStoneId: originalStoneId } : undefined)
          : StoneService.getExcelLink(checked, {
              userAccount,
              withOriginalStoneId: !excelForBoth ? originalStoneId : undefined,
            });
      const excel = await getExcelLink();
      links.push(`\nDownload Excel : ${excel}`);
    }

    return encodeURIComponent(links.join(`\n`));
  }, [checked, isExcel, originalStoneId, isImg, isVideo, isCert, withPrice, downloadExcel, excelForBoth, userAccount]);

  const shareToWhatsApp = React.useCallback(async () => {
    const text = await getShareLinks();
    if (isEmpty(text)) return;

    window.open(`https://web.whatsapp.com/send?text=${text}`);
    onClose();
  }, [getShareLinks, onClose]);

  const shareToSkype = React.useCallback(async () => {
    const text = await getShareLinks();
    if (isEmpty(text)) return;

    window.open(`https://web.skype.com/share?url=${text}`);
    onClose();
  }, [getShareLinks, onClose]);

  const shareToEmail = React.useCallback(() => {
    // if (!anyOptionsChecked) return Notify.error({ message: 'Please Select atleast 1 Option to Share.' });
    const shareDiamond = getShareLinks();

    if (isEmpty(shareDiamond)) {
      return Notify.error({ message: Messages.resourceNotAvailable });
    }

    setSendEmail(true);
  }, [getShareLinks]);

  const getOriginalStoneId = (stoneId) => {
    setExcelForBoth(stoneId == 'both');
    setOriginalStoneId(stoneId == 'with');
    setWithOutOriginalId(stoneId == 'withOut');
    setIsExcel(true);
  };

  React.useEffect(() => {
    setIsHa(false);
    setIsImg(false);
    setIsPlt(false);
    setIsCert(false);
    setIsProp(false);
    setImages(false);
    setVideos(false);
    setLink(false);
    setIsExcel(false);
    setIsVideo(false);
    setOriginalStoneId(false);
    setWithOutOriginalId(false);
    setFlsImage(false);
    setIsVideo2(false);
    setNaturalImage(false);
  }, [visible]);

  React.useEffect(() => {
    setIsHa(images);
    setIsImg(images);
    setIsPlt(images);
    setIsProp(images);
    setFlsImage(images);
    setNaturalImage(images);
  }, [images]);

  React.useEffect(() => {
    setIsVideo(videos);
    setIsVideo2(videos);
  }, [videos]);

  React.useEffect(() => {
    setWithoutPrice();
  }, []);

  React.useEffect(() => {
    setIsCert(isCert);
  }, [isCert]);

  React.useEffect(() => {
    setImages(allChecked);
    setVideos(allChecked);
    setIsCert(allChecked);
    setIsExcel(allChecked);
    setLink(allChecked);
    setExcelForBoth(allChecked);
    if (clickRef.current) setDiamondDetail(!allChecked);
  }, [allChecked]);

  return (
    <Modal title="Share Stone" handleCancel={onClose} visible={visible ?? true} className="md-size" destroyOnClose>
      <div>
        <Checkbox
          checked={allChecked}
          onChange={() => {
            clickRef.current = true;
            setAllChecked(!allChecked);
          }}
        >
          <span className="checkBox-span">Select All</span>
        </Checkbox>
        <div className="searchPopupCommon">
          <div className="shareOptionSet">
            <div className="shareOptionSetItem shareOptionImage">
              <ul>
                <div className="dropdownMainTitle">
                  <div className="dropdownIconCheckBox">
                    <Checkbox checked={images} onChange={() => setImages(!images)}></Checkbox>
                  </div>
                  <span>Images : </span>
                </div>
                <div className="dropdownRightCheck">
                  <ShareCheckbox title="Image" checked={isImg} onChange={() => setIsImg(!isImg)} />
                  {/* <ShareCheckbox
                    title="Natural Image"
                    checked={isNatural}
                    onChange={() => setNaturalImage(!isNatural)}
                  />
                  <ShareCheckbox title="Heart & Arrow Image" checked={isHa} onChange={() => setIsHa(!isHa)} />
                  <ShareCheckbox title="Fluorescence Image" checked={isFls} onChange={() => setFlsImage(!isFls)} />
                  <ShareCheckbox title="Plot Image" checked={isPlt} onChange={() => setIsPlt(!isPlt)} />
                  <ShareCheckbox title="Proportion Image" checked={isProp} onChange={() => setIsProp(!isProp)} /> */}
                </div>
              </ul>
              <ul>
                <div className="dropdownMainTitle">
                  <div className="dropdownIconCheckBox">
                    <Checkbox checked={videos} onChange={() => setVideos(!videos)}></Checkbox>
                  </div>
                  <span>Video : </span>
                </div>
                <div className="dropdownRightCheck">
                  {/* <ShareCheckbox title="360 Video" checked={isVideo2} onChange={() => setIsVideo2(!isVideo2)} /> */}
                  <ShareCheckbox title="Video" checked={isVideo} onChange={() => setIsVideo(!isVideo)} />
                </div>
              </ul>
              <ul>
                <div className="dropdownMainTitle">
                  {/* <div className="dropdownIconCheckBox">
                    <Checkbox checked={link} onChange={() => setLink((link) => !link)}></Checkbox>
                  </div>
                  <span>Detail Link : </span> */}
                  <div className="dropdownIconCheckBox">
                    <Checkbox checked={!diamondDetail} onChange={() => setDiamondDetail(!diamondDetail)}></Checkbox>
                  </div>
                  <span>Diamond detail : </span>
                </div>
                <div className="dropdownRightCheck">
                  {/* <ShareRadio
                    title="With Price"
                    checked={withPrice}
                    onClick={() => setWithPrice(!withPrice)}
                    // onChange={() => setWithoutPrice((v) => !v)}
                  /> */}
                  <ShareCheckbox
                    title="Diamond detail"
                    checked={!withPrice}
                    onClick={() => setWithPrice(!withPrice)}
                    // onChange={() => setWithoutPrice((v) => !v)}
                  />
                </div>
              </ul>
              <ul className="d-flex">
                <div className="width-50 d-flex align-items-center">
                  <div className="dropdownMainTitle">
                    <span>Certificate : </span>
                  </div>
                  <div className="dropdownRightCheck">
                    <ShareCheckbox title="Certificate" checked={isCert} onChange={() => setIsCert(!isCert)} />
                  </div>
                </div>
                {/* <div className="width-50 d-flex align-items-center excelShare">
                  <div className="dropdownMainTitle">
                    <span>Excel : </span>
                  </div>
                  <div className="dropdownRightCheck">
                    <div>
                      <ShareCheckbox checked={isExcel} onChange={() => setIsExcel(!isExcel)} title="Excel" />
                      </div>
                      </div>
                    </div> */}
              </ul>
              <ul>
                <div className="dropdownMainTitle">
                  <span>Excel : </span>
                </div>

                {[USERS_TYPE.ADMIN, USERS_TYPE.SUPER_ADMIN].includes(loginUser?.type) ? (
                  <div className="dropdownRightCheck">
                    <ShareRadio checked={excelForBoth} onChange={() => getOriginalStoneId('both')} title="Both" />

                    <ShareRadio
                      title="Original Stock No."
                      checked={originalStoneId}
                      onClick={() => getOriginalStoneId('with')}
                    />
                    <ShareRadio
                      title="Modified Stock No."
                      checked={withOutOriginalId}
                      onClick={() => getOriginalStoneId('withOut')}
                    />
                  </div>
                ) : (
                  <ShareRadio title="Excel" checked={withOutOriginalId} onClick={() => getOriginalStoneId('withOut')} />
                )}
              </ul>
            </div>
          </div>
          <div className="commonModalButton mt-50">
            <button className="fillButton" onClick={shareToEmail}>
              Email
            </button>
            <button className="fillButton ml-5" onClick={shareToWhatsApp}>
              WhatsApp
            </button>
            <button className="fillButton ml-5" onClick={shareToSkype}>
              Skype
            </button>
          </div>
        </div>
      </div>
      <SendEmailPopup
        currentType={props.currentType}
        client={client}
        orderList={orderList}
        checked={checked}
        sendEmail={sendEmail}
        onCancel={toggleEmailModal}
        img={isImg}
        hAFile={isHa}
        isPlt={isPlt}
        isFls={isFls}
        isReal={isImg}
        isProp={isProp}
        excelForBoth={excelForBoth}
        originalStoneId={originalStoneId}
        withOutOriginalId={withOutOriginalId}
        ids={checkedIds}
        onClose={onClose}
        certFile={isCert}
        isExcel={isExcel}
        mp4Video={isVideo2}
        videoFile={isVideo}
        isNatural={isNatural}
        excelPDFTab={props?.excelPDFTab}
        excelType={props?.excelType}
      />
    </Modal>
  );
}

export default withRouter(ShareAction);
