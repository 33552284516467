import React from 'react';

export function useList(list = [], count = 0) {
  const [state, _setState] = React.useState({ list, count });

  const setList = React.useCallback((list, count) => {
    _setState((state) => ({
      list: list ?? state.list,
      count: count ?? state.count,
    }));
  }, []);

  const resetList = React.useCallback(() => {
    _setState(() => ({ list: [], count: 0 }));
  }, []);

  React.useDebugValue(state);
  return [state?.list, state?.count, setList, resetList];
}
