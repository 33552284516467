import React from 'react';

import { isEmpty, isNotEmpty, isString, pruneEmpty } from 'util/utils';

import { useCurrentModule } from './useCurrentModule';

export function useCurrentType(...args) {
  const module = useCurrentModule();

  const currentType = React.useMemo(() => {
    const _args = pruneEmpty(args.filter(isString).filter(isNotEmpty));
    return !isEmpty(_args) ? _args.join('.') : module?.path;
  }, [args, module?.path]);

  return [currentType];
}
