import { Storage } from 'services/storage';

import { callApi } from 'util/call-api';
import { isEmpty, isNotEmpty } from 'util/utils';

import { MEMO_TYPES, USERS_TYPE } from 'constants/Common';
import { API_ROUTES } from 'constants/CommonApi';

class DiamondLocationService {
  static getFormattedSalespersonName(data) {
    if (isNotEmpty(data?.seller)) {
      return `${data?.name} (SELLER)`;
    } else if (isNotEmpty(data?.userAccount)) {
      return `${data?.name} (BROKER)`;
    }
  }

  static async getDiamondLocation(stone, callback) {
    const [, res] = await callApi({
      ...API_ROUTES.DiamondLocation.checkLocation,
      request: { vStnIds: [stone?.vStnId], isDeleted: false, status: MEMO_TYPES.MEMO_IN },
    });

    const locationData = res?.data?.locationData;
    const memoData = res?.data?.memoDetails;
    const data = locationData ?? memoData;
    const locNm = stone?.locNm ?? 'NA';
    const companyName = memoData?.companyName;
    let userName;
    const urls = window.location.pathname?.split('/');
    const tab = urls?.pop();

    const user = Storage.get('user');
    const stoneUserId =
      stone?.createdBy ||
      (stone &&
        stone?.tracks?.length > 0 &&
        stone?.tracks[0].blockDetails?.length > 0 &&
        stone?.tracks[0].blockDetails[0]?.createdBy);

    if (stone?.user) {
      userName = `${stone?.user?.firstName} ${stone?.user?.lastName}`;
    } else if (stone?.tracks?.length > 0 && stone?.tracks[0].blockDetails?.length > 0) {
      userName = `${stone?.tracks[0].blockDetails[0]?.user?.firstName} ${stone?.tracks[0].blockDetails[0]?.user?.lastName}`;
    } else {
      userName = memoData?.name;
    }
    const fullCompanyDetails = `${companyName ? companyName : ''}${userName ? ' - ' + userName : ''}`;

    if (tab === 'pending') {
      callback({ source: locNm, destination: DiamondLocationService.getFormattedSalespersonName(stone?.locationData) });
      return;
    }
    if (isEmpty(data)) return callback({ source: locNm });

    if (isNotEmpty(locationData) && isNotEmpty(memoData)) {
      callback({
        source: `${locNm} | ${DiamondLocationService.getFormattedSalespersonName(locationData)}`,
        destination:
          user?.id === stoneUserId ||
          [USERS_TYPE.SUPER_ADMIN, USERS_TYPE.ADMIN]?.includes(user?.type) ||
          (tab === 'search' && USERS_TYPE?.INTERNAL_SELLER === user?.type)
            ? fullCompanyDetails ?? null
            : null,
        locationData: locationData,
      });
      return;
    }

    if (isNotEmpty(locationData)) {
      callback({ source: locNm, destination: DiamondLocationService.getFormattedSalespersonName(locationData) });
      return;
    }

    if (isNotEmpty(memoData)) {
      callback({
        source: locNm,
        destination:
          user?.id === stoneUserId ||
          [USERS_TYPE.SUPER_ADMIN, USERS_TYPE.ADMIN]?.includes(user?.type) ||
          (tab === 'search' && USERS_TYPE?.INTERNAL_SELLER === user?.type)
            ? fullCompanyDetails ?? null
            : null,
      });
      return;
    }
  }
}

export default DiamondLocationService;
