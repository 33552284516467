import { Block } from 'entities/Block';

import { MediaService } from 'services/MediaService';

import { callApi } from 'util/call-api';
import { unique } from 'util/utils';

import { BASE_DOWNLOAD_URL, SETTING_TYPE } from 'constants/Common';
import { API_ROUTES } from 'constants/CommonApi';

export class BlockCollection {
  static identifier = 'BlockCollection';

  blocks = [];

  constructor(blockDetailList = []) {
    this.blocks = blockDetailList.map((detail) => new Block(detail));
  }

  get memoNoList() {
    return unique(this.blocks.map((block) => block.memoNo));
  }

  get vStnIdList() {
    return this.blocks.map((block) => block.stone.vStnId);
  }

  async getInvoiceLink(payload = {}) {
    payload = {
      memoNo: this.memoNoList,
      vStnIds: this.vStnIdList,
      through: undefined,
      companyType: undefined,
      type: SETTING_TYPE.INVOICE_DETAILS,
      inBlockDiamonds: true,
      ...payload,
    };
    const [err, res] = await callApi({ ...API_ROUTES.ORDER.GET_INVOICE, payload, showLoader: true });
    const link = err ? undefined : `${BASE_DOWNLOAD_URL}${res?.data}`;
    return [err, link];
  }

  async downloadInvoice(payload = {}) {
    const [err, link] = await this.getInvoiceLink(payload);
    return err
      ? [err, undefined]
      : MediaService.downloadFile(link, { name: `INVOICE-${this.memoNoList.join('_')}.pdf` });
  }

  async viewInvoice(payload = {}) {
    const [err, link] = await this.getInvoiceLink(payload);
    return err ? [err, undefined] : MediaService.viewFile(link, false);
  }
}
