import React from 'react';
import { useSelector } from 'react-redux';

export function withSelectedClient(WrappedComponent) {
  return function WithSelectedClient(props) {
    const client = useSelector((state) => state.tab?.clientList?.customerDetail);

    return <WrappedComponent {...props} client={client} inClient />;
  };
}
