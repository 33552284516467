import React from 'react';

import { fetchcolumnAsyncMemo } from 'components/Inventory/inventoryFunctions';

import { isFunction } from 'util/utils';

import { useAutoRef } from './useAutoRef';

export function useColumns(setDefault = false, options = {}) {
  const [columns, _setColumns] = React.useState([]);
  const defaultOptions = useAutoRef(options);

  const setColumns = React.useCallback(
    async (params) => {
      const { type, transform, beforeSet, afterSet, ...rest } = { ...defaultOptions.current, ...params };
      if (isFunction(beforeSet)) beforeSet();
      const columns = await fetchcolumnAsyncMemo(type, rest);
      isFunction(transform) ? _setColumns(transform(columns)) : _setColumns(columns);
      if (isFunction(afterSet)) afterSet();
    },
    [defaultOptions],
  );

  React.useEffect(() => {
    if (setDefault) setColumns();
  }, [setDefault, setColumns]);

  React.useDebugValue(columns);
  return [columns, setColumns];
}
