import React from 'react';
import PropTypes from 'prop-types';
import { isArray, isEmpty, isObject } from 'util/utils';
import classNames from 'util/classNames';

const InlineList = ({
  list,
  separator,
  dlSeparator,
  defaultStyle,
  parentClassName,
  labelClassName,
  skipEmpty,
  onClick,
}) => {
  if (!isArray(list)) return null;
  if (defaultStyle) {
    parentClassName = 'savedSearchValue';
    labelClassName = 'savedSearchLabel';
  }
  const max = list.length;

  return list.map((item, i) => (
    <React.Fragment key={i}>
      {item?.title == 'isDeleted' ? (
        <></>
      ) : React.isValidElement(item) ? (
        <>
          <span className={classNames(['list-item-inline', parentClassName])} onClick={onClick}>
            {item}
          </span>
          {max !== i + 1 && !defaultStyle && (separator || ', ')}
        </>
      ) : isObject(item) && (!isEmpty(item?.title) || !isEmpty(item?.value)) ? (
        <>
          <span className={classNames(['list-item-inline', parentClassName])} onClick={onClick}>
            {!isEmpty(item?.title) && (
              <span className={classNames([labelClassName])}>
                {item?.title}
                {!isEmpty(item?.value) && (dlSeparator || ' :')}
              </span>
            )}
            {!isEmpty(item?.value) && (
              <span>
                {isArray(item?.value) ? (
                  <InlineList skipEmpty list={item.value} dlSeparator={dlSeparator} />
                ) : item?.value?.toString ? (
                  item.value.toString()
                ) : (
                  `${item?.value}`
                )}
              </span>
            )}
            {max !== i + 1 && !defaultStyle && (separator || ', ')}
          </span>
        </>
      ) : (
        <>
          <span className={classNames(['list-item-inline', parentClassName])} onClick={onClick}>
            {!isEmpty(item) && (item?.toString ? item?.toString() : `${item}`)}
            {isEmpty(item) && !skipEmpty && '-'}
            {max !== i + 1 &&
              (skipEmpty
                ? !isEmpty(item) && !defaultStyle && (separator || ', ')
                : !defaultStyle && (separator || ', '))}
          </span>
        </>
      )}
    </React.Fragment>
  ));
};

InlineList.propTypes = {
  list: PropTypes.array,
  separator: PropTypes.any,
};

export default InlineList;
