import React from 'react';

import { PermissionService } from 'services/PermissionService';

import { LocalModuleContext } from './LocalModuleContext';

export const LocalModuleProvider = React.memo(({ path, children }) => {
  const [tree, setTree] = React.useState(() => PermissionService.getPermission(path));

  React.useEffect(() => {
    setTree(PermissionService.getPermission(path));
    return PermissionService.events.updateTree.listen(() => setTree(PermissionService.getPermission(path)));
  }, [path]);

  const isAllowed = tree?.allow ?? true;

  if (!isAllowed) return null;

  return <LocalModuleContext.Provider value={tree}>{children}</LocalModuleContext.Provider>;
});

LocalModuleProvider.displayName = 'LocalModuleProvider';
