import React from 'react';

import { TABLE_PAGE_LIMIT } from 'constants/Common';

import { useAutoRef } from './useAutoRef';

export function usePagination(page = 1, limit = TABLE_PAGE_LIMIT) {
  const [state, _setState] = React.useState({ page, limit });
  const paramsRef = useAutoRef({ page, limit });

  const setPagination = React.useCallback((page, limit) => {
    _setState((state) => ({
      page: page ?? state.page,
      limit: limit ?? state.limit,
    }));
  }, []);

  const resetPagination = React.useCallback(() => {
    const { page, limit } = paramsRef.current ?? {};
    _setState({ page, limit });
  }, [paramsRef]);

  React.useDebugValue(state);
  return [state?.page, state?.limit, setPagination, resetPagination];
}
