const getDefaultPermissions = (spread = {}) => ({
  view: true,
  insert: true,
  update: true,
  delete: true,
  uploadExcel: true,
  downloadExcel: true,
  mailExcel: true,
  printPDF: true,
  all: true,
  ...spread,
});

/* DASHBOARD */
const DASHBOARD = [
  {
    mainModule: 'DASHBOARD',
    module: 'DASHBOARD',
    url: '/dashboard/home',
    title: 'Dashboard',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'DASHBOARD',
    module: 'ENQUIRY',
    url: '/dashboard/enquiry',
    title: 'Enquiry',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'DASHBOARD',
    module: 'INVENTORY',
    url: '/dashboard/inventory',
    title: 'Inventory',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'DASHBOARD',
    module: 'SEARCH',
    title: 'Search',
    url: '/dashboard/search',
    permissions: getDefaultPermissions(),
  },
];

/* INVENTORY */
const INVENTORY = [
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'OVERVIEW',
    title: 'Overview',
    url: '/inventory/single-stone/overview',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'SEARCH',
    title: 'Search',
    url: '/inventory/single-stone/search',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'MATCH_PAIR',
    title: 'Search Match Pair',
    url: '/inventory/single-stone/predefine-match-pair',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'NEW_ARRIVAL',
    title: 'New Arrival',
    url: '/inventory/single-stone/new-arrival',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'SAVED_SEARCH',
    title: 'Saved Search',
    url: '/inventory/single-stone/saved-search',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'MY_DEMAND',
    title: 'Demands',
    url: '/inventory/single-stone/my-demand',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'EXPORT_EXCEL',
    title: 'Export Excel',
    // url: '/inventory/single-stone/my-demand',
    permissions: getDefaultPermissions(),
  },
  /* INVENTORY.CONFIGURATION */
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'CREATE_COLLECTIONS',
    title: 'Create Collections',
    url: '/inventory/configuration/create-collection',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'FEATURE_STONE',
    title: 'Feature Stone',
    url: '/inventory/configuration/feature-stone',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'CARAT_RANGE',
    title: 'Carat Range',
    url: '/inventory/configuration/carat-range',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'DIAMOND_COLUMN_SETTING',
    title: 'Diamond List Column Setting',
    url: '/inventory/configuration/diamond-column-setting',
    permissions: getDefaultPermissions(),
  },
];

/* CLIENT */
const CLIENT = [
  /* KYC */
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'GENERAL',
    title: 'General',
    url: '/client/kyc/basic',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'USER',
    title: 'Users',
    url: '/client/kyc/user',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'BUSINESS_DETAILS',
    title: 'Business',
    url: '/client/kyc/business-detail',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'BANKS',
    title: 'Banks',
    url: '/client/kyc/bank-info',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'DEPARTMENT',
    title: 'Department',
    url: '/department',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'BRANCH',
    title: 'Branch',
    url: '/client/kyc/branch',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'REFERENCES',
    title: 'References',
    url: '/client/kyc/references-detail',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'TERMS_DETAIL',
    title: 'Markup',
    url: '/client/kyc/terms-detail',
    permissions: getDefaultPermissions(),
  },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'CONFIRM_STONE',
  //   module: 'PENDING',
  //   title: 'Confirm Stone Pending',
  //   url: '/pending',
  //   permissions: getDefaultPermissions(),
  // },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'CONFIRM_STONE',
  //   module: 'APPROVED',
  //   title: 'Confirm Stone Approved',
  //   url: '/approved',
  //   permissions: getDefaultPermissions(),
  // },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'CONFIRM_STONE',
  //   module: 'REJECTED',
  //   title: 'Confirm Stone Rejected',
  //   url: '/rejected',
  //   permissions: getDefaultPermissions(),
  // },
  //{
  //   mainModule: 'CLIENT',
  //   subModule: 'ENQUIRY',
  //   module: 'HOLD',
  //   title: 'Hold',
  //   url: '/client/enquiry/hold',
  //   permissions: getDefaultPermissions({ confirm: true }) },
  //{
  //   mainModule: 'CLIENT',
  //   subModule: 'ENQUIRY',
  //   module: 'MEMO',
  //   title: 'Memo',
  //   url: '/client/enquiry/memo',
  //   permissions: getDefaultPermissions({ confirm: true }) },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'ENQUIRY',
  //   module: 'OPEN',
  //   title: 'Open Enquiry',
  //   url: '/client/transactions/enquiry-open',
  //   permissions: getDefaultPermissions(),
  // },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'ENQUIRY',
  //   module: 'CLOSE',
  //   title: 'Closed Enquiry',
  //   url: '/client/transactions/enquiry-close',
  //   permissions: getDefaultPermissions(),
  // },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'ENQUIRY',
  //   module: 'DEMAND',
  //   title: 'Order Request',
  //   url: '/client/enquiry/demand',
  //   permissions: getDefaultPermissions(),
  // },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'CART',
  //   // module: 'CART',
  //   title: 'Cart',
  //   url: '/client/transactions/cart',
  //   permissions: getDefaultPermissions(),
  // },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'WATCHLIST',
  //   // module: 'WATCHLIST',
  //   title: 'Watchlist',
  //   url: '/client/watchlist',
  //   permissions: getDefaultPermissions(),
  // },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'NOTE',
  //   // module: 'NOTES',
  //   title: 'Notes',
  //   url: '/client/notes',
  //   permissions: getDefaultPermissions(),
  // },

  //{
  //   mainModule: 'CLIENT',
  //   subModule: 'TRANSACTION',
  //   module: 'OFFICE_VIEW',
  //   title: 'Appointment',
  //   url: '/client/transactions/officeview',
  //   permissions: getDefaultPermissions(),
  // },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'SUGGESTED_STOCK',
  //   module: 'SUGGESTED_STOCK',
  //   title: 'Suggested Stock',
  //   url: '/client/suggested-stock',
  //   permissions: getDefaultPermissions(),
  // },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'QUOTE',
  //   module: 'PENDING',
  //   title: 'Offer Pending',
  //   url: '/client/offer/pending',
  //   permissions: getDefaultPermissions({ isShowClientColumn: true }),
  // },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'QUOTE',
  //   module: 'APPROVED',
  //   title: 'Offer Approved',
  //   url: '/client/offer/approved',
  //   permissions: getDefaultPermissions({ isShowClientColumn: true }),
  // },

  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'QUOTE',
  //   module: 'REJECTED',
  //   title: 'Offer Rejected',
  //   url: '/client/offer/rejected',
  //   permissions: getDefaultPermissions({ isShowClientColumn: true }),
  // },

  {
    mainModule: 'CLIENT',
    subModule: 'SEARCH',
    module: 'SAVED_SEARCH',
    title: 'Saved Search',
    url: '/client/search/saved-search',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'SEARCH',
    module: 'RECENT_SEARCH',
    title: 'Recent Search',
    url: '/client/search/recent-search',
    permissions: getDefaultPermissions(),
  },
];

/* TRANSACTION */
const TRANSACTION = [
  // TRANSACTION.CONFIRM_STONE
  {
    mainModule: 'TRANSACTION',
    subModule: 'CONFIRM_STONE',
    module: 'PENDING',
    title: 'Confirm Stones Pending',
    url: '/pending',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'CONFIRM_STONE',
    module: 'APPROVED',
    title: 'Confirm Stones Approved',
    url: '/approved',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'CONFIRM_STONE',
    module: 'REJECTED',
    title: 'Confirm Stones Rejected',
    url: '/rejected',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'CONFIRM_STONE',
    module: 'CANCELLED',
    title: 'Confirm Stones Cancelled',
    url: '/cancelled',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'CONFIRM_STONE',
    module: 'HISTORY',
    title: 'Confirm Stones History',
    url: '/history',
    permissions: getDefaultPermissions(),
  },
  // TRANSACTION.ENQUIRY
  {
    mainModule: 'TRANSACTION',
    subModule: 'ENQUIRY',
    module: 'ENQUIRY_OPEN',
    title: 'Open Enquiry',
    url: '/transaction/enquiry/enquiry-open',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'ENQUIRY',
    module: 'ENQUIRY_CLOSE',
    title: 'Closed Enquiry',
    url: '/transaction/enquiry/enquiry-close',
    permissions: getDefaultPermissions(),
  },
  // TRANSACTION.CART
  {
    mainModule: 'TRANSACTION',
    subModule: 'CART',
    module: 'CART',
    title: 'Cart',
    url: '/transaction/cart',
    permissions: getDefaultPermissions(),
  },
  // TRANSACTION.WATCHLIST
  {
    mainModule: 'TRANSACTION',
    subModule: 'WATCHLIST',
    module: 'WATCHLIST',
    title: 'Watch list',
    url: '/transaction/watchlist',
    permissions: getDefaultPermissions(),
  },
  // TRANSACTION.NOTES
  {
    mainModule: 'TRANSACTION',
    subModule: 'NOTES',
    module: 'NOTES',
    title: 'Notes',
    url: '/transaction/notes',
    permissions: getDefaultPermissions(),
  },

  /* TRANSACTION.HOLD */
  {
    mainModule: 'TRANSACTION',
    subModule: 'HOLD',
    module: 'HOLD',
    title: 'Hold',
    url: '/transaction/hold/hold',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'HOLD',
    module: 'RELEASE',
    title: 'Hold Release',
    url: '/transaction/hold/release',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'HOLD',
    module: 'HISTORY',
    title: 'Hold History',
    url: '/transaction/hold/history',
    permissions: getDefaultPermissions(),
  },

  /* TRANSACTION.MEMO */
  {
    mainModule: 'TRANSACTION',
    subModule: 'MEMO',
    module: 'ISSUED',
    title: 'Memo Issued',
    url: '/transaction/memo/issued',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'MEMO',
    module: 'RECEIVED',
    title: 'Memo Received',
    url: '/transaction/memo/received',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'MEMO',
    module: 'CANCELLED',
    title: 'Memo Cancelled',
    url: '/transaction/memo/cancelled',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'MEMO',
    module: 'HISTORY',
    title: 'Memo History',
    url: '/transaction/memo/history',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'LOCATIONLIST',
    module: 'LOCATIONLIST',
    title: 'Location List',
    url: '/transaction/location-list',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'LOCATIONLIST',
    module: 'RECEIVE',
    title: 'Receive List',
    url: '/transaction/receive',
    permissions: getDefaultPermissions(),
  },
];

/* DIAMOND_TRACKING */
const DIAMOND_TRACKING = [
  {
    mainModule: 'DIAMOND_REPORTS',
    module: 'DIAMOND_TRACKING',
    title: ' Diamond Tracking',
    url: '/diamond-tracking',
    permissions: getDefaultPermissions(),
  },
];

/* NOTIFICATION */
const NOTIFICATION = [
  {
    mainModule: 'NOTIFICATION',
    module: 'BULK_EMAIL',
    title: 'Bulk Email',
    url: '/notification/bulk-email',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'NOTIFICATION',
    module: 'NEW',
    title: 'Unseen',
    url: '/unseen',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'NOTIFICATION',
    module: 'CLEARED',
    title: 'Seen',
    url: '/seen',
    permissions: getDefaultPermissions(),
  },
];

/* USERS */
const USERS = [
  // USER.CONFIGURATION
  {
    mainModule: 'USER',
    subModule: 'CONFIGURATION',
    module: 'BASIC_DETAILS',
    title: 'Basic Details',
    url: '/user/configuration/user-information',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'USER',
    subModule: 'CONFIGURATION',
    title: 'Permissions',
    module: 'PERMISSIONS',
    url: '/user/configuration/permissions',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'USER',
    subModule: 'CONFIGURATION',
    module: 'CHANGE_PASSWORD',
    title: 'Change Password',
    url: '/user/configuration/change-password',
    permissions: getDefaultPermissions(),
  },
  // USER.SEARCH
  {
    mainModule: 'USER',
    title: 'Saved Search',
    subModule: 'SEARCH',
    module: 'SAVED',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'USER',
    title: 'Recent Search',
    subModule: 'SEARCH',
    module: 'RECENT',
    permissions: getDefaultPermissions(),
  },
];

/* SHORTCUTS  */
const SHORTCUTS = [
  {
    mainModule: 'SHORTCUTS',
    module: 'CLIENT_KYC',
    url: '/user/configuration/user-information',
    title: 'Clients / KYC',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'SHORTCUTS',
    module: 'USER',
    url: '/user/configuration/user-information',
    title: 'User',
    permissions: getDefaultPermissions(),
  },
];

/* SETTING */
const SETTING = [
  {
    mainModule: 'SETTING',
    module: 'MY_ACCOUNT',
    title: 'My Account',
    permissions: getDefaultPermissions(),
  },
  // SETTING.MY_SETTING
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'MASTER',
    title: 'Master',
    url: '/setting/master',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'SUB_MASTER',
    title: 'Sub Master',
    url: '/setting/submaster',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'ROLE_PERMISSION',
    title: 'Role Permission',
    url: '/rolepermission',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'PRICE_LIST',
    title: 'Price List',
    url: '/price-list',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'CHANGE_PASSWORD',
    title: 'Change Password',
    url: '/setting/change-password',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'HOME_SETTING',
    title: 'Home Settings',
    url: '/setting/home-setting',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'STOCK',
    title: 'Stock',
    url: '/setting/stock',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'MATCH_PAIR_SETTING',
    title: 'Match Pair Setting',
    url: '/setting/matchpair',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'PROJECT_SETTING',
    title: 'Project Settings',
    url: '/project-setting',
    permissions: getDefaultPermissions(),
  },
];

export default [
  ...DASHBOARD,
  ...INVENTORY,
  ...CLIENT,
  ...TRANSACTION,
  ...NOTIFICATION,
  ...USERS,
  ...SHORTCUTS,
  ...SETTING,
  ...DIAMOND_TRACKING,
];
