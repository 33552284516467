import UtilService from 'services/util';

import { isEmpty } from 'util/utils';

import { API_ROUTES } from 'constants/CommonApi';

import Storage from './storage';

export const getStoneList = (request, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.Diamond.paginate, request, ...(!isEmpty(config) && { config }) });

export const fetchSearch = async (request = {}, config) =>
  UtilService.callApiAsync({
    ...API_ROUTES.Diamond.search,
    request: {
      isAppendMasters: true,
      account: request.account || Storage.get('user')?.account?.id,
      ...request,
    },
    ...(!isEmpty(config) && { config }),
  });

export const deleteSearch = async (id, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.Diamond.delete, request: { id }, ...(!isEmpty(config) && { config }) });

export const getSearchIds = async (filters, config) =>
  UtilService.callApiAsync({
    ...API_ROUTES.Diamond.paginate,
    request: { filters: [filters], isNotReturnTotal: true, isReturnCountOnly: true },
    ...(!isEmpty(config) && { config }),
  });

export const matchPairList = (request = {}) => UtilService.callApiAsync({ ...API_ROUTES.MatchPair.Paginate, request });
