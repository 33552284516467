import React from 'react';

import { calculate, quoteCalculation } from 'components/DiamondListing/SelectStone';

import { useSelectedRows } from 'util/hooks';
import { i18n } from 'util/i18n';
import { formatCurrency, formatDecimal, formatNumber, isEmpty, parseDecimal } from 'util/utils';

export function HeaderCalcConnected({ currentType }) {
  const [selectedRows] = useSelectedRows(currentType);
  if (isEmpty(selectedRows)) return null;
  return <HeaderCalc list={selectedRows} tab={currentType} />;
}

export function HeaderCalc({ list, tab }) {
  const calc = React.useMemo(() => calculate(list), [list]);
  const quoteCalc = React.useMemo(() => (tab === 'quoteApprove' ? quoteCalculation(list) : undefined), [list, tab]);

  return (
    <div className="DiamondDetailPopup d-flex">
      <div className="DiamondDetailPopupItem">
        <span>CT.:</span>

        <span>{formatDecimal(calc.total_carat)}</span>
      </div>
      <div className="DiamondDetailPopupItem">
        <span>Disc%:</span>
        <span>{formatDecimal(calc.final_discount)}</span>
      </div>
      <div className="DiamondDetailPopupItem">
        <span>Price/Ct:</span>
        <span>{formatCurrency(calc.final_price)}</span>
      </div>
      {['note', 'orderApprove', 'quoteApprove', 'order', 'showStone', 'bid'].includes(tab) && (
        <div className="DiamondDetailPopupItem">
          <span>Amount:</span>
          <span>{formatCurrency(calc.final_value)}</span>
        </div>
      )}
      {tab === 'quoteApprove' && (
        <>
          <div className="DiamondDetailPopupItem">
            <span>Discount Different: </span>
            <span>{parseDecimal(quoteCalc.discountDifferent)}</span>
          </div>
          <div className="DiamondDetailPopupItem">
            <span>{i18n.t('title.offer')}: </span>
            <span>{parseDecimal(quoteCalc.offer)}</span>
          </div>
          <div className="DiamondDetailPopupItem">
            <span>{i18n.t('title.offer')} value: </span>
            <span>{parseDecimal(quoteCalc.offerValue)}</span>
          </div>
        </>
      )}
    </div>
  );
}
