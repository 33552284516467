export const randomString = (
  length = 50,
  characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
) => {
  let result = '';
  for (let i = 0; i < length; i++) result += characters.charAt(Math.floor(Math.random() * characters.length));
  return result;
};

export const capitalize = (input) =>
  input?.replace(/_/g, ' ')?.replace(/(\w+)/g, (x) => x[0].toUpperCase() + x.substring(1));

export const fixedNumber = (value) => parseFloat(Number(value)).toFixed(2);

export const validateNumberInput = (evt) => {
  if ((evt.which < 48 || evt.which > 57) && evt.which !== 46 && evt.which !== 45) {
    evt.preventDefault();
  }
};
