import { LoaderService } from 'services/LoaderService';
import { MediaService } from 'services/MediaService';
import { callApiAsync } from 'services/util';

import { Notify } from 'util/notify';
import { isArray, isEmpty, isString } from 'util/utils';

import { API_ROUTES } from 'constants/CommonApi';
import { COMMON_URL } from 'constants/CommonUrl';

export class OrderService {
  static async getSlipFilePath(stoneList = [], options = {}) {
    // return if empty
    const isListEmpty = !isArray(stoneList) || isEmpty(stoneList);
    if (isListEmpty) {
      Notify.error({ message: 'Please, select order(s) from the group' });
      return;
    }

    // check if any record has different jangadNo
    const isOrder = stoneList.some((rec) => !isEmpty(rec?.invoiceDate));
    const notHasSameJangadNo = stoneList.some((rec) => rec?.jangadNo !== stoneList[0]?.jangadNo);
    if (notHasSameJangadNo) {
      Notify.error({ message: `Please, select orders with same ${isOrder ? 'invoice' : 'memo'} no.` });
      return;
    }

    // list of unique memo numbers
    const memoNoList = stoneList
      .map((rec) => rec?.memoNo)
      .filter((value, index, list) => list.indexOf(value) === index)
      .sort();

    // list of unique vStnIds
    const vStnIdList = stoneList
      .map((rec) => rec?.vStnId)
      .filter((value, index, list) => list.indexOf(value) === index)
      .sort();

    LoaderService.startLoading();
    const [err, res] = await callApiAsync({
      ...API_ROUTES.Order.orderDiamondPdf,
      request: { ...options?.payload, memoNo: memoNoList, vStnIds: vStnIdList },
    });
    LoaderService.stopLoading();

    if (!err && isString(res?.data)) return `${COMMON_URL.BASE_URL}data${res.data}`;

    Notify.error({ message: 'Something went wrong' });
    return;
  }

  static async viewSlip(stoneList = [], options = {}) {
    LoaderService.startLoading();
    const path = await this.getSlipFilePath(stoneList, options);
    if (isString(path)) await MediaService.viewFile(path);
    LoaderService.stopLoading();
  }

  static async downloadSlip(stoneList = [], options = {}) {
    LoaderService.startLoading();
    const path = await this.getSlipFilePath(stoneList, options);
    if (isString(path)) await MediaService.downloadFile(path);
    LoaderService.stopLoading();
  }

  static async printSlip(stoneList = [], options = {}) {
    LoaderService.startLoading();
    const path = await this.getSlipFilePath(stoneList, options);
    if (isString(path)) await MediaService.printFile(path);
    LoaderService.stopLoading();
  }
}

window.__OrderService = OrderService;
