import Cookies from 'js-cookie';

import { PermissionService } from 'services/PermissionService';
import { Storage } from 'services/storage';

import { axios } from 'util/axios';
import { callApi, withURLParams } from 'util/call-api';
import { history } from 'util/history';
import { formatDateTime } from 'util/utils';

import { PREFIX_URL } from 'constants/CommonUrl';

import { store } from 'appRedux/store';

export { callApi, withURLParams, withQueryParams } from 'util/call-api';
export { callApi as callApiAsync, withURLParams as withUrlParams } from 'util/call-api';

export const handleLogout = () => {
  // remove token
  Cookies.remove('token');
  delete axios.defaults.headers.common['Authorization'];

  // clear in memory storage
  Storage.deleteAll();
  PermissionService.clear();
  store.dispatch({ type: '@@RESET' });

  // redirect to signin page
  history.push(`/${PREFIX_URL}/signin`);
};

export default {
  callApi,
  callApiAsync: callApi,
  withUrlParams: withURLParams,

  displayDate: (date) => formatDateTime(date, 'LLL dd yyyy hh:mm:ss a'),
  getSyncValue: () => false,
};
