import { prepareDataForValidation, yupToFormErrors } from 'formik';

/**
 * Validate and get error using yup
 *
 * @param {yup.AnySchema} schema  yup schema
 * @param {any}           value   value to validate against schema
 * @param {object}        options options
 */
export async function getYupErrors(schema, values, options = {}) {
  try {
    const data = prepareDataForValidation(values);
    await schema.validate(data, { abortEarly: false, context: data, ...options });
  } catch (error) {
    return yupToFormErrors(error);
  }
  return {};
}
