import { configureStore } from '@reduxjs/toolkit';

import { isDevEnv } from 'util/utils';

import rootReducer from '../reducers';

export const store = configureStore({
  reducer: rootReducer,
  devTools: isDevEnv() ? { trace: true, traceLimit: 25 } : false,
  middleware: (f) => f({ thunk: true, immutableCheck: false, serializableCheck: false }),
});

if (isDevEnv() && module.hot) {
  module.hot.accept('../reducers', () => store.replaceReducer(rootReducer));
}
