import { flatten } from 'lodash';
import _each from 'lodash/each';
import _uniq from 'lodash/uniq';

import { downloadExcel, printPdf } from 'services/CommonApi';
import Storage from 'services/storage';
import UtilService from 'services/util';

import { catchError, isArray, isEmpty, isFunction, isString, logInfo, logWarn } from 'util/utils';
import { groupByUserAccount } from 'util/utils/groupByUserAccount';

import { BASE_URL, CommonPrintExcel, USERS_TYPE } from 'constants/Common';
import ConstantApi from 'constants/CommonApi';
import { API_ROUTES } from 'constants/CommonApi';
import { COMMON_URL } from 'constants/CommonUrl';
import Messages from 'constants/Messages';
import OpenNotification from 'constants/OpenNotifications';

export const sendPricingEmail = (request = {}) => {
  return UtilService.callApiAsync({
    ...ConstantApi.Pricing.sendEmail,
    request,
  });
};

// Diamond
export const getWithoutDiamondList = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.Inventory.imageCheck,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

export const placeOrderHandel = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.DiamondConfirm.Request,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

// Diamond track
export const getGridColumnList = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.Gridcolumn.list, request, ...(!isEmpty(config) && { config }) });
};

export const saveGridColumnList = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.Gridcolumn.update, request, ...(!isEmpty(config) && { config }) });
};

/**
 * Function used to get saved search list
 * @param {*} request
 */
export const savedSearchList = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.Diamond.search, request, ...(!isEmpty(config) && { config }) });
};

/**
 * Function used to get total diamond count
 * @param {*} filter
 * @param {*} cb
 */
export const getCount = (filter, cb) => {
  _each(filter, (val, key) => {
    if (isArray(val) && val.length === 0) delete filter[key];
    else if (isArray(val)) filter[key] = _uniq(val);
  });

  const fcColDesc = catchError(
    () =>
      ['fcCol', 'inten', 'ovrtn'].map((col) => {
        const fancyColorIds = filter?.[col];
        if (isEmpty(fancyColorIds)) return [];
        const master = Storage.get('master');

        return fancyColorIds.map((id) => {
          const masterToLookAt =
            col === 'ovrtn' ? 'OVERTONE' : col === 'inten' ? 'INTENSITY' : col === 'fcCol' ? 'FANCY_COLOR' : '';
          const M = master[masterToLookAt].find((item) => item?.id?.[0] === id);
          return M?.description;
        });
      }),
    () => [],
  );

  const fcColDescFlatten = fcColDesc.flat(1);
  filter.fcColDesc = isEmpty(fcColDescFlatten) ? undefined : fcColDescFlatten;

  // if (filter.lb) {
  //   let or = [{ lbIds: filter.lb }, { lb: filter.lb }];

  //   //delete filter.lb;
  //   filter = omit(filter, ['lb']);
  //   filter = {
  //     ...filter,
  //     or: filter.or ? concat(or, filter.or) : or,
  //   };
  // }

  UtilService.callApi(
    {
      ...ConstantApi.Diamond.paginate,
      request: {
        isNotReturnTotal: true,
        isReturnCountOnly: true,
        isSkipSave: true,
        filters: [filter],
      },
      config: {
        loader: false,
      },
      notify: false,
    },
    (err, res) => cb(res?.data?.[0]?.count),
  );
};

export const getCountAsync = (...args) => new Promise((resolve) => getCount(...args, resolve));

// Diamond track
export const getDiamondTrackList = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.DiamondTrack.paginate,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

export const getDiamondCommentRemove = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.Inventory.diamondCommentDelete,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

export const getDiamondCommentList = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.Inventory.diamondCommentPaginate,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

export const getLocationList = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.Location.stoneLocation,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

export const getDiamondBidList = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.DiamondBid.paginate, request, ...(!isEmpty(config) && { config }) });
};

export const getDiamondTrackRemove = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.DiamondTrack.delete, request, ...(!isEmpty(config) && { config }) });
};

export const getDiamondTrackChangeStatus = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.DiamondTrack.changeStatus,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

export const getDiamondTrackUpdateStatus = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.DiamondTrack.statusUpdate,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

export const createDiamondTrack = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.DiamondTrack.create, request, ...(!isEmpty(config) && { config }) });
};

export const removeFromLocation = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.Location.removeStone,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

export const filterApply = (request = {}) => {
  return UtilService.callApiAsync({
    ...ConstantApi.Location.filter,
    request,
  });
};

// Transaction Hold/Memo
export const getTransactionHoldMemo = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.Memo.paginate, request, ...(!isEmpty(config) && { config }) });
};

//End Diamond track

//Diamond excel

export const diamondFtc = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.Inventory.ftc, request, ...(!isEmpty(config) && { config }) });
};

export const sendEmailAttachment = (oldRequest = {}) => {
  const request = { ...oldRequest, sendEmail: true };
  //set new changes in api
  return UtilService.callApiAsync({
    ...ConstantApi.Inventory.exportExcel,
    request,
  });
};

export const sendXRayEmailAttachment = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.Inventory.xRayMail, request, ...(!isEmpty(config) && { config }) });
};

export const downloadExcelFile = async (request = {}, cb) => {
  request.isPairExcel = request.filter.isPairExcel;
  request.isLayoutExcel = request.filter.isLayoutExcel;
  delete request.filter.isLayoutExcel;
  delete request.filter.isPairExcel;

  const [err, res] = await UtilService.callApiAsync({
    ...ConstantApi.Inventory.exportExcel,
    request,
  });

  return new Promise((resolve) => {
    if (err || res?.code !== 'OK' || !isString(res?.data?.data)) return;

    const server = COMMON_URL.FILE_URL;
    const filePath = server + res?.data?.data;
    window.open(filePath, 'Download');
    if (isFunction(cb)) cb(true);
    resolve();
  });
};

/**
 * Function used to download pdf file
 * @param {*} request
 */
export const downloadPdfFile = async (
  diamond,
  requestUrl,
  filter = {},
  moduleParam,
  cb,
  excelPDFTab = null,
  client = {},
  isPrintListPopup = false,
) => {
  if (isFunction(cb)) cb(true);

  if (CommonPrintExcel[moduleParam]) {
    const user = Storage.get('user');
    if (user?.type != USERS_TYPE.SELLER) delete CommonPrintExcel[moduleParam]?.filter?.['sSts'];
  }
  const userAccount = groupByUserAccount(diamond, client, 'vStnId');
  const request = {
    ...(isArray(diamond) && diamond?.length
      ? {
          ...(excelPDFTab
            ? { excelType: excelPDFTab, filter: { id: diamond?.map((o) => o.blockId || o.id), userAccount } }
            : { id: diamond?.map((o) => o.id ?? o?._id), userAccount }), //_id for lcoation list
        }
      : // moduleParam == 'inOrder'
        // ? { orderDiamond: diamond?.map((o) => o.id) }
        {}),
    ...(!isEmpty(filter) ? { filter } : {}),
    ...(CommonPrintExcel[moduleParam] ? CommonPrintExcel[moduleParam] : {}),
    ...(isPrintListPopup ? { diamonds: diamond } : {}),
  };

  if (!isEmpty(requestUrl)) {
    ['page', 'limit'].map((d) => delete requestUrl?.request[d]);
  }
  const [err, res] =
    isEmpty(requestUrl) || (isArray(diamond) && diamond?.length) || !isEmpty(filter)
      ? await printPdf(request)
      : await requestUrl?.urlMethod({ ...requestUrl?.request, isPdf: true });

  if (err || res?.code !== 'OK' || !isString(res?.data)) {
    if (isFunction(cb)) cb(false);
    return;
  }

  const filePath = COMMON_URL.FILE_URL + res?.data;
  window.open(filePath, 'Download');
  if (isFunction(cb)) cb(false);
};

export const downloadZip = async (request = {}, cb) => {
  const [, res] = await UtilService.callApiAsync({
    ...ConstantApi.Inventory.downloadZip,
    request,
  });
  return new Promise((resolve) => {
    if (res?.code === 'OK' && isString(res?.data)) {
      const filePath = COMMON_URL.BASE_URL + res.data;
      window.location.href = filePath;
      if (isFunction(cb)) cb();
    } else {
      if (isEmpty(res?.data)) OpenNotification({ title: Messages.resourceNotAvailable, type: 'error' });
    }
    resolve();
  });
};

/**
 * Function used to download file
 * @param {*} request
 * @param {*} cb
 */
export const downloadFile = async (request = {}, cb, isDataPath = true) => {
  if (isString(request?.fileName)) request.fileName = request.fileName.replace(/\s+/g, '-');
  const [err, res] = await UtilService.callApiAsync({
    ...ConstantApi.Inventory.downloadFile,
    request,
  });
  if (err || res?.code !== 'OK') return;

  const server = isDataPath ? COMMON_URL.FILE_URL : BASE_URL;
  const file = `${server}${res?.data?.path}`;

  await new Promise((r) => setTimeout(r, 1000));
  return new Promise((resolve) => {
    try {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', file, true);
      xhr.responseType = 'blob';

      xhr.onload = function () {
        if (xhr.statusText == 'OK' || xhr.status == 200) {
          const URL = window.URL || window.webkitURL;
          const imageUrl = URL.createObjectURL(this.response);

          const tag = document.createElement('a');
          tag.href = imageUrl;
          tag.download = `${request.fileName}${request.ext}`;
          document.body.appendChild(tag);
          tag.click();
          document.body.removeChild(tag);
          resolve();
        } else {
          OpenNotification({
            type: 'error',
            title: Messages.resourceNotAvailable,
          });
          resolve();
        }
      };
      xhr.send();
    } catch (error) {
      logInfo(error);
      resolve();
    }
    if (isFunction(cb)) cb();
  });
};

export const downloadVideo = (apiObj, cb) => {
  return new Promise((resolve) => {
    try {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', apiObj.path, true);
      xhr.responseType = 'blob';

      xhr.onload = function () {
        const URL = window.URL || window.webkitURL;
        const imageUrl = URL.createObjectURL(this.response);
        const tag = document.createElement('a');
        tag.href = imageUrl;
        tag.target = '_blank';
        tag.download = apiObj.fileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      };
      xhr.onerror = (error) => alert(error);
      xhr.send();
    } catch (error) {
      logWarn(error);
    }
    if (isFunction(cb)) cb();
    resolve();
  });
};

export const downloadCertificate = async (request = {}) => {
  const [err, res] = await UtilService.callApiAsync({
    ...ConstantApi.Inventory.downloadCertificate,
    request,
  });
  if (err && res?.code !== 'OK') return;

  try {
    const server = COMMON_URL.FILE_URL;
    const filePath = server + res.data;
    const xhr = new XMLHttpRequest();
    xhr.open('GET', filePath, true);
    xhr.responseType = 'blob';

    xhr.onload = function () {
      const urlCreator = window.URL || window.webkitURL;
      const url = urlCreator.createObjectURL(this.response);
      const tag = document.createElement('a');
      tag.href = url;
      tag.download = request.fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  } catch (error) {
    return error;
  }
};

export const downloadDiamondExcel = async (
  diamond,
  requestUrl,
  filter = {},
  moduleParam,
  cb,
  excelPDFTab = null,
  extraRequest = {},
  client = {},
  originalStoneId,
) => {
  const user = Storage.get('user');

  if (isFunction(cb)) cb(true);
  if (CommonPrintExcel[moduleParam] && user?.type != USERS_TYPE.SELLER)
    delete CommonPrintExcel[moduleParam]?.filter?.['sSts'];
  const userAccount = groupByUserAccount(diamond, client, 'vStnId');
  const request = {
    ...(isArray(diamond) && diamond?.length
      ? {
          ...(excelPDFTab
            ? { excelType: excelPDFTab, filter: { id: diamond?.map((o) => o.blockId || o.id), userAccount } }
            : { id: diamond?.map((o) => o.id ?? o?._id), userAccount, ...extraRequest }), //_id for location List
        }
      : {}),
    ...(!isEmpty(filter) ? { filter } : {}),
    ...(CommonPrintExcel[moduleParam] ? CommonPrintExcel[moduleParam] : {}),
    withOriginalStoneId: originalStoneId?.withOriginalStoneId,
  };

  if (!isEmpty(requestUrl)) {
    ['page', 'limit'].map((d) => delete requestUrl?.request[d]);
  }
  const [, res] =
    isEmpty(requestUrl) || (isArray(diamond) && diamond?.length) || !isEmpty(filter)
      ? await downloadExcel(request)
      : await requestUrl?.urlMethod({ ...requestUrl?.request, isExcel: true });

  if (res?.code === 'OK') {
    if (isFunction(cb)) cb(false);
    return `${COMMON_URL.FILE_URL}${res?.data?.data}`;
  }
  if (isFunction(cb)) cb(false);
  return;
};

export const exportExcel = async (diamonds, originalStoneNo, cb) => {
  const MATCH_PAIR = window.location.pathname?.includes('match-pair');
  const request = {
    diamonds,
    id: diamonds.map((x) => x?.id),
    exportExcel: true,
    isPairExcel: MATCH_PAIR ? MATCH_PAIR : undefined,
    withOriginalStoneId: originalStoneNo,
  };

  const [, res] = await downloadExcel(request);

  if (res?.code === 'OK') {
    if (isFunction(cb)) cb(false);
    return `${COMMON_URL.FILE_URL}${res?.data?.data}`;
  }
  if (isFunction(cb)) cb(false);
  return;
};

export const getOfficeList = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.office.paginate, request, ...(!isEmpty(config) && { config }) });
};

export const uploadDiamondsExcel = (request = {}) => {
  return UtilService.callApiAsync({ ...ConstantApi.Diamond.upload, request });
};

export const addLocation = (request) => UtilService.callApiAsync({ ...API_ROUTES.Diamond.addLocation, request });

export const discountCalculations = (request) =>
  UtilService.callApiAsync({ ...API_ROUTES.Diamond.discountCalculations, request });

export const diamondSearchUpsert = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.Demand.Upsert, request, ...(!isEmpty(config) && { config }) });

export const diamondSearchPartialUpsert = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.Demand.PartialUpsert, request, ...(!isEmpty(config) && { config }) });

export const getMatchPairList = (request, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.MatchPair.Paginate, request, ...(!isEmpty(config) && { config }) });
