import memoize from 'lodash/memoize';

import { isNotEmpty } from './isNotEmpty';
import { lowerCase } from './lowerCase';
import { titleCase } from './titleCase';

export const getUserName = memoize(
  (input, replace = '-') => {
    input = input?.user ?? input;
    const name = titleCase([input?.firstName, input?.lastName].join(' '));
    const email = lowerCase(input?.email);
    return [name, input?.name, input?.username, email].find(isNotEmpty) ?? replace;
  },
  (user, replace) => `${user?.firstName}${user?.lastName}${user?.name}${user?.username}${user?.email}${replace}`,
);
