import React from 'react';

import { LoaderService } from 'services/LoaderService';

import { isNumber } from 'util/utils';

import { useAutoRef } from './useAutoRef';
import { useCounter } from './useCounter';

export function useLoading(show = false) {
  const [count, inc, dec] = useCounter(0);

  const countRef = useAutoRef(count);
  const showRef = useAutoRef(show);

  const start = React.useCallback(() => {
    const show = showRef.current;
    inc(() => show && LoaderService.startLoading());
  }, [inc, showRef]);

  const stop = React.useCallback(() => {
    const show = showRef.current;
    dec(() => show && LoaderService.stopLoading());
  }, [dec, showRef]);

  React.useEffect(() => {
    const hasClass = document?.body?.classList?.contains?.('progress');

    void (count > 0
      ? !hasClass && document?.body?.classList?.add?.('progress')
      : hasClass && document?.body?.classList?.remove?.('progress'));
  }, [count]);

  React.useEffect(() => {
    const show = showRef.current;
    const count = countRef.current;

    return () => {
      if (show && isNumber(count) && count > 0) {
        LoaderService.adjustCount(-Math.abs(count));
      }
    };
  }, [countRef, showRef]);

  React.useDebugValue(count);
  return [Boolean(count), start, stop];
}
