import { memoize } from 'lodash';

import { LoaderService } from 'services/LoaderService';

import { axios } from 'util/Api';
import { asyncHandler } from 'util/async-utils';
import { logWarn } from 'util/utils';

import { BASE_URL } from 'constants/CommonUrl';

export class MediaService {
  static async getHeaders(path) {
    try {
      const response = await axios.head(path);
      return response?.headers;
    } catch (error) {
      logWarn(error);
      return false;
    }
  }

  static memoizedGetHeaders = memoize(this.getHeaders, (arg) => `${arg}`);

  static async getBlobUrl(path) {
    try {
      const response = await axios.get(path, { responseType: 'blob' });
      return window.URL.createObjectURL(response?.data);
    } catch (error) {
      logWarn(error);
      throw error;
    }
  }

  static async viewFile(path, options) {
    options = { ...options };
    LoaderService.startLoading();
    const response = await asyncHandler(async () => {
      const blobUrl = await (options.blob ? this.getBlobUrl(path) : path);
      const anchor = document.createElement('a');
      void ((anchor.style.display = 'none'), (anchor.href = blobUrl), (anchor.target = '_blank'));
      void (document.body.appendChild(anchor), anchor.click(), anchor.remove(), window.URL.revokeObjectURL(blobUrl));
    });
    LoaderService.stopLoading();
    return response;
  }

  static async downloadFile(path, options) {
    options = { ...options };
    LoaderService.startLoading();
    const response = await asyncHandler(async () => {
      const blobUrl = await (options.blob ? this.getBlobUrl(path) : path);
      const anchor = document.createElement('a');
      void ((anchor.style.display = 'none'), (anchor.href = blobUrl));
      void ((anchor.target = '_blank'), (anchor.download = options.name ?? path.split('/').pop()));

      void (document.body.appendChild(anchor), anchor.click(), anchor.remove(), window.URL.revokeObjectURL(blobUrl));
    });
    LoaderService.stopLoading();
    return response;
  }

  static async downloadBusinessDoc(path, name) {
    LoaderService.startLoading();
    const response = await asyncHandler(async () => {
      const blobUrl = await this.getBlobUrl(path);
      const anchor = document.createElement('a');

      void ((anchor.style.display = 'none'),
      (anchor.href = blobUrl),
      (anchor.download = name ?? path.split('/').pop()));
      void (document.body.appendChild(anchor), anchor.click(), anchor.remove(), window.URL.revokeObjectURL(blobUrl));
    });
    LoaderService.stopLoading();
    return response;
  }

  static async printFile(path, options) {
    options = { ...options };
    LoaderService.startLoading();
    const response = await asyncHandler(async () => {
      const blobUrl = await (options.blob ? this.getBlobUrl(path) : path);
      const iframe = document.querySelector('#PrintFrame') ?? document.createElement('iframe');
      if (iframe?.src) window.URL.revokeObjectURL(iframe.src);
      void ((iframe.style.display = 'none'), (iframe.src = blobUrl), (iframe.height = '100%'), (iframe.width = '100%'));
      void ((iframe.id = 'PrintFrame'), document.body.appendChild(iframe), iframe.contentWindow.print());
    });
    LoaderService.stopLoading();
    return response;
  }
}

window.__MediaService = MediaService;
