import React from 'react';

import { Tooltip } from 'antd';
import { isString } from 'lodash';

import CheckBox from 'components/CheckBox';
import DiamondTotalCard from 'components/DiamondListing/DiamondTotalCard';

import { OrderService } from 'services/OrderService';
import { GROUP_KEY } from 'services/groupingArray';

import { useBoolean, useGroupSelect } from 'util/hooks';
import { classNames, isEmpty } from 'util/utils';

import { ALLOW_ROW_GROUP_TITLE_TOGGLE } from 'constants/Common';

import { store } from 'appRedux/store';

import excelWhiteSvg from 'assets/svg/InventoryResult/excel-white.svg';
import mailWhiteSvg from 'assets/svg/InventoryResult/mail-white.svg';
import { ReactComponent as PrintSvgIcon } from 'assets/svg/InventoryResult/print.svg';
import leftArrowWhiteSvg from 'assets/svg/left-arrow-white.svg';
import rightArrowWhiteSvg from 'assets/svg/right-arrow-white.svg';

const GroupCheckBox = React.memo((props) => {
  const { groupRows, currentType } = props;

  const [checked, indeterminate, toggle] = useGroupSelect(groupRows, currentType);
  const groupKey = React.useMemo(() => groupRows?.[0]?.[GROUP_KEY], [groupRows]);

  return <CheckBox name={groupKey} checked={checked} indeterminate={`${indeterminate}`} onChange={toggle} />;
});

function TableGrouping(props) {
  const { currentType, children, displayTotal = [], groupRows, noCheckBox, hideCheckbox } = props;
  const { viewOrder, viewOrderParams } = props;

  const [titleExpanded, setTitleExpanded] = useBoolean(false);

  const pdfType = currentType === 'TRANSACTION.MEMO.RECEIVED' || currentType === 'TRANSACTION.MEMO.ISSUED' ? 2 : 1;

  const openOrderSlip = React.useCallback(async () => {
    const path = await (() => {
      const state = store.getState();
      const selectedRowIds = state?.diamondData?.selectedRowIds?.[currentType] ?? [];
      const selectedRows = groupRows.filter((row) => {
        return selectedRowIds.includes(row.id) || selectedRowIds.includes(row.selectionKey);
      });
      return OrderService.getSlipFilePath(selectedRows, { payload: { ...viewOrderParams, pdfType } });
    })();

    if (isString(path)) window.open(path);
  }, [currentType, groupRows, pdfType, viewOrderParams]);

  return (
    <>
      {!props.tableHide && !isEmpty(props.groupingTitle) && (
        <div
          className="tableGropupingBox"
          style={window.location.pathname.includes('offer') ? { marginTop: '3px' } : {}}
        >
          <div className="tableGroupTitle">
            {(props.Collapse || props.multipleCheckBox || props.groupingTitle || props.MoreOption) && (
              <span
                className={classNames([
                  `tableGroupTitleInner`,
                  titleExpanded && 'collpaseBlock',
                  props?.MoreOption && 'moreOptionShow',
                ])}
              >
                {props?.Collapse && (
                  <div className="tableCollapse" onClick={props.onClick}>
                    <img src={props.img} />
                  </div>
                )}
                {!isEmpty(props?.groupRows) && !noCheckBox && !hideCheckbox && (
                  <Tooltip title="Select All">
                    <div className="checkBoxSelect">
                      <GroupCheckBox groupRows={props?.groupRows} currentType={props.currentType} />
                    </div>
                  </Tooltip>
                )}
                {children && <div className="checkBoxSelect">{children}</div>}
                {props?.groupingTitle && <span>{props?.groupingTitle}</span>}

                {props.Action && (
                  <div className="d-flex tableListIconMain">
                    {props.pending && (
                      <>
                        <span className="tableListIcon" onClick={props.approveClick}>
                          Approve
                        </span>
                        {props.reject ? null : <span className="tableListIcon">Reject</span>}
                      </>
                    )}
                    <span className="tableListIcon">
                      <img src={mailWhiteSvg} />
                    </span>
                    <span className="tableListIcon">
                      <img src={excelWhiteSvg} />
                    </span>
                  </div>
                )}
                {ALLOW_ROW_GROUP_TITLE_TOGGLE && (
                  <div className="groupClose" onClick={setTitleExpanded.toggle}>
                    <img src={titleExpanded ? leftArrowWhiteSvg : rightArrowWhiteSvg} />
                  </div>
                )}
              </span>
            )}
            <div className="tableHeaderMain">
              {props?.totalDiamonds ? props?.totalDiamonds + ' Diamond' : ''}

              {viewOrder && currentType !== 'TRANSACTION.HOLD.HOLD' && currentType !== 'TRANSACTION.HOLD.RELEASE' && (
                <span className="tableListIcon" onClick={openOrderSlip}>
                  <PrintSvgIcon fill="#414143" />
                </span>
              )}
            </div>
          </div>
        </div>
      )}
      <tr className="boxShadowNone">
        {isEmpty(props.columns) && (
          <td
            colSpan={props?.colspan ? props?.colspan : props?.columns ? 2 : 3}
            style={{ color: 'transparent', paddingTop: '0.25rem', paddingBottom: '0.25rem' }}
          >
            &nbsp;
          </td>
        )}
        {props.isOfferPopup && <td style={{ color: 'transparent' }}></td>}
        {props.isOfferPopup
          ? props.calc?.map?.((n, key) => (
              <td key={key} className="groupingTableAmount">
                {n}
              </td>
            ))
          : props.columns?.map?.((column, index) => (
              <td className="groupingTableAmount" key={isEmpty(column?.id) ? index : column?.id}>
                {displayTotal.includes(column?.id) && (
                  <DiamondTotalCard row={props?.row} rows={groupRows} col={column?.id} />
                )}
              </td>
            ))}
      </tr>
    </>
  );
}

export default TableGrouping;
