import React from 'react';

import metadata from 'libphonenumber-js/metadata.full.json';
import PhoneNumber from 'libphonenumber-js/source/PhoneNumber';

import { catchError } from 'util/utils';

export class CellNumber {
  static identifier = 'CellNumber';

  #nationalNumber = undefined;
  #countryCallingCode = undefined;
  #parsedNumber = {};

  constructor(nationalNumber, countryCallingCode) {
    this.#nationalNumber = nationalNumber;
    this.#countryCallingCode = countryCallingCode;
    const countryCode = CellNumber.COUNTRY_CALLING_CODES?.[this.#countryCallingCode];
    const check = countryCode?.map((country) => {
      const number = new PhoneNumber(country, this.#nationalNumber, metadata);

      if (number.isValid()) {
        this.#parsedNumber = number;
      }
    });
    // this.#parsedNumber = catchError(
    //   () =>
    //     new PhoneNumber(
    //       CellNumber.COUNTRY_CALLING_CODES?.[this.#countryCallingCode]?.[3],
    //       this.#nationalNumber,
    //       metadata,
    //     ),
    //   () => ({}),
    // );
  }

  static COUNTRIES = metadata.countries;
  static COUNTRY_CALLING_CODES = metadata.country_calling_codes;

  static isCellNumber(input) {
    return input?.constructor === CellNumber;
  }

  format(type = 'NATIONAL') {
    return catchError(
      () => this.#parsedNumber.format?.(type),
      () => undefined,
    );
  }

  get isPossible() {
    return this.#parsedNumber.isValid?.() ?? false;
  }

  get isValid() {
    return this.#parsedNumber.isValid?.() ?? false;
  }

  get country() {
    return this.#parsedNumber.country;
  }

  get countryCallingCode() {
    return this.#parsedNumber.countryCallingCode;
  }

  get nationalNumber() {
    return this.#parsedNumber.nationalNumber;
  }

  get number() {
    return this.#parsedNumber.number;
  }

  get intl() {
    return this.format('INTERNATIONAL');
  }

  get uri() {
    return this.format('RFC3966');
  }

  toString() {
    return this.number;
  }

  valueOf() {
    return this.number;
  }
}

CellNumber.use = function useCellNumber(nationalNumber, countryCallingCode) {
  return React.useMemo(() => new CellNumber(nationalNumber, countryCallingCode), [countryCallingCode, nationalNumber]);
};
