import { Action } from 'services/action';
import { Storage } from 'services/storage';

import {
  deepFreeze,
  get,
  hasKey,
  isArray,
  isEmpty,
  isNotEmpty,
  isObject,
  isString,
  logInfo,
  lowerCase,
  pruneEmpty,
  titleCase,
} from 'util/utils';

import { PERMISSION } from 'constants/permission';

/**
 * PermissionStore class
 * Transforms and stores permission list as tree
 * @note: Keep all methods static (so they can be accessed directly)
 * @author Sagar Panchal <panchal.sagar@outlook.com>
 */
class PermissionStore {
  static list = [];
  static tree = {};
  static template = [];

  static alternatives = [
    ['upload', 'uploadExcel'],
    ['download', 'downloadExcel'],
    ['mail', 'mailExcel'],
    ['print', 'printPDF'],
  ];

  static #_defaultModule = {
    allow: false,
    name: 'DIAMNOW',
    parentName: 'DIAMNOW',
    parentPath: 'DIAMNOW',
    path: 'DIAMNOW.DIAMNOW',
    title: 'DiamNow',
    url: '/',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      upload: true,
      download: true,
      mail: true,
      print: true,
      all: true,
    },
  };

  static get defaultModule() {
    return this.#_defaultModule;
  }

  static get updateTreeEvent() {
    return new Action('@permission/update-tree');
  }

  static get updateListEvent() {
    return new Action('@permission/update-list');
  }

  static clear() {
    PermissionStore.list = [];
    PermissionStore.tree = {};
  }

  static setList() {
    const storedList = Storage.get('permission');
    const defaultList = PermissionStore.clonePermissionList(PERMISSION);
    PermissionStore.list = !isEmpty(storedList) ? storedList : defaultList;
    deepFreeze(PermissionStore.list);

    PermissionStore.updateListEvent.emit(PermissionStore.list);
  }

  static getList() {
    if (isEmpty(PermissionStore.list)) PermissionStore.setList();
    return PermissionStore.list;
  }

  static setTemplate() {
    PermissionStore.template = PermissionStore.list.map((input) => {
      const emptyPermissionEntries = Object.keys(input?.permissions).map((key) => [key, false]);
      const permissions = Object.fromEntries(emptyPermissionEntries);
      return { ...input, permissions };
    });

    logInfo('PermissionStore.setTemplate', PermissionStore.template);
  }

  static getTemplate() {
    if (isEmpty(PermissionStore.template)) PermissionStore.setTemplate();
    return PermissionStore.template;
  }

  static setTree() {
    PermissionStore.clear();
    PermissionStore.tree = PermissionStore.createTree();
    deepFreeze(PermissionStore.tree);
    PermissionStore.updateTreeEvent.emit(PermissionStore.tree);
  }

  static getTree() {
    if (isEmpty(PermissionStore.tree)) PermissionStore.setTree();
    return PermissionStore.tree;
  }

  static createTree() {
    const tree = {};
    // permission module array
    const permissionList = PermissionStore.getList();
    if (!isArray(permissionList) || isEmpty(permissionList)) return [];

    // transform permission module array to tree
    permissionList.forEach((original) => {
      if (!isObject(original)) return;

      // copy original before modification
      const permissions = { ...original?.permissions };

      if (!isEmpty(permissions)) {
        // decide value of `all` prop
        if (hasKey(permissions, 'all')) {
          const { all, ...restPermissions } = permissions;
          permissions.all = !Object.values(restPermissions).includes(false);
        }

        // add alternative keys
        PermissionStore.alternatives.forEach(([altField, origField]) => {
          if (!isEmpty(permissions[altField])) return;
          permissions[altField] = permissions[origField];
          delete permissions[origField];
        });

        // update undefined keys to valueOf `all`
        Object.keys(permissions).forEach((key) => {
          permissions[key] = permissions?.[key] ?? permissions?.all;
        });
      }

      // unique modules
      const modulePath = PermissionStore.getModulePath(original, true);

      // create module nodes
      const nodeStack = [];
      let initCurrent = tree;

      for (let index = 0; index < modulePath.length; index++) {
        const leaf = modulePath[index];

        initCurrent[leaf] =
          initCurrent?.[leaf] ??
          pruneEmpty({
            name: modulePath[index],
            title: titleCase(lowerCase(modulePath[index])),
            path: modulePath.slice(0, index + 1).join('.'),
            parentName: modulePath[index - 1],
            parentPath: index > 0 ? modulePath.slice(0, index).join('.') : undefined,
          });

        if (isEmpty(initCurrent[leaf].parent)) delete initCurrent[leaf].parent;
        initCurrent = initCurrent[leaf];
        nodeStack.push(initCurrent);
      }

      // assign value to node
      const value = pruneEmpty({ path: modulePath.join('.'), title: original?.title, permissions, original });
      value.allow = PermissionStore.checkSubModuleAllowance(value) ?? false;

      for (let index = modulePath.length - 1; index > -1; index--) {
        const leaf = modulePath[index];
        const parentNode = index === 0 ? tree : nodeStack[index - 1];

        if (isObject(nodeStack[index])) {
          parentNode[leaf] = { ...nodeStack[index], ...value };
          break;
        }
      }

      for (let index = modulePath.length - 1; index > -1; index--) {
        const parentNode = index === 0 ? tree : nodeStack[index - 1];
        parentNode.allow = PermissionStore.checkSubModuleAllowance(parentNode) ?? false;
      }
    });

    logInfo('PermissionStore.createTree', tree);
    deepFreeze(tree);
    return tree;
  }

  static clonePermissionList(list) {
    return list?.map?.((input) => ({ ...input, permissions: { ...input?.permissions } })) ?? [];
  }

  static destructureNode(node) {
    const { name, path, title, parentName, parentPath, allow, permissions, original, ...modules } = node;
    return { name, path, title, parentName, parentPath, allow, permissions, modules, original };
  }

  static checkSubModuleAllowance(node) {
    const { modules, permissions } = PermissionStore.destructureNode(node);
    if (!isEmpty(permissions)) return (permissions?.all || permissions?.view) ?? false;
    if (isEmpty(modules)) return false;
    return Object.values(modules).some(PermissionStore.checkSubModuleAllowance);
  }

  static getModulePath(module, returnArray = false) {
    const path = isString(module?.path)
      ? module.path.split('.')
      : isString(module?.mainModule) || isString(module?.subModule) || isString(module?.module)
      ? [module?.mainModule, module?.subModule, module?.module].filter(isNotEmpty)
      : [];
    return returnArray ? path : path.join('.');
  }

  static getPermission(path) {
    if (!isString(path) || isEmpty(path)) return {};
    return get(PermissionStore.getTree(), path.toUpperCase());
  }

  static getPathFromUrl(url) {
    const permissionList = PermissionStore.getList();
    const modulesWithUrl = permissionList.filter((_module) => !isEmpty(_module?.url));

    const exactMatch = modulesWithUrl.find((_module) => url === _module?.url);
    if (!isEmpty(exactMatch)) return PermissionStore.getModulePath(exactMatch);

    const partialMatch = modulesWithUrl.find((_module) => _module.url.includes(url) || url.includes(_module?.url));
    if (!isEmpty(partialMatch)) return PermissionStore.getModulePath(partialMatch);

    return '';
  }
}

deepFreeze(PermissionStore.alternatives);
deepFreeze(PermissionStore.defaultModule);

/**
 * PermissionService
 * Interface for PermissionStore
 * @author Sagar Panchal <panchal.sagar@outlook.com>
 */
export class PermissionService {
  static identifier = 'Permission';

  static clear = PermissionStore.clear;
  static setTree = PermissionStore.setTree;
  static getTree = PermissionStore.getTree;
  static getPermission = PermissionStore.getPermission;
  static getPathFromUrl = PermissionStore.getPathFromUrl;
  static getTemplate = PermissionStore.getTemplate;
  static clonePermissionList = PermissionStore.clonePermissionList;
  static defaultModule = PermissionStore.defaultModule;

  static events = {
    updateTree: PermissionStore.updateTreeEvent,
    updateList: PermissionStore.updateListEvent,
  };

  static initialize() {
    PermissionStore.setTree();
    void window.__DN?.PermissionListeners?.forEach?.((fn) => fn?.());
    const PermissionListeners = [
      Storage?.listen?.('permission', () => PermissionStore.setTree()),
      PermissionStore?.updateListEvent?.listen?.(() => PermissionStore.setTemplate()),
    ];
    window.__DN.PermissionListeners = PermissionListeners;
  }
}
