import React from 'react';

import { formatDecimal } from 'util/utils';

const BasicDetail = (props) => {
  const dt = props.data;
  return (
    <div>
      <ul className="basicDetailFull">
        <ul className="basicDetailFull">
          <li>{dt.vStnId}</li>
          <li>{dt.shpNm}</li>
          <li>{formatDecimal(dt.crt)}</li>
          <li>{dt.colNm}</li>
          <li>{dt.clrNm}</li>
          {/* <li>2.96%</li> */}
          <li>{dt.cutNm}</li>
          <li>{dt.polNm}</li>
          <li>{dt.symNm}</li>
          <li>{dt.fluNm}</li>
          <li>{dt.lbNm}</li>
          <li>{dt.rptNo}</li>
        </ul>
      </ul>
    </div>
  );
};
export default BasicDetail;
