import React from 'react';
import { useSelector } from 'react-redux';

import { EMPTY_VALUES } from 'util/constants';

import { useCurrentModule } from './useCurrentModule';

export function useSelectedClient(type) {
  const module = useCurrentModule();
  const currentType = type ?? module?.path;

  const isInClient = module?.path?.startsWith?.('CLIENT.');

  const selectedFromSidebar = useSelector((state) => state.tab?.clientList?.customerDetail ?? EMPTY_VALUES.OBJECT);
  const selectedFromDropdown = useSelector((state) => state.client?.selected?.[currentType] ?? EMPTY_VALUES.OBJECT);

  const client = isInClient ? selectedFromSidebar : selectedFromDropdown;

  React.useDebugValue(client);
  return client;
}
