import { logWarn } from './logWarn';

/**
 * Handles exceptions in a function
 * @param   {Function} func
 * @param   {Object}   options
 * @returns {Promise}
 */
export const asyncHandler = async (func) => {
  try {
    const response = await func();
    return [false, response];
  } catch (error) {
    logWarn(error);
    return [true, error];
  }
};
