import Cookies from 'js-cookie';

import { Action } from 'services/action';

import { hasKey, isArray, isString, logWarn } from 'util/utils';

/**
 * Storage class
 * Transforms and stores data in Storage.driver
 * @note: Keep all methods static (so they can be accessed directly)
 * @author Sagar Panchal <panchal.sagar@outlook.com>
 */
export class Storage {
  static identifier = 'Storage';
  static driver = localStorage;

  static get updateKeyEvent() {
    return new Action('@storage/key-updated');
  }

  static get deleteKeyEvent() {
    return new Action('@storage/key-deleted');
  }

  static get storagePrefix() {
    const keyPrefix = 'sla';
    const driverPrefix =
      Storage.driver === localStorage ? 'local' : Storage.driver === sessionStorage ? 'session' : 'other';
    return `@${keyPrefix}/${driverPrefix}/`;
  }

  static getStorageKey(key) {
    return key.startsWith(Storage.storagePrefix) ? key : `${Storage.storagePrefix}${key}`;
  }

  static getSenitizedKey(key) {
    return key.startsWith(Storage.storagePrefix) ? key.substr(Storage.storagePrefix?.length) : key;
  }

  static decodeValue(value, toBinary = true) {
    return (toBinary ? JSON.parse(decodeURIComponent(escape(atob(value)))) : JSON.parse(value))?._;
  }

  static encodeValue(value, toAscii = true) {
    value = { _: value };
    return toAscii ? btoa(unescape(encodeURIComponent(JSON.stringify(value)))) : JSON.stringify(value);
  }

  /**
   * Store
   * @param {String} id
   * @param {*} value
   */
  static set(id, value, encode = true) {
    const name = Storage.getSenitizedKey(id);
    const key = Storage.getStorageKey(name);

    try {
      Storage.driver.setItem(key, Storage.encodeValue(value, encode));
      Storage.updateKeyEvent.emit({ [name]: value });
      return true;
    } catch (error) {
      logWarn(error);
    }
  }

  /**
   * Retrieve
   * @param {String} name
   */
  static get(name, decode = true) {
    const key = Storage.getStorageKey(name);

    try {
      return Storage.decodeValue(Storage.driver.getItem(key), decode);
    } catch (error) {
      logWarn(error);
      return null;
    }
  }

  /**
   * Remove values by key
   * @param {String|Array} list String or Array of string
   */
  static delete(list) {
    list = isArray(list) ? list : isString(list) ? [list] : [];

    list.forEach((id) => {
      const name = Storage.getSenitizedKey(id);
      const key = Storage.getStorageKey(id);

      try {
        Storage.driver.removeItem(key);
        Storage.driver.removeItem(name);
        Storage.updateKeyEvent.emit({ [name]: undefined });
        Storage.deleteKeyEvent.emit({ [name]: undefined });
      } catch (error) {
        logWarn(error);
      }
    });
  }

  static deleteAll(except = ['dn-up', 'dn-r-up']) {
    const storageKeys = Object.keys(Storage.driver).map(Storage.getSenitizedKey);
    except.forEach((name) => storageKeys.splice(storageKeys.indexOf(name), 1));
    Storage.delete(storageKeys);
    Storage.deleteCookie('token');
  }

  static listen(id, callback) {
    const name = Storage.getSenitizedKey(id);
    const unlisten = Storage.updateKeyEvent.listen((event) => {
      if (!hasKey(event?.detail, name)) return;
      callback({ [name]: event?.detail?.[name] });
    });
    return unlisten;
  }

  /**
   * Store
   * @param {String} id
   * @param {*} value
   */
  static setCookie(id, value, encode = true) {
    const name = Storage.getSenitizedKey(id);
    const key = Storage.getStorageKey(name);

    try {
      Cookies.set(key, Storage.encodeValue(value, encode), { expires: 365, sameSite: 'strict' });
      Storage.updateKeyEvent.emit({ [name]: value, cookie: true });
      return true;
    } catch (error) {
      logWarn(error);
    }
  }

  /**
   * Retrieve
   * @param {String} name
   */
  static getCookie(name, decode = true) {
    const key = Storage.getStorageKey(name);

    try {
      const value = Storage.decodeValue(Cookies.get(key), decode);
      return value;
    } catch (error) {
      logWarn(error);
      return null;
    }
  }

  /**
   * Remove values by key
   * @param {String|Array} list String or Array of string
   */
  static deleteCookie(list) {
    list = isArray(list) ? list : isString(list) ? [list] : [];

    list.forEach((id) => {
      const name = Storage.getSenitizedKey(id);
      const key = Storage.getStorageKey(id);

      try {
        Cookies.remove(key);
        Storage.updateKeyEvent.emit({ [name]: undefined, cookie: true });
        Storage.deleteKeyEvent.emit({ [name]: undefined, cookie: true });
      } catch (error) {
        logWarn(error);
      }
    });
  }

  static initialize() {}
}

export default Storage;
