import React from 'react';

export function useEffectOnMount(callback) {
  const didMount = React.useRef(true);

  React.useEffect(() => {
    if (didMount.current) {
      callback();
      didMount.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
