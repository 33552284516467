import { PermissionService } from 'services/PermissionService';
import { getLoginUser } from 'services/commonFunc';

import { PREFIX_URL_WITH_SLASH as URL } from 'constants/CommonUrl';

import { USERS_TYPE } from './Common';

export const MENU = [
  { path: `${URL}/dashboard`, name: 'Home', svg: 'Dashboard', permit: 'DASHBOARD' },
  { path: `${URL}/inventory`, name: 'Inventory', svg: 'Master', permit: 'INVENTORY' },
  { path: `${URL}/client`, name: 'Clients', svg: 'Account', permit: 'CLIENT' },
  { path: `${URL}/transaction`, name: 'Transactions', svg: 'Transactions', permit: 'TRANSACTION' },
  { path: `${URL}/reports`, name: 'Track Reports', svg: 'Reports', permit: 'DIAMOND_REPORTS' },
  { path: `${URL}/notification`, name: 'Notifications', svg: 'Notification', permit: 'NOTIFICATION' },
  // { path: `${URL}/todo`, name: 'Todo', svg: 'Todo', permit: 'TODO' },
  { path: `${URL}/user`, name: 'Users', svg: 'Account', permit: 'USER' },
  // { path: `${URL}/crm`, name: 'CRM', svg: 'Account', permit: 'CRM' },
];

export function getMenuLinks() {
  const loginUser = getLoginUser();
  const permissionTree = PermissionService.getTree() ?? [];
  const mainModuleList = Object.keys(permissionTree).filter((module) => {
    const modulePermissions = PermissionService.getPermission(module);

    return loginUser?.type === USERS_TYPE?.SELLER && module === 'USER' ? false : modulePermissions?.allow;
  });
  return MENU.filter(({ permit }) => mainModuleList.includes(permit));
}
