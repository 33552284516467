import React from 'react';

import _divide from 'lodash/divide';
import _sum from 'lodash/sum';
import _sumBy from 'lodash/sumBy';

import { parseDecimal } from 'util/utils';

import { floatkeys } from '../Inventory/TableBack';

function DiamondTotalCard(props) {
  const { rows, col } = props;

  const avgCtPr = _divide(_sumBy(rows, 'amt'), _sumBy(rows, 'crt'));

  const avgRap = _divide(_sum(rows?.map?.((row) => row.crt * row.rap)), _sumBy(rows, 'crt'));
  const backDiscount = avgRap ? (1 - avgCtPr / avgRap) * -100 : avgRap == 0 ? 0 : '';

  const prefix = !['crt', 'back'].includes(col) ? '$' : '';

  const result = (() => {
    const sum = parseFloat(
      ['crt', 'amt'].includes(col)
        ? _sumBy(rows, col) ?? 0
        : props.col === 'rap'
        ? avgRap
        : col === 'back'
        ? backDiscount
        : _divide(_sumBy(rows, 'amt' || 0), _sumBy(rows, 'crt' || 0)),
    ).toFixed(floatkeys.includes(col) ? (col === 'crt ' ? 3 : 2) : 0);

    return `${prefix}${sum}`;
  })();

  return <span className="selectAmount">{result}</span>;
}
export default DiamondTotalCard;
