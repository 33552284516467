import { EMPTY_VALUES } from 'util/constants';

import { DiamondActions } from 'appRedux/reducers/diamond';
import { store } from 'appRedux/store';

export class RowSelectService {
  static getSelectedRows(type) {
    return store.getState().diamondData.selectedRows?.[type] ?? EMPTY_VALUES.ARRAY;
  }

  static getSelectedRowIds(type) {
    return store.getState().diamondData.selectedRowIds?.[type] ?? EMPTY_VALUES.ARRAY;
  }

  static selectRows(type, rows, replace) {
    return store.dispatch(DiamondActions.selectRows({ payload: rows, currentType: type, replace }));
  }

  static unSelectRows(type, rows, replace) {
    return store.dispatch(DiamondActions.unSelectRows({ payload: rows, currentType: type, replace }));
  }

  static resetSelectedRows(type) {
    return store.dispatch(DiamondActions.resetSelectedRows({ currentType: type }));
  }
}
