import React from 'react';
import Magnifier from 'react-magnifier';
import { useBoolean } from 'util/hooks';

import noImageList from 'assets/svg/noImageList.svg';

function MagnifierImage(props) {
  const { image, src, zoomFactor = 1, clickToOpen, children, ...restProps } = props;

  const ref = React.useRef();
  const url = React.useMemo(() => src ?? image, [src, image]);
  const [error, setError] = useBoolean();

  const handleClick = React.useCallback(() => void (clickToOpen && window.open(url, '_blank')), [clickToOpen, url]);

  React.useEffect(() => {
    if (error || !ref.current) return;
    const image = ref.current.img;
    image.addEventListener('error', setError.true);
  });

  if (error) return children ?? <img src={noImageList} />;
  return <Magnifier ref={ref} src={url} zoomFactor={zoomFactor} onClick={handleClick} {...restProps} />;
}

export default MagnifierImage;
