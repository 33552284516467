import React from 'react';
import { useSelector } from 'react-redux';

const defaultUser = {};

export function useCurrentUser() {
  const user = useSelector((state) => state.auth?.authUser ?? defaultUser);
  React.useDebugValue(user);
  return user;
}
