import React from 'react';

import { retEditClient } from 'components/Inventory/TableBack';
import InlineList from 'components/List/InlineList';

import { capitalize } from 'util/not-needed';
import { isEmpty, isObject, isString } from 'util/utils';

const ClientName = (props) => {
  const { user, data } = props;
  if (!user || isEmpty(user)) return <></>;

  const companyName = user?.account?.companyName ? user.account.companyName : user?.companyName ? user.companyName : '';
  return (
    <span className="ClientLinkRedirect">
      <InlineList
        skipEmpty
        separator=" | "
        list={[
          !isEmpty(companyName) ? <a className="tableLink">{companyName}</a> : undefined,
          capitalize(user?.name ?? '-'),
        ]}
        onClick={retEditClient(
          isObject(data?.user?.account)
            ? data?.user?.account?.id
            : isString(data?.user?.account)
            ? data?.user?.account
            : data?.userAccount,
        )}
      />
    </span>
  );
};
export default ClientName;
