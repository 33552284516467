import 'react-app-polyfill/ie9';
import './services';
// import './wdyr';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';

import { ModuleProvider } from 'contexts/Permissions';

import { loadable } from 'util/asyncComponent';
import { history } from 'util/history';

import { store } from 'appRedux/store';

import 'styles/main.less';
import 'react-intl-tel-input/dist/main.css';

const App = loadable(() => import('containers/App'));
const ConnectedLoader = loadable(() => import('components/CircularProgress/ConnectedLoader'));

ReactDOM.render(
  <Provider store={store}>
    <ModuleProvider>
      <ConnectedLoader />
      <Router history={history}>
        <App />
      </Router>
    </ModuleProvider>
  </Provider>,
  document.getElementById('root'),
);
