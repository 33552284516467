import React from 'react';

import { LocalModuleContext } from 'contexts/Permissions';

import { EMPTY_VALUES } from 'util/constants';

/**
 * useCurrentModule react hook
 * @author Sagar Panchal <panchal.sagar@outlook.com>
 */

export function useCurrentModule() {
  const module = React.useContext(LocalModuleContext);
  return module ?? EMPTY_VALUES.OBJECT;
}
