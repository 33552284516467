import React from 'react';
import { useSelector } from 'react-redux';

import { RowSelectService } from 'services/RowSelectService';

export function useGroupSelect(rows = [], currentType) {
  const groupRowIds = React.useMemo(() => rows.map((row) => row?.selectionKey ?? row?.id), [rows]);

  const isGroupChecked = useSelector((state) => {
    const selectedRowIds = state?.diamondData?.selectedRowIds?.[currentType] ?? [];
    return Boolean(groupRowIds.every((id) => selectedRowIds.includes(id)));
  });

  const isIndeterminate = useSelector((state) => {
    if (isGroupChecked) return false;
    const selectedRowIds = state?.diamondData?.selectedRowIds?.[currentType] ?? [];
    return Boolean(groupRowIds.some((id) => selectedRowIds.includes(id)));
  });

  const toggleGroupSelection = React.useCallback(() => {
    isGroupChecked ? RowSelectService.unSelectRows(currentType, rows) : RowSelectService.selectRows(currentType, rows);
  }, [isGroupChecked, rows, currentType]);

  return [isGroupChecked, isIndeterminate, toggleGroupSelection];
}
