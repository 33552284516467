import React from 'react';

import { useCurrentType } from 'util/hooks';

export function withCurrentType(...args) {
  return function HOC(WrappedComponent) {
    return function WithCurrentType(props) {
      const [currentType] = useCurrentType(...args);

      return <WrappedComponent {...props} currentType={currentType} />;
    };
  };
}
