import UtilService from 'services/util';

import { isEmpty } from 'util/utils';

import { API_ROUTES } from 'constants/CommonApi';
import ConstantApi from 'constants/CommonApi';

export const getCountries = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.Country.paginate, request, ...(!isEmpty(config) && { config }) });

export const getStates = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.State.paginate, request, ...(!isEmpty(config) && { config }) });

export const getCities = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.City.paginate, request, ...(!isEmpty(config) && { config }) });

export const getBroker = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.Broker.list, request, ...(!isEmpty(config) && { config }) });

export const UserTerms = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.UserTerms.Request, request, ...(!isEmpty(config) && { config }) });

export const getRateSettings = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.projectSettings.view, request, ...(!isEmpty(config) && { config }) });

export const getMatchPairSettings = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.matchPairSettings.List, request, ...(!isEmpty(config) && { config }) });

export const updateMatchPairSettings = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.matchPairSettings.Update, request, ...(!isEmpty(config) && { config }) });

export const getQuoteDay = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.QuoteDay.paginate, request, ...(!isEmpty(config) && { config }) });

export const createShowSelection = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.showSelection.create, request, ...(!isEmpty(config) && { config }) });

export const saveCertificate = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.Certificate.create, request, ...(!isEmpty(config) && { config }) });

export const getCheckPacket = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.CheckPacket.Paginate, request, ...(!isEmpty(config) && { config }) });

export const exportExcelCheckPacket = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.CheckPacket.ExportExcel, request, ...(!isEmpty(config) && { config }) });

export const printPdf = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.Inventory.printPdf, request, ...(!isEmpty(config) && { config }) });

export const downloadExcel = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.Diamond.excel, request, ...(!isEmpty(config) && { config }) });

export const deleteDiamondTrack = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.DiamondTrack.delete, request, ...(!isEmpty(config) && { config }) });

export const shippingDetail = (id = null) =>
  UtilService.callApiAsync({
    ...UtilService.withUrlParams(ConstantApi.Shipping.detail, [id]),
  });

export const settingUpsert = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.Setting.upsert, request, ...(!isEmpty(config) && { config }) });

export const getSetting = (request = {}) => UtilService.callApiAsync({ ...API_ROUTES.Setting.view, request });

export const settingView = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.Setting.view, request, ...(!isEmpty(config) && { config }) });

export const matchPairList = (request = {}) => UtilService.callApiAsync({ ...API_ROUTES.MatchPair.Paginate, request });

export const dashboardDetails = (request = {}) =>
  UtilService.callApiAsync({ ...API_ROUTES.Dashboard.Details, request });

export const forgotPassword = (request = {}) => UtilService.callApiAsync({ ...API_ROUTES.Auth.forgot, request });

export const exportExcel = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.Inventory.exportExcel, request, ...(!isEmpty(config) && { config }) });

export const diamondTracking = (request) =>
  UtilService.callApiAsync({
    ...API_ROUTES.Crm.diamondTracking,
    request,
  });

export const priceListUpload = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.Setting.priceListUpload, request, ...(!isEmpty(config) && { config }) });
