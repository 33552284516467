const initialState = {
  isActive: true,
  isApproved: 'all',
  type: 'all',
  natureOfOrg: 'all',
  isSync: 'all',
};

export const accountFilterActionTypes = {
  SET_ACCOUNT_FILTER: 'SET_ACCOUNT_FILTER',
  RESET_ACCOUNT_FILTER: 'RESET_ACCOUNT_FILTER',
};

export const AccountFilterActions = {
  setAccountFilter: (filters) => ({
    type: accountFilterActionTypes.SET_ACCOUNT_FILTER,
    payload: filters,
  }),
  resetFilter: () => ({
    type: accountFilterActionTypes.RESET_ACCOUNT_FILTER,
  }),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case accountFilterActionTypes.SET_ACCOUNT_FILTER:
      return { ...state, ...action.payload };

    case accountFilterActionTypes.RESET_ACCOUNT_FILTER:
      return { ...initialState };

    case '@@RESET':
      return { ...initialState };

    default:
      return state;
  }
};
